import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Loading from './V_Loading'
import V_KelolaUser from './administrator/V_KelolaUser'

export default class Login extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      un: '',
      ua: ''

    }
  }

  componentDidMount() {

    if (sessionStorage.getItem("un") == null) {
      sessionStorage.clear()
      localStorage.clear()
      window.location.replace('/')
      console.log("cleared")
      
    }else{
      window.location.reload()
      localStorage.clear()
      sessionStorage.clear()
      console.log("ref")
    }
  }
  render() {
    return (
      <div>
        <div className="limiter">

          <div className="container-login100">
            <div className="col-md-3">
              <div className="box box-solid">
                <div className="overlay">
                  <li className="fa fa-refresh fa-spin" />
                </div>
              </div><br/>
              <div className=" box-solid" align="center">
                <h4><b style={{color:'#fff'}}>Keluar ....</b></h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
