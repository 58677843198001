import React, { Component } from 'react'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import History_SuratUndangan from './History_SuratUndangan'
import Notif_ReqSurat from './Notif_ReqSurat'
import { now } from 'moment'
import API from '../axios/Api'
import { Link } from 'react-router-dom'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'

export default class Home extends Component {
  constructor() {
    super()
    this.state = {
      tsm: '',
      tsk: '',
      tsemer: '',
      tskel: '',
      idusers: '',
      unusers:'',
      aksusers: ''
    }
  }

  jamku = () => {
    var span = document.getElementById('jamku');
    var d = new Date();
    var s = d.getSeconds();
    var m = d.getMinutes();
    var h = d.getHours();

    if (span)
      span.textContent = "waktu : " + h + ":" + m + ":" + s;
  }

  async componentDidMount() {
    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    // const asd = sessionStorage.getItem("ak")

    await API.get('beranda/total/surat/view' + key_surat.baseURL + '&ak=' + this.state.aksusers)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        tsm: Response.data
      }))

    await API.get('beranda/total/surat/keluar/v' + key_surat.baseURL + '&ak=' + this.state.aksusers)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        tsk: Response.data
      }))

    await API.get('beranda/total/surat/emergensi/tahun/ini/v' + key_surat.baseURL + '&ak=' + this.state.aksusers)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        tsemer: Response.data
      }))

    await API.get('beranda/total/surat/keluar/nya/v' + key_surat.baseURL + '&ak=' + this.state.aksusers)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        tskel: Response.data
      }))

      // console.log(this.state)
      
    setInterval(this.jamku, 0.5);
    // this.cek()

    this.timer = setInterval(() => this.cek(), 1000);
  }

  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }

    if (sessionStorage.getItem("un") == undefined) {
      this.props.history.push('/loadingout')

    }
  }

  render() {
    var date = new Date();
    var tahun = date.getFullYear();
    var bulan = date.getMonth();
    var tanggal = date.getDate();
    switch (bulan) {
      case 0: bulan = "Januari"; break;
      case 1: bulan = "Februari"; break;
      case 2: bulan = "Maret"; break;
      case 3: bulan = "April"; break;
      case 4: bulan = "Mei"; break;
      case 5: bulan = "Juni"; break;
      case 6: bulan = "Juli"; break;
      case 7: bulan = "Agustus"; break;
      case 8: bulan = "September"; break;
      case 9: bulan = "Oktober"; break;
      case 10: bulan = "November"; break;
      case 11: bulan = "Desember"; break;
    }
    var tglhariini = tanggal + " " + bulan + " " + tahun;
    return (
      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">
          <section className="content" style={{ padding: '40px', marginBottom: '-24px' }}>
            <div className="row">
              <div className="col-lg-6 col-xs-12">
                <div className="small-box bg-green" style={{
                  height: '150px', backgroundImage: 'url("https://i.ibb.co/BVYrJgs/bgkku.jpg")',
                  backgroundSize: '100%'
                }}>
                  <div className="inner">
                    <h3 style={{ padding: '20px' }} className="fontku" id="ukuranku">Selamat datang</h3>
                    <h5 className="text-white fontku" style={{ padding: '20px', marginTop: '-40px', fontSize: '18px' }}>di sistem informasi pengarsipan asia</h5>
                    <div className="icon">
                      <i className="fa fa-archive" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xs-12">
                <div className="small-box bg-green" style={{
                  height: '150px', backgroundImage: 'url("https://i.ibb.co/SVJ9J5m/bgmu.png")',
                  backgroundSize: '110%'
                }}>
                  <div className="inner">
                    <h3 className="fontku" id="ukuranku" style={{ padding: '20px' }}>{tglhariini}</h3>
                    <h4 className="text-white fontku" style={{ padding: '20px', marginTop: '-40px', fontSize: '18px' }}><div id="jamku"></div></h4>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-6 col-xs-12">
                <div className="small-box bg-green" style={{ height: '150px' }}>
                  <div className="inner">
                    <h3 className="fontku" style={{ padding: '20px' }}>{this.state.tsm}</h3>
                    <p className="text-white fontku" style={{ padding: '20px', marginTop: '-50px' }}>Total Surat Masuk</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-envelope-o" />
                  </div>
                  <Link to="distribusisurat" className="small-box-footer fontku" style={{ marginTop: '-6px' }}>
                    Selengkapnya <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-xs-12">
                <div className="small-box bg-aqua" style={{ height: '150px' }}>
                  <div className="inner">
                    <h3 className="fontku" style={{ padding: '20px' }}>{this.state.tsk}</h3>
                    <p className="text-white fontku" style={{ padding: '20px', marginTop: '-50px' }}>Total Surat Keluar</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-envelope-open-o " />
                  </div>
                  <Link to="hsuratkeluar/0" className="small-box-footer fontku" style={{ marginTop: '-6px' }}>
                    Selengkapnya <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-6 col-xs-12">
                {/* small box */}
                <div className="small-box bg-yellow" style={{ height: '150px' }}>
                  <div className="inner">
                    <h3 className="fontku" style={{ padding: '20px' }}>{this.state.tsemer}</h3>
                    <p className="text-white fontku" style={{ padding: '20px', marginTop: '-50px' }}>Total Surat Emergency</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-exclamation-triangle" />
                  </div>
                  <Link to="bemergency" className="small-box-footer fontku" style={{ marginTop: '-6px' }}>
                    Selengkapnya <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-6 col-xs-12">
                {/* small box */}
                <div className="small-box" style={{ backgroundColor: '#6bb0a5', height: '150px' }}>
                  <div className="inner">
                    <h3 className="fontku text-white" style={{ padding: '20px' }}>{this.state.tskel}</h3>
                    <p className="text-white fontku" style={{ padding: '20px', marginTop: '-50px' }}>Total Surat Non-Emergency</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-envelope-square " />
                  </div>
                  <Link to="hsuratkeluar/0" className="small-box-footer fontku" style={{ marginTop: '-6px' }}>
                    Selengkapnya <i className="fa fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              {/* ./col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
