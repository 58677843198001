import React, { Component } from 'react'
import V_DistribusiSurat from './v_codelist'
import API from '../axios/Api'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'

export default class codelist extends Component {

  state = {
    distribusi: []
  }

  // async componentDidMount() {
  //   await API.get("read_recieve.php")
  //    .then(Response => this.setState({
  //      distribusi: Response.data
  //    }))

  //  console.log(this.state)
  //}

  async componentDidMount() {
    await API.get("panduan/code/rektor/tampil/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    //console.log(this.state)


    // console.log(sessionStorage.getItem("un", JSON.stringify(this.state.un)))
    // if (sessionStorage.getItem("un", JSON.stringify(this.state.un)) == null){
    //   sessionStorage.clear()
    //   this.props.history.push('/')
    // }else{

    // }
    // console.log(sessionStorage.getItem("un", JSON.stringify(this.state.un)))

    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }
  close() {
    window.close()
  }

  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_DistribusiSurat v_distribusi={distribusi} key={distribusi.Recieve_code} />
      )
    })

    return (

      <div className="">
        <section className="content-header">
          <h1>Panduan Penulisan Tandatangan & Daftar Code</h1>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12 col-sm-12 margin-bottom-30">
              <div className="box box-success box-solid">
                <div className="box-header">
                  <strong>Panduan Penulisan Tandatangan</strong>
                  <div className="box-tools pull-right">
                  </div>
                </div>
                {/* /.box-header */}
                <div className="box-body">
                  <div className="table-responsive has-error">
                    <label style={{ color: '#000' }}>Keterangan Untuk Yang Bertandatangan</label>
                    <div className="input-group">
                      <span style={{ color: '#000' }}>*** Gunakan kode untuk mengisi yang bertandatangan</span>
                    </div>
                    <div className="input-group">
                      <span style={{ color: '#000' }}>*** Jika yang bertandatangan lebih dari dari 1, maka gunakan tanda titik koma (;) untuk memisah</span>
                    </div>
                    <div className="input-group">
                      <span style={{ color: '#000' }}>*** Misalkan lebih dari 2 ttd diisi seperti ini <b>R.0;R.1</b> tanpa spasi, dan kode yang terakhir tidak memakai tanda ;</span>
                    </div>
                    <div className="input-group">
                      <span style={{ color: '#000' }}>*** Misalkan 1 ttd diisi seperti ini <b>R.0</b></span>
                    </div>
                    <br /><br />
                    <label style={{ color: '#000' }}>Contoh</label>
                    <div className="input-group">
                      <span style={{ color: '#000' }}>
                        1. Satu Tandatangan
                        <input type="text" name="ttdlist" value="R.0" className="form-control" placeholder="" required></input>
                      </span>
                    </div><br />
                    <div className="input-group">
                      <span style={{ color: '#000' }}>
                        2. Lebih Dari 1 Tandatangan
                        <input type="text" name="ttdlist" value="R.0;R.2" className="form-control" placeholder="" required></input>
                      </span>
                    </div><br />
                    <div className="input-group">
                      <span style={{ color: '#000' }}>
                        3. Satu Tandatangan yang tidak ada di daftar list Kode
                        <input type="text" name="ttdlist" value="Dendik" className="form-control" placeholder="" required></input>
                      </span>
                    </div><br />
                    <div className="input-group">
                      <span style={{ color: '#000' }}>
                        4. Lebih Dari 1 Tandatangan yang tidak ada di daftar list Kode
                        <input type="text" name="ttdlist" value="Dendik;Dadang" className="form-control" placeholder="" required></input>
                      </span>
                    </div><br />
                    <div className="input-group">
                      <span style={{ color: '#000' }}>
                        5. Lebih Dari 1 Tandatangan Kode dan yang tidak ada di daftar list Kode
                        <input type="text" name="ttdlist" value="R.0;Dadang" className="form-control" placeholder="" required></input>
                      </span>
                    </div><br /><br />
                    <div className="input-group">
                      <span style={{ color: '#000' }}>*** Penempatan tandatangan ketika dicetak, urut dari kiri ke kanan seperti contoh berikut</span>
                    </div><br/>
                    <div className="input-group">
                      <table border="0" cellpadding="1" cellspacing="1" style={{width:'600px'}}>
                        <tbody align="center">
                          <tr>
                            <td>
                              <p>Yang Bertandatangan</p>

                              <p>&nbsp;</p>

                              <p>TTD 1</p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                              <p>Yang Bertandatangan</p>

                              <p>&nbsp;</p>

                              <p>TTD 2</p>
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>
                              <p>Yang Bertandatangan</p>

                              <p>&nbsp;</p>

                              <p>TTD 3</p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                              <p>Yang Bertandatangan</p>

                              <p>&nbsp;</p>

                              <p>TTD 4</p>
                            </td>
                          </tr>
                        </tbody>
                      </table><br/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 margin-bottom-30">
              <div className="box box-success box-solid">
                <div className="box-header">
                  <strong>Daftar Code dan Tandatangan</strong>
                  <div className="box-tools pull-right">
                  </div>
                </div>
                <div className="box-body">
                  <div className="table-responsive">

                    <table id="example1" className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Kode</th>
                          <th>Nama</th>
                          <th>Jabatan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderData}
                      </tbody>
                    </table>
                  </div>
                  {/* <button type="button" className="btn btn-primary">Kirim</button> */}
                  <Link onClick={this.close} className="btn btn-primary">Tutup</Link>
                  {/* /.box-body */}
                </div>
              </div>

            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </div>

    )
  }
}

