import React, { Component } from 'react'
import Printer, { print } from 'react-pdf-print'
import ReactHtmlParser from 'react-html-parser';


const ids = ['1']

function V_Edaran({ v_edaran }) {
    //const html = '<div>Example HTML string</div>';
    return (

        <div>
            <button id="button" onClick={() => print(ids)} className="btn btn-primary">Download</button>

            <center>
                <Printer>
                    <div id={ids[0]} style={{ border: '2px solid black', width: '210mm', height: '297mm', marginTop: '30px', marginRight: '20px', marginBottom: '30px', marginLeft: '30px', padding: '60px' }}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <center><font size="5"><u><b>SURAT EDARAN</b></u></font></center>
                        <center><font size="2">No:{v_edaran.Sent_code}</font> </center>
                        <br />
                        <center><font color="black">Tentang :</font></center>
                        <br />
                        <p align='center' style={{ textIndent: '0rem' }}>
                            <b><font size="3" color="black">{v_edaran.Sent_about}</font></b>
                        </p>
                        <br />


                        <p align='justify' style={{ textIndent: '0rem' }}>
                            <font size="2" color="black">
                                { ReactHtmlParser(v_edaran.Sent_content) }
                            </font>
                        </p>
                        <br />
                        <p align='justify'>
                            <font size="2" color="black">

                                {v_edaran.Sent_footer_content}
                            </font>

                        </p>
                        <br />

                        <table width="100%" border="0">


                            <tr>
                                <td width='65%' align='right'></td>
                                <td width='' ></td>
                                <td width='' align=''><font size="2" color="black">Ditetapkan di: Malang<br /></font></td>
                            </tr>
                            <tr>
                                <td width='65%' align='right'></td>
                                <td width=''></td>
                                <td width='' align=''><font size="2" color="black">Pada tanggal : {v_edaran.Sent_date}</font></td>
                            </tr>
                            <br />

                            <tr>
                                <td width='65%' align='right'></td>
                                <td width=''></td>
                                <td width='' align=''><font size="2" color="black">Jabatan,</font></td>
                            </tr>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <tr>
                                <td width='65%' align='right'></td>
                                <td width='0%'></td>
                                <td width='' align=''><font size="2" color="black">{v_edaran.Sent_creator}</font></td>
                            </tr>
                        </table>
                    </div>
                </Printer>
            </center>
        </div>



    )

}

export default V_Edaran
