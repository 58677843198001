import React, { Component, createRef } from 'react'
import QRCodeStyling from "qr-code-styling";
import V_DistribusiSuratKeluar from './v_dsuratkeluar'
import API from '../axios/Api'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import V_rektor from './v_rektor'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'
import koneksi from '../axios/koneksi'
import { Assistant } from '@material-ui/icons'
import koneksi_astor from '../axios/koneksi_astor'

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20,
  },
};


export default class hsuratkeluar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      distribusi: [],
      filter: '0',
      as: '0',
      users: [],
      idusers: '',
      unusers: '',
      aksusers: '',
      url: "https://asia.ac.id",
      fileExt: "png"
    }
    this.qrRef = createRef();
  }

  //interval = null;
  async dataapi() {
    const sentid = this.props.match.params.filter_code
    // console.log(sentid)

    await API.get(
      'distribusi/surat/keluar/new/normal/v' +
      key_surat.baseURL +
      '&kade=' +
      sentid +
      '&lv=' +
      this.state.aksusers,
    ).then((Response) =>
      this.setState({
        distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val })),
      }),
    )
  }

  async componentDidMount() {
    const user = sessionStorage.getItem('sid')
    const ak = sessionStorage.getItem('ak')
    const sstate = await API_ASTOR.get(
      'cari/id/sign/cari' + key_astor.baseURL + '&id=' + user,
    )
    const aksses = await API_ASTOR.get(
      'cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak,
    )
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    await API_ASTOR.get('surat/tujuan/multi/srh' + key_astor.baseURL).then(
      (Response) =>
        // console.log(Response.data)
        this.setState({
          users: Response.data,
        }),
    )


    this.dataapi()

    this.timer = setInterval(() => this.cek(), 1000)
  }

  getuser(id) {
    const user = this.state.users.find((usr) => usr.id === id)

    if (user) {
      return user.namauser
    }

  }

  cek() {
    if (sessionStorage.getItem('un') == null) {
      this.props.history.push('/loadingout')
    }
  }

  createQr = (id) => {
    const qrCode = new QRCodeStyling({
      width: 300,
      height: 300,
      dotsOptions: {
        color: "#000000",
        type: "rounded"
      },

    });

    qrCode.update({
      data: "https://sipa.asia.ac.id/showqr/" + id
    });

    qrCode.download({
      extension: "jpeg",
    });
  }

  componentWillUnmount() {
    this.timer = null
  }

  clicksemua = async (e) => {
    window.location.replace('/hsuratkeluar/0')
  }

  clickadanotulen = async (e) => {
    window.location.replace('/hsuratkeluar/1')
  }

  clicktidakadanotulen = async (e) => {
    // this.setState({
    //   filter: 2
    // })
    window.location.replace('/hsuratkeluar/2')
    // this.props.history.push('/hsuratkeluar/2')
  }

  render() {

    const { url, fileExt } = this.state;
    const renderData = this.state.distribusi.map((distribusi) => {
      return (
        <V_DistribusiSuratKeluar
          v_distribusi={distribusi}
          key={distribusi.id_suratkeluar}
        />
      )
    })

    function bukaModalku() {
      document.getElementById('modalmu').classList.remove('modalku')
      document.getElementById('modalmu').classList.add('muncul')
    }

    function tutupModalkita() {
      document.getElementById('modalmu').classList.remove('muncul')
      document.getElementById('modalmu').classList.add('modalku')
    }

    function okbos() {
      var y = document.querySelector('.canceljon')

      if (y.checked == true) {
        var co_popup2 = document.querySelector('.confirm')
        co_popup2.classList.remove('active')

        var co_popup3 = document.querySelector('.confirm-box')
        co_popup3.classList.remove('active')
      } else {
      }
    }
    function okwes() {
      var y = document.querySelector('.return-canceljon')

      if (y.checked == true) {
        var return_popup2 = document.querySelector('.return-confirm')
        return_popup2.classList.remove('active')

        var return_popup3 = document.querySelector('.return-confirm-box')
        return_popup3.classList.remove('active')
      } else {
      }
    }

    return (
      <div className="wrapper">
        <div className="">
          <div className="wrapper">
            <Menu />
            <Header />

            <div className="content-wrapper">
              <div className="loading-jon" id="preloader">
                <div className="jumper">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className="confirm">
                <input
                  type="checkbox"
                  className="confirmjon"
                  id="confirmjon"
                ></input>
                <input
                  type="checkbox"
                  className="canceljon"
                  id="canceljon"
                  onChange={okbos}
                ></input>
                <div className="confirm-box">
                  <div className="confirm-icon">
                    <i className="fa fa-exclamation"></i>
                  </div>
                  <header>Hapus ?</header>
                  <p>Apakah anda yakin ingin menghapus?</p>
                  <div className="confirm-btn-box">
                    <label for="confirmjon">OK</label>
                    <label for="canceljon">Cancel</label>
                  </div>
                </div>
              </div>
              <div className="return-confirm">
                <input
                  type="checkbox"
                  className="return-confirmjon"
                  id="return-confirmjon"
                ></input>
                <input
                  type="checkbox"
                  className="return-canceljon"
                  id="return-canceljon"
                  onChange={okwes}
                ></input>
                <div className="return-confirm-box">
                  <div className="return-confirm-icon">
                    <i className="fa fa-refresh"></i>
                  </div>
                  <header>Tampilkan ?</header>
                  <p>Apakah anda yakin ingin menampilkan data?</p>
                  <div className="return-confirm-btn-box">
                    <label for="return-confirmjon">OK</label>
                    <label for="return-canceljon">Cancel</label>
                  </div>
                </div>
              </div>

              {/* Content Header (Page header) */}
              <section className="content-header">
                <h1>History Surat Keluar</h1>
              </section>
            
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-success box-solid">
                      <div className="box-header">
                        <strong>Daftar Surat Keluar</strong>
                        <div className="box-tools pull-right">
                          <button
                            className="btn btn-success"
                            onClick={this.clicksemua}
                          >
                            Semua Surat
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={this.clickadanotulen}
                          >
                            Ada Notulen
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={this.clicktidakadanotulen}
                          >
                            Tidak Ada Notulen
                          </button>
                        </div>
                      </div>
                      {/* /.box-header */}
                      <div className="box-body">
                        <div className="table-responsive">
                          <table
                            id="example1"
                            className="table table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                {/* <th>Pilih</th> */}
                                <th>No</th>
                                <th>Kode Surat</th>
                                <th>Jenis Surat</th>
                                <th>Perihal</th>
                                <th>Tanggal</th>
                                <th>Tujuan</th>
                                <th>Kategori</th>
                                <th>Notulen & Absensi</th>
                                <th>Status</th>
                                <th>Theme Laporan</th>
                                <th>Opsi</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {renderData} */}
                              {this.state.distribusi.map((v_distribusi) => {
                                const script = document.createElement('script')

                                script.src = '../js/content.js'
                                script.async = true

                                document.body.appendChild(script)

                                async function handleClick() {
                                  await API.put(
                                    'surat/keluar/dellete/newnormal/ex?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' +
                                    v_distribusi.id_suratkeluar,
                                  )

                                  var loadingmamak = document.querySelector('#preloader')
                                  loadingmamak.classList.add('loading-jon')

                                  window.location.reload()
                                  // console.log('Tautan diklik.');
                                }

                                function pop() {

                                  var co_popup = document.querySelector('.confirm')
                                  co_popup.classList.add('active')
                                  var co_popup1 = document.querySelector('.confirm-box')
                                  co_popup1.classList.add('active')

                                  document.querySelector('.confirmjon').onclick = function () {
                                    var loadingmamak = document.querySelector('#preloader')
                                    loadingmamak.classList.remove('loading-jon')

                                    var co_popup2 = document.querySelector('.confirm')
                                    co_popup2.classList.remove('active')

                                    var co_popup3 = document.querySelector('.confirm-box')
                                    co_popup3.classList.remove('active')

                                    handleClick()
                                  }
                                }

                                async function handleAktif() {
                                  await API.put(
                                    'surat/keluar/aktifkan/surat/newnormal/act?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' +
                                    v_distribusi.id_suratkeluar,
                                  )

                                  var loadingmamak = document.querySelector('#preloader')
                                  loadingmamak.classList.add('loading-jon')

                                  window.location.reload()
                                }

                                function aktif() {
                                  // var pop = window.confirm("Apakah anda yakin untuk Mengembalikan data surat ini?")
                                  // if (pop == true) {
                                  //   handleAktif()
                                  // } else {
                                  //   //window.location.reload();
                                  // }
                                  var return_popup = document.querySelector('.return-confirm')
                                  return_popup.classList.add('active')
                                  var return_popup1 = document.querySelector('.return-confirm-box')
                                  return_popup1.classList.add('active')

                                  document.querySelector('.return-confirmjon').onclick = function () {
                                    var loadingmamak = document.querySelector('#preloader')
                                    loadingmamak.classList.remove('loading-jon')

                                    var return_popup2 = document.querySelector('.return-confirm')
                                    return_popup2.classList.remove('active')

                                    var return_popup3 = document.querySelector('.return-confirm-box')
                                    return_popup3.classList.remove('active')

                                    handleAktif()
                                  }
                                }

                                async function updatetrue() {
                                  await API.put(
                                    'update/theme/surat/terbaru/update?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' +
                                    v_distribusi.id_suratkeluar,
                                  )
                                  window.location.reload()
                                }

                                function updatetheme() {
                                  var pop = window.confirm(
                                    'Apakah anda yakin untuk Mengembalikan data surat ini?',
                                  )
                                  if (pop == true) {
                                    updatetrue()
                                  } else {
                                    //window.location.reload();
                                  }
                                }

                                const status = (val) => {
                                  if (val === 1) return 'Dikirim'
                                  else return 'Disimpan'
                                }

                                const stype = (val) => {
                                  if (val === 'S.Kep') return 'Surat Keputusan'
                                  else if (val === 'S.Ket')
                                    return 'Surat Keterangan'
                                  else if (val === 'S.Ed') return 'Surat Edaran'
                                  else if (val === 'ST') return 'Surat Tugas'
                                  else if (val === 'SK') return 'Surat Kuasa'
                                  else if (val === 'SP')
                                    return 'Surat Peringatan'
                                  else if (val === 'SIK')
                                    return 'Surat Ijin Kegiatan'
                                  else if (val === 'S.Pn')
                                    return 'Surat Perjanjian'
                                  else if (val === 'S.Tap')
                                    return 'Surat Ketetapan'
                                  else if (val === 'S.Ker')
                                    return 'Surat Kerjasama'
                                  else if (val === 'S.Per')
                                    return 'Surat Perintah'
                                  else if (val === 'Und')
                                    return 'Surat Undangan'
                                  else if (val === 'Peng')
                                    return 'Surat Pengumuman'
                                  else if (val === 'L') return 'Surat Lain Lain'
                                  else return 'Surat Lain Lain'
                                }

                                const sts = (val) => {
                                  if (val === 'S') return 'Surat Keluar'
                                  else return 'Emergency'
                                }

                                const reportview = (val) => { }

                                const rep = (val) => {
                                  if (val === 'S.Kep') return ''
                                  else if (val === 'S.Ket') return ''
                                  else if (val === 'S.Ed') return 'edaran'
                                  else if (val === 'ST') return ''
                                  else if (val === 'SK') return 'kuasa'
                                  else if (val === 'SP') return ''
                                  else if (val === 'SIK') return ''
                                  else if (val === 'S.Pn') return ''
                                  else if (val === 'S.Tap') return ''
                                  else if (val === 'S.Ker') return 'kerjasama'
                                  else if (val === 'S.Per') return ''
                                  else if (val === 'Und') return ''
                                  else if (val === 'Peng') return ''
                                  else if (val === 'L') return ''
                                  else return 'Surat Lain Lain'
                                }

                                const pdfopen = () => {
                                  // window.open(`${koneksi.baseURL}` + v_distribusi.file_suratkeluar, '_blank');
                                }

                                const notulen = (val) => {
                                  if (val == '-')
                                    return <td align="center">-</td>
                                  else if (val == '0')
                                    return (
                                      <td align="center">
                                        <a
                                          href={
                                            '/isinotulen/' +
                                            v_distribusi.id_suratkeluar
                                          }
                                          style={{
                                            width: '110px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                            backgroundColor: '#6ba3d1',
                                            borderColor: '#6ba3d1',
                                          }}
                                          className="btn btn-info"
                                        >
                                          Isi Notulen
                                        </a>
                                      </td>
                                    )
                                  // else if (val === "1") return <Link to="#" className="btn btn-primary">Edit Notulen</Link>
                                  else
                                    return (
                                      <td align="center">
                                        <a
                                          href={
                                            '/editnotulen/' +
                                            v_distribusi.id_suratkeluar
                                          }
                                          className="btn btn-warning"
                                          style={{
                                            width: '110px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                          }}
                                        >
                                          Edit Notulen
                                        </a>
                                        <br />
                                        <a
                                          href={
                                            '/dnotulen/' +
                                            v_distribusi.id_suratkeluar +
                                            '/' +
                                            v_distribusi.noduratlink
                                          }
                                          className="btn btn-primary"
                                          style={{
                                            width: '110px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                          }}
                                          target="_blank"
                                        >
                                          Download
                                        </a>
                                        {/* <a onClick={() => pdfopen()} style={{ width: '110px', marginTop: '2px', marginBottom: '2px', marginLeft: '0px' }} className="btn btn-primary">Download</a> */}
                                        {/* <Link to={'/notulenview/' + v_distribusi.id_suratkeluar} className="btn btn-success">Lihat Dokumen</Link> */}
                                        {/* <a href={config.baseURL + v_distribusi.file_suratkeluar} className="btn btn-success">Lihat Dokumen</a> */}
                                        {/* <Link to={"./" + config.baseURL + v_distribusi.id_suratkeluar} className="btn btn-success">Lihat Dokumen</Link> */}
                                      </td>
                                    )
                                }

                                const valstatus = (val) => {
                                  if (val === '0')
                                    return (
                                      <a
                                        style={{ fontSize: '12px' }}
                                        className="label label-primary fontku"
                                      >
                                        {' '}
                                        Surat{' '}
                                      </a>
                                    )
                                  else if (val === '1')
                                    return (
                                      <a
                                        style={{ fontSize: '12px' }}
                                        className="label label-warning fontku"
                                      >
                                        {' '}
                                        Emergensi{' '}
                                      </a>
                                    )
                                  else return ''
                                }

                                const preview = (val) => {
                                  if (val == '-') return '-'
                                  else if (val == '0') return ''
                                  else if (val == '1')
                                    return (
                                      <a
                                        href={
                                          `${koneksi.baseURL}` +
                                          v_distribusi.file_suratkeluar
                                        }
                                        className="btn btn-success"
                                        style={{ marginLeft: '10px' }}
                                      >
                                        Download PDF
                                      </a>
                                    )
                                  else return ''
                                }

                                const hhdn = (val) => {
                                  if (val == '1')
                                    return (
                                      <td align="center">
                                        <a
                                          href={
                                            '/esurat/' +
                                            v_distribusi.id_suratkeluar
                                          }
                                          style={{
                                            width: '70px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                            marginLeft: '3px',
                                          }}
                                          className="btn btn-info"
                                        >
                                          Edit
                                        </a>
                                        <Link
                                          target="_blank"
                                          to={`/laporan/${v_distribusi.id_suratkeluar}`}
                                          style={{
                                            width: '70px',
                                            marginTop: '2px',
                                            marginBottom: '3px',
                                            marginLeft: '2px',
                                          }}
                                          className="btn btn-success"
                                        >
                                          Preview
                                        </Link>
                                        <button
                                          onClick={pop}
                                          className="btn btn-danger"
                                          style={{
                                            width: '70px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                            marginLeft: '3px',
                                          }}
                                        >
                                          Hapus
                                        </button>
                                        <a
                                          style={{
                                            width: '70px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                            marginLeft: '3px',
                                          }}
                                          className="btn btn-info"
                                          onClick={() => this.createQr(v_distribusi.id_suratkeluar)}
                                        >
                                          QR-Code
                                        </a>
                                      </td>
                                    )
                                  else if (val == '0')
                                    return (
                                      <td align="center">
                                        <button
                                          onClick={aktif}
                                          className="btn btn-primary"
                                          style={{
                                            width: '110px',
                                            marginTop: '2px',
                                            marginBottom: '2px',
                                          }}
                                        >
                                          Aktifkan
                                        </button>
                                        {/* <a href={"/" + v_distribusi.id_suratkeluar} className="btn btn-primary">Aktifkan</a> */}
                                      </td>
                                    )
                                  else return ''
                                }

                                const ttheme = (val) => {
                                  if (val == v_distribusi.vtheme_jenis)
                                    return (
                                      <span style={{ color: 'green' }}>
                                        Terbaru
                                      </span>
                                    )
                                  else
                                    return (
                                      <button
                                        onClick={updatetheme}
                                        id="btnckanan"
                                        style={{ color: 'red' }}
                                      >
                                        Update Theme
                                      </button>
                                    )
                                }

                                // function bukaModalku() {
                                //   document.getElementById("modalmu").classList.remove("modalku");
                                //   document.getElementById("modalmu").classList.add("muncul");

                                // }

                                // function tutupModalkita() {
                                //   document.getElementById("modalmu").classList.remove("muncul");
                                //   document.getElementById("modalmu").classList.add("modalku");
                                // }

                                const ntujuan = (val) => {
                                  if (val == 0)
                                    return (
                                      <td align="center">
                                        {v_distribusi.tujuan_suratkeluar}
                                      </td>
                                    )
                                  else
                                    return (
                                      <td align="center">
                                        {/* <strong onClick={bukaModalku} className="btnlisuser" id="btnlisuser">{`....`}</strong> */}
                                        {/* <button onClick={bukaModalku} className="btn btn-success">Detail</button> */}
                                        {/* <div className="modalku" id="modalmu">
                                        <div className="modal-contentku" style={{ height: '-webkit-fill-available', minHeight: '100%' }}>
                                          <div className="col-md-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                            <div className="col-md-8" align="left">
                                              Tujuan Surat No : 
                                              <strong style={{ color: '#00a65a' }}>{v_distribusi.no_suratkeluar}</strong>
                                            </div>
                                            <div className="col-md-4">
                                              <span className="closeku fa fa-close" onClick={tutupModalkita}></span>
                                            </div>
                                          </div>
                                          <div className="col-md-12" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                            <div className="table-responsive">
                                              <table id="example1" className="table table-bordered table-hover">
                                                <thead>
                                                  <tr>
                                                    <th style={{ width: '40px' }}>No</th>
                                                    <th>Nama</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {v_distribusi.tujuan_suratkeluar.map(ttujuan => {
                                                    return (
                                                        <tr key={ttujuan.nnnn}>
                                                          <td>{ttujuan.nnnn}</td>
                                                          <td>
                                                            {this.getuser(ttujuan.id)}
                                                          </td>
                                                        </tr>
                                                    )
                                                  })
                                                  }
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                        <table>
                                          {v_distribusi.tujuan_suratkeluar.map(
                                            (ttujuan) => {
                                              // console.log(v_distribusi.no_suratkeluar,ttujuan.id)
                                              return (
                                                <tr key={ttujuan.nnnn} style={{ borderBottom: 'rgb(218, 216, 216)', borderStyle: 'solid', borderLeft: 'transparent', borderTop: 'transparent', borderRight: 'transparent' }}>
                                                  <td>
                                                    {/* {ttujuan.nnnn} */} -
                                                  </td>
                                                  <td>
                                                    {/* {ttujuan.id} */}
                                                    {this.getuser(ttujuan.id)}
                                                  </td>
                                                </tr>
                                              )
                                            },
                                          )}
                                        </table>
                                      </td>
                                    )
                                }
                                // console.log(v_distribusi.nn)
                                return (
                                  // <>

                                  <tr key={v_distribusi.No}>
                                    <td>{v_distribusi.No}</td>
                                    <td>{v_distribusi.no_suratkeluar}</td>
                                    <td>{v_distribusi.id_jenis}</td>
                                    <td>{v_distribusi.hal_suratkeluar}</td>
                                    <td align="center">
                                      {v_distribusi.tgl_suratkeluar}
                                    </td>
                                    {ntujuan(v_distribusi.nintujuan)}
                                    {/* {v_distribusi.tujuan_suratkeluar} */}
                                    <td align="center">
                                      {v_distribusi.ketegori_surat}
                                    </td>

                                    {notulen(v_distribusi.file_suratkeluar)}
                                    <td align="center">
                                      {' '}
                                      {valstatus(v_distribusi.status)}{' '}
                                    </td>
                                    <td align="center">
                                      {ttheme(v_distribusi.vtheme_suratkeluar)}
                                    </td>

                                    {hhdn(v_distribusi.hdn)}
                                  </tr>

                                  // </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        {/* <button type="button" className="btn btn-primary">Kirim</button> */}
                        {/* /.box-body */}
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </section>
              {/* /.content */}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}
