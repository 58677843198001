import React from 'react'
import Error from './pages/NotFound2'
import { BrowserRouter, Route, Switch } from 'react-router-dom'


//AUTH
import Loading from './pages/Loading'
import linkdash from './pages/linkdash'
import Loadingout from './pages/Loadingout'


//SEKRETARIAT
import Notfound from './pages/NotFound'
import Home from './pages/Home'
import DistribusiSurat from './pages/DistribusiSurat'
import DistribusiSuratKeluar from './pages/DistribusiSuratKeluar'
import Surat from './pages/Surat'
import TerimaSurat from './pages/TerimaSurat'
import Undangan from './pages/Undangan'
import Notulen from './pages/Notulen'
import NotulenIsi from './pages/NotulenIsi'
import NotulenEdit from './pages/NotulenEdit'
import Emergency from './pages/Emergency'
import Login from './pages/Login'
import Notif from './pages/Notif_tidakada_Notulen'
import Notif_ada_Notulen from './pages/Notif_ada_Notulen'
import Notif_tidakada_Notulen from './pages/Notif_tidakada_Notulen'
import Notif_ReqSurat from './pages/Notif_ReqSurat'
import History_SuratUndangan from './pages/History_SuratUndangan'
import SuratEdit from './pages/SuratEdit'
import editnonotulen from './pages/editnonotulen'

//ADMINISTRATOR
// import Beranda from './pages/administrator/Beranda'
// import KelolaUser from './pages/administrator/KelolaUser'
// import TambahUser from './pages/administrator/TambahUser'
// import KelolaMenuSystem from './pages/administrator/KelolaMenuSystem'
// import KelolaSubMenu from './pages/administrator/KelolaSubMenu'
// import LogBAA from './pages/administrator/LogBAA'
// import LogBAU from './pages/administrator/LogBAU'
// import LogSurat from './pages/administrator/LogSurat'
// import KelolaRule from './pages/administrator/KelolaRule'

//pdf
import Edaran from './pdf/Edaran'
import Kerjasama from './pdf/Kerjasama'
import Kuasa from './pdf/Kuasa'
//import Lain from './pdf/Lain'


// New Normal
import suratk from  './pages/suratk'
import ptheme from './pages/ptheme'
import ttheme from './pages/ttheme'
import djenis from './pages/djenis'
import tjenis from  './pages/tjenis'
import ejenis from './pages/ejenis'
import etheme from './pages/etheme'
import bsurat from './pages/bsurat'
import codelist from './pages/codelist'
import hsuratkeluar from './pages/hsuratkeluar'
import bemergency from  './pages/bemergency'
import femergensi from  './pages/femergensi'
import esurat from './pages/esurat'
import etsurat from './pages/etsurat'
import esurat2 from './pages/esurat2'
import laporan from './pdf/laporan'
import vtheme from './pages/vtheme'
import isinotulen from  './pages/isinotulen'
import editnotulen from './pages/editnotulen'
import notulenview from './pages/notulenview'
import SNomorSurat from './pages/SNomorSurat'
import Nyoba from './pdf/nyoba.jsx'
import downloadterimasurat from './pdf/terimapdf'
import dnotulen from './pdf/dnotulen'
import inboxframe from './pages/inboxframe'
import pdfdownlotif from './pdf/pdfdownlotif'
import notdash from './pages/notdash'
import linkinbox from './pages/linkinbox'
import preview from './pages/preview'
// import parttirex from './trex/index'
import showqr from './pages/showqr'


function App() {
  return (
    <BrowserRouter>
      <Switch>
      
        {/*LOGIN*/}
        <Route path="/" exact component={Login} />

        {/*SEKRETRIAT*/}
        <Route path="/loading/:un" exact component={Loading} />
        <Route path="/linkdash/:un/:usr/:pss" exact component={linkdash} />
        <Route path="/loadingout" exact component={Loadingout} />
        {/* <Route path="/parttirex" exact component={parttirex} /> */}

        {/*SEKRETRIAT*/}
        <Route path="/home" exact component={Home} />
        <Route path="/distribusisurat" exact component={DistribusiSurat} />
        <Route path="/surat" exact component={Surat} />
        <Route path="/terimasurat" exact component={TerimaSurat} />
        <Route path="/distribusisuratkeluar" exact component={DistribusiSuratKeluar} />
        <Route path="/undangan" exact component={Undangan} />
        <Route path="/notulen" exact component={Notulen} />
        <Route path="/notulenisi/:invite_id" exact component={NotulenIsi} />
        <Route path="/notulenedit/:invite_id" exact component={NotulenEdit} />
        <Route path="/emergency" exact component={Emergency} />
        <Route path="/notif" exact component={Notif} />
        <Route path="/notif_ada_notulen" exact component={Notif_ada_Notulen} />
        <Route path="/notif_tidakada_notulen" exact component={Notif_tidakada_Notulen} />
        <Route path="/notif_reqsurat" exact component={Notif_ReqSurat} />
        <Route path="/history_suratundangan" exact component={History_SuratUndangan} />
        <Route path="/suratedit/:sent_id" exact component={SuratEdit} />
        <Route path="/editnonotulen/:invite_id" exact component={editnonotulen} />
        <Route path="/nsurat" exact component={SNomorSurat} />
        <Route path="/nyobapaged" exact component={Nyoba} />

        {/*ADMINISTRATOR*/}
        {/* <Route path="/administrator/beranda" exact={false} component={Beranda} />
        <Route path="/administrator/kelolauser" exact component={KelolaUser} />
        <Route path="/administrator/tambahuser" exact component={TambahUser} />
        <Route path="/administrator/kelolamenusystem" exact component={KelolaMenuSystem} />
        <Route path="/administrator/kelolasubmenu" exact component={KelolaSubMenu} />
        <Route path="/administrator/logbaa" exact component={LogBAA } />
        <Route path="/administrator/logbau" exact component={LogBAU} />
        <Route path="/administrator/logsurat" exact component={LogSurat} />
        <Route path="/administrator/kelolarule" exact component={KelolaRule} />

        <Route path="/edaran/:sent_id" exact component={Edaran} />
        <Route path="/kerjasama/:sent_id" exact component={Kerjasama} />
        <Route path="/kuasa/:sent_id" exact component={Kuasa} /> */}
        {/*pdf
        
        
        //
        <Route path="/lain" exact component={Lain} />
       
        */}

        {/* New Normal */}
        <Route path="/suratk" exact component={suratk} />
        <Route path="/ptheme" exact component={ptheme} />
        <Route path="/ttheme" exact component={ttheme} />
        <Route path="/djenis" exact component={djenis} />
        <Route path="/tjenis" exact component={tjenis} />
        <Route path="/ejenis/:id_jenis" exact component={ejenis} />
        <Route path="/etheme/:id_theme" exact component={etheme} />
        <Route path="/bsurat/:id_theme" exact component={bsurat} />
        <Route path="/codelist" exact component={codelist} />
        <Route path="/hsuratkeluar/:filter_code" exact component={hsuratkeluar} />
        <Route path="/bemergency" exact component={bemergency} />
        <Route path="/femergensi/:id_theme/:tgl/:bln/:thn" exact component={femergensi} />
        <Route path="/esurat/:id_suratkeluar" exact component={esurat} />
        <Route path="/etsurat/:Recieve_id" exact component={etsurat} />
        <Route path="/esurat2/:id_suratkeluar" exact component={esurat2} />
        <Route path='/laporan/:id_suratkeluar' exact component={laporan} />
        <Route path='/vtheme/:id_jenis' exact component={vtheme} />
        <Route path='/isinotulen/:id_surat' exact component={isinotulen} />
        <Route path='/editnotulen/:id_surat' exact component={editnotulen} />
        <Route path='/notulenview/:id_jenis' exact component={notulenview} />
        <Route path='/pdfterimasurat/:nomersurat' exact component={downloadterimasurat} />
        <Route path='/dnotulen/:ids/:nomersurat' exact component={dnotulen} />
        <Route path='/inboxframe/:hal' exact component={inboxframe} />
        <Route path='/pdfdownlotif/:nomersurat' exact component={pdfdownlotif} />
        <Route path='/notdash' exact component={notdash} />
        <Route path="/preview/:Recieve_id" exact component={preview} />
        <Route path="/showqr/:id_suratkeluar" exact component={showqr} />


        {/* DASHBOARD */}
        <Route path="/linkinbox/:un/:usr/:pss" exact component={linkinbox} />

        <Route component={Notfound} />
        <Route component={Error} />

      </Switch>
    </BrowserRouter>
  )
}

export default App
