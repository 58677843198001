import React, { Component } from 'react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'

class SNomorSurat extends Component {
    render() {
        var date = new Date();
        var bulan = date.getMonth();
        var tahun = date.getFullYear();
        switch (bulan) {
            case 0: bulan = "I"; break;
            case 1: bulan = "II"; break;
            case 2: bulan = "III"; break;
            case 3: bulan = "IV"; break;
            case 4: bulan = "V"; break;
            case 5: bulan = "VI"; break;
            case 6: bulan = "VII"; break;
            case 7: bulan = "VIII"; break;
            case 8: bulan = "IX"; break;
            case 9: bulan = "X"; break;
            case 10: bulan = "XI"; break;
            case 11: bulan = "XII"; break;
        }
        var bulanromawi = bulan;
        return (
            <div className="wrapper" >
                <Menu />
                <Header />
                <div className="content-wrapper">
                    <section className="content-header">
                        <h1>
                            Setting Nomor Surat
                        </h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 margin-bottom-30">
                                <div className="box box-primary box-solid">
                                    <div className="box-header">
                                        <strong>Form Setting Nomor Surat</strong>
                                        <div className="box-tools pull-right">
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        <form name="myForm">
                                            <div className="table-responsive striped col-md-12">
                                                <table id="example1" className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>No Urut</th>
                                                            <th>Form Isian</th>
                                                            <th>Posisi</th>
                                                            <th>Keterangan</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td width="80px" align="center">1</td>
                                                            <td>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">
                                                                                <i className="fa fa-list"></i>
                                                                            </div>
                                                                            <select name="" className="form-control" required>
                                                                                <option value="">-- PILIH --</option>
                                                                                <option value="">Nomor Surat</option>
                                                                                <option value="">Kategori Surat</option>
                                                                                <option value="">Divisi</option>
                                                                                <option value="">Perguruan Tinggi</option>
                                                                                <option value="">Bulan / Romawi</option>
                                                                                <option value="">Tahun</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <b style={{ color: 'red' }}>P1</b>/P2/P3/P4/P5/P6
                                                            </td>
                                                            <td rowSpan="6">
                                                                <p>
                                                                    1. Isi pilihan pada menu form Isian <br />
                                                                    2. Urutan / susunan nomer surat berawal dari kiri<br />
                                                                    3. Jika salah satu urutan tidak digunakan, maka kosongi saja
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="80px" align="center">2</td>
                                                            <td>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">
                                                                                <i className="fa fa-list"></i>
                                                                            </div>
                                                                            <select name="" className="form-control" required>
                                                                                <option value="">-- PILIH --</option>
                                                                                <option value="">Nomor Surat</option>
                                                                                <option value="">Kategori Surat</option>
                                                                                <option value="">Divisi</option>
                                                                                <option value="">Perguruan Tinggi</option>
                                                                                <option value="">Bulan / Romawi</option>
                                                                                <option value="">Tahun</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                P1/<b style={{ color: 'red' }}>P2</b>/P3/P4/P5/P6
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="80px" align="center">3</td>
                                                            <td>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">
                                                                                <i className="fa fa-list"></i>
                                                                            </div>
                                                                            <select name="" className="form-control" required>
                                                                                <option value="">-- PILIH --</option>
                                                                                <option value="">Nomor Surat</option>
                                                                                <option value="">Kategori Surat</option>
                                                                                <option value="">Divisi</option>
                                                                                <option value="">Perguruan Tinggi</option>
                                                                                <option value="">Bulan / Romawi</option>
                                                                                <option value="">Tahun</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                P1/P2/<b style={{ color: 'red' }}>P3</b>/P4/P5/P6
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="80px" align="center">4</td>
                                                            <td>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">
                                                                                <i className="fa fa-list"></i>
                                                                            </div>
                                                                            <select name="" className="form-control" required>
                                                                                <option value="">-- PILIH --</option>
                                                                                <option value="">Nomor Surat</option>
                                                                                <option value="">Kategori Surat</option>
                                                                                <option value="">Divisi</option>
                                                                                <option value="">Perguruan Tinggi</option>
                                                                                <option value="">Bulan / Romawi</option>
                                                                                <option value="">Tahun</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                P1/P2/P3/<b style={{ color: 'red' }}>P4</b>/P5/P6
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="80px" align="center">5</td>
                                                            <td>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">
                                                                                <i className="fa fa-list"></i>
                                                                            </div>
                                                                            <select name="" className="form-control" required>
                                                                                <option value="">-- PILIH --</option>
                                                                                <option value="">Nomor Surat</option>
                                                                                <option value="">Kategori Surat</option>
                                                                                <option value="">Divisi</option>
                                                                                <option value="">Perguruan Tinggi</option>
                                                                                <option value="">Bulan / Romawi</option>
                                                                                <option value="">Tahun</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                P1/P2/P3/P4/<b style={{ color: 'red' }}>P5</b>/P6
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="80px" align="center">6</td>
                                                            <td>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <div className="input-group-addon">
                                                                                <i className="fa fa-list"></i>
                                                                            </div>
                                                                            <select name="" className="form-control" required>
                                                                                <option value="">-- PILIH --</option>
                                                                                <option value="">Nomor Surat</option>
                                                                                <option value="">Kategori Surat</option>
                                                                                <option value="">Divisi</option>
                                                                                <option value="">Perguruan Tinggi</option>
                                                                                <option value="">Bulan / Romawi</option>
                                                                                <option value="">Tahun</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                P1/P2/P3/P4/P5/<b style={{ color: 'red' }}>P6</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-12 text-right">
                                                <div className="form-group">
                                                    <button type="Submit" className="btn btn-primary">Simpan</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        );
    }
}

export default SNomorSurat;