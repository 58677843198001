import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../axios/Api'


function V_History_SuratUndangan({ v_history_suratundangan }) {



    async function deleteNotif() {
        // await API.delete('delete_recieve.php?Recieve_code'+v_history_suratundangan.Recieve_code)
    }


    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);




    // componentDidMount (){
    //     const script = document.createElement("script");

    //     script.src ='js/content.js';
    //     script.async = true;

    //     document.body.appendChild(script);
    // }

    // render() {
    return (

        <tr>
            <td>{v_history_suratundangan.No}</td>
            <td>{v_history_suratundangan.Invite_code}</td>
            <td>{v_history_suratundangan.Invite_creator}</td>
            <td>{v_history_suratundangan.Invite_about}</td>
            <td>{v_history_suratundangan.Invite_date}</td>
            <td>{v_history_suratundangan.Invite_to}</td>
            <td><Link to="/notulenedit" className="btn btn-success">Edit</Link> &nbsp;
                       <i className="btn btn-danger" onClick={deleteNotif}>Hapus</i>
            </td>
        </tr>


    )
}

export default V_History_SuratUndangan