import React, { Component } from 'react'
import V_DistribusiSurat from './V_DistribusiSurat'
import API from '../axios/Api'
import key_surat from '../axios/key'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API_ASTOR from '../axios/Api_astor'
import key_astor from '../axios/key_astor'
import conf_surat from '../axios/koneksi'

export default class DistribusiSurat extends Component {

  state = {
    distribusi: [],
    users: [],
    idusers: '',
    unusers: '',
    aksusers: '',
    cariinbox: ''
  }

  getuser(id) {
    const user = this.state.users.find(usr => usr.id_rektor === id)
    // .then(qwe => )
    if (user) {
      return user.rektor_name
    }
    return '-'
    // console.log(user)

  }

  rekidtujuan(id) {
    const rekid = this.state.users.find(rekidnya => rekidnya.id === id)
    // .then(qwe => )
    if (rekid) {
      return rekid.rektor_id
    }
    return '-'
    // console.log(user)

  }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");

    const sentto = this.props.match.params.hal
    // const cariinbox = await API_ASTOR.get('cari/id/inbox/dash/cari' + key_astor.baseURL + '&id=' + sentto)

    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    await API_ASTOR.get('surat/cari/tujuan/struktural' + key_astor.baseURL)
      .then(
        Response => this.setState({
          users: Response.data
        })
      )

    await API.get("surat/masuk/notif/dash/view" + key_surat.baseURL + "&id=" + user + "&hal=" + sentto)
      .then(Response => this.setState({
        distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    this.timer = setInterval(() => this.cek(), 1000);

    // console.log(this.state.users)

  }

  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')
    }
  }

  clicksemua() {
    window.location.replace('/inboxframe/0')
  }

  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_DistribusiSurat v_distribusi={distribusi} key={distribusi.Recieve_code} />
      )
    })

    return (
      <div className="wrapper">
        <div className="">
          <div className="wrapper" style={{ backgroundColor: 'transparant', border: '0' }}>
            <div className="">
              <section className="">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-success box-solid">
                      <div className="box-header">
                        <strong>Daftar Surat Mauk</strong>
                        <div className="box-tools pull-right">
                          <button className="btn btn-success" onClick={this.clicksemua}>Semua Surat</button>
                          <button className="btn btn-success" onClick={this.clickadanotulen}>Belum Dibuka</button>
                          <button className="btn btn-success" onClick={this.clicktidakadanotulen}>Sudah Dibuka</button>
                        </div>
                      </div>
                      <div className="box-body">
                        <div className="table-responsive">

                          <table id="example1" className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Kode Surat</th>
                                <th>Pengirim</th>
                                <th>Perihal</th>
                                <th>Tgl Terima</th>
                                <th>Status</th>
                                <th>Opsi</th>
                              </tr>
                            </thead>
                            <tbody>

                              {this.state.distribusi.map(v_distribusi => {
                                const script = document.createElement("script");

                                script.src = '../js/content.js';
                                script.async = true;

                                document.body.appendChild(script);

                                const cekKondisi = (val) => {
                                  if (val == 1) return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-open" viewBox="0 0 16 16">
                                    <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.818l5.724 3.465L8 8.917l1.276.766L15 6.218V5.4a1 1 0 0 0-.53-.882l-6-3.2zM15 7.388l-4.754 2.877L15 13.117v-5.73zm-.035 6.874L8 10.083l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738zM1 13.117l4.754-2.852L1 7.387v5.73zM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2z" />
                                  </svg>
                                  else return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                                  </svg>
                                }

                                return (
                                  <tr key={v_distribusi.No}>
                                    <td>{v_distribusi.No}</td>
                                    <td style = {{display: 'none'}}>{v_distribusi.Recieveid}</td>
                                    <td>{v_distribusi.Recievecode}</td>
                                    <td>{v_distribusi.Recievesender}</td>
                                    <td>{v_distribusi.Recieveabout}</td>
                                    <td>{v_distribusi.Recievedate}</td>
                                    <td align="center">
                                      {cekKondisi(v_distribusi.Recievedistributionstatus)}

                                    </td>
                                    <td>
                                      <Link className="btn btn-success" to={`../pdfdownlotif/` + v_distribusi.Recievecode} style={{ marginRight: '4px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                                          <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                          <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                        </svg>
                                      </Link>
                                      <Link className="btn btn-warning" to={'../preview/' + v_distribusi.Recieveid}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                        </svg>
                                      </Link>
                                    </td>
                                  </tr>

                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    )
  }
}