import React, { Component } from 'react'
// import Printer, { print } from 'react-pdf-print'
// import { pdfjs } from 'react-pdf';
import parse from 'html-react-parser';
import ReactHtmlParser from 'react-html-parser';
import rp from 'react-html-parser';

// const ids = ['1']

function v_vtheme({ v_distribusi }) {
    const parse = require('html-react-parser');

    return (

        <div>
            <input type="checkbox" checked="" name="check_list[]" value="value id"></input>
        </div>

    )
}
export default v_vtheme