import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
//import V_Inbox_Code from './V_Inbox_Code'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import Select from "react-select";
import V_SEOptiona from './V_SEOptiona'
import V_SEOptionb from './V_SEOptionb'
import ReactHtmlParser from 'react-html-parser'

export default class SuratEdit extends Component {
  constructor() {
    super()
    this.Sent_groub = ''
    this.state = {
      data: [],
      voption: [],
      voptionb: [],
      sent_id: '',
      Sent_type: '',
      Sent_creator: '',
      Sentcreator: '',
      Sent_groub: '',
      Sent_to: '',
      Sent_category: '',
      Sent_date: '',
      Sent_about: '',
      Sent_code: '',
      Sent_content: ''
    }
  }

  handleInputChange = (event) => {
    //event.preventDefault()
    //console.log(event)
    //console.log(event.target.name)
    //console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
    //console.log(this.setState)
  }

  handlesubmit = async (event) => {
    // console.log(this.state)
    event.preventDefault()
    API.post('imassage/user/send/update/true?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + this.state.sent_id,this.state)
      .then(sembarang => console.log(sembarang))
    // this.mboh()
  }

  mboh(){
    this.props.history.push('/distribusisuratkeluar')
  }

  async componentDidMount() {
    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);

    const sentid = this.props.match.params.sent_id
    const res = await API.get('surat/keluar/edit/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sent_id=' + sentid)
    this.setState({
      sent_id: res.data[0].sent_id,
      Sent_code: res.data[0].sent_code,
      Sent_type: res.data[0].sent_type,
      Sent_creator: res.data[0].sent_creator,
      Sent_groub: res.data[0].sent_groub,
      Sent_category: res.data[0].sent_category,
      Sent_date: res.data[0].sent_date,
      Sent_about: res.data[0].sent_about,
      Sent_content: res.data[0].sent_content
    })

    //await API.get('surat/keluar/edit/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sent_id=' + sentid)
    //  .then(Response => this.setState({
    //    data: Response.data
    //  }))

    //const as = await API.get('surat/keluar/edit/option/a?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idvr=' + this.state.Sent_creator)
    await API.get('surat/keluar/edit/option/a?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idvr=' + this.state.Sent_creator)
    .then(Response => this.setState({
    //this.setState({
      voption: Response.data
    }))
        

    await API.get('surat/keluar/edit/optionb/b?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE')
      .then(Response => this.setState({
        voptionb: Response.data
      }))


    //console.log(res.data[0].sent_creator)
    console.log(this.state.voption)
    //console.log(this.state)
    //console.log(this.state.Sent_creator)
  }

  render() {
    const { Sent_type, Sent_creator, Sent_groub, Sent_category, Sent_date, Sent_about, Sent_content } = this.state

    const stype = (val) => {
      if (val === "S.Kep") return 'Surat Keputusan'
      else if (val === "S.Ket") return 'Surat Keterangan'
      else if (val === "S.Ed") return 'Surat Edaran'
      else if (val === "ST") return 'Surat Tugas'
      else if (val === "SK") return 'Surat Kuasa'
      else if (val === "SP") return 'Surat Peringatan'
      else if (val === "SIK") return 'Surat Ijin Kegiatan'
      else if (val === "S.Pn") return 'Surat Perjanjian'
      else if (val === "S.Tap") return 'Surat Ketetapan'
      else if (val === "S.Ker") return 'Surat Kerjasama'
      else if (val === "S.Per") return 'Surat Perintah'
      else if (val === "Und") return 'Surat Undangan'
      else if (val === "Peng") return 'Surat Pengumuman'
      else if (val === "L") return 'Surat Lain Lain'
      else return 'Surat Lain Lain'
    }


    const vrektor = this.state.voption.map(voption => {
      return (
        <V_SEOptiona v_seoptiona={voption} key={voption.rektor_id} />
      )
    })

    const vrektorb = this.state.voptionb.map(voptionb => {
      return (
        <V_SEOptionb v_seoptionb={voptionb} key={voptionb.rektor_id} />
      )
    })

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Edit Permintaan Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Edit Permintaan Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form onSubmit={this.handlesubmit}> */}
                      <form method="post" action={`${config.baseURL}imassage/user/send/update/true?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=${this.state.sent_id}`}>
                      <div className="form-group">
                        <label>Jenis Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-bars"></i>
                          </div>
                          <select name="Sent_type" className="form-control" required onChange={this.handleInputChange}>
                            <option value={`${this.state.Sent_type}`} >{stype(`${this.state.Sent_type}`)}</option>
                            <option value="">--------------------</option>
                            <option value="S.Kep">Surat Keputusan</option>
                            <option value="S.Ket">Surat Keterangan</option>
                            <option value="S.Ed">Surat Edaran</option>
                            <option value="ST">Surat Tugas</option>
                            <option value="SK">Surat Kuasa</option>
                            <option value="SP">Surat Peringatan</option>
                            <option value="SIK">Surat Ijin Kegiatan</option>
                            <option value="S.Pn">Surat Perjanjian</option>
                            <option value="S.Tap">Surat Ketetapan</option>
                            <option value="S.Ker">Surat Kerjasama</option>
                            <option value="S.Per">Surat Perintah</option>
                            <option value="Peng">Pengumuman</option>
                            <option value="L">Surat Lain-lain</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Pembuat Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-user"></i>
                          </div>
                          <select name="Sent_creator" className="form-control" required onChange={this.handleInputChange}>
                            {/* <option value={this.state.voption}>{this.state.voption}</option> */}
                            {vrektor}

                            {vrektorb}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Tujuan</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-institution"></i>
                          </div>
                          <input type="text" onChange={this.handleInputChange} name="Sent_groub" className="form-control" defaultValue={this.state.Sent_groub} placeholder="Divisi / Bagian / Entitas Luar" required />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Kategori</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-bars"></i>
                          </div>
                          <select name="Sent_category" className="form-control" required onChange={this.handleInputChange}>
                            <option value={this.state.Sent_category} >{this.state.Sent_category}</option>
                            <option value="">--------------------</option>
                            <option value="Rahasia">Rahasia</option>
                            <option value="Penting">Penting</option>
                            <option value="Biasa">Biasa</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Setting Tanggal</label>
                          <div className="input-group date">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input type="date" name="Sent_date" onChange={this.handleInputChange} className="form-control pull-right" defaultValue={this.state.Sent_date} required />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Perihal Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-bookmark"></i>
                          </div>
                          <input type="text" name="Sent_about" onChange={this.handleInputChange} className="form-control" placeholder="Perihal Surat" defaultValue={this.state.Sent_about} required />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Nomer Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-tag"></i>
                          </div>
                          <input readOnly type="text" onChange={this.handleInputChange} name="Sent_code" className="form-control" defaultValue={this.state.Sent_code} placeholder="Generte Number" required />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Isi Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-envelope"></i>
                          </div>
                          <textarea id="editor1" onChange={this.handleInputChange} name="Sent_content" rows="10" cols="80" required defaultValue={this.state.Sent_content}>
                          </textarea>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary">Simpan</button>
                    </form>
                  </div>
                  {/* /.box-body */}
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
