import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../axios/Api'
import config from '../axios/koneksi'


function V_DistribusiSuratKeluar({ v_distribusi }) {

  async function handleClick() {
    
    await API.put('surat/keluar/distribusi/dl?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.sent_id)
    //window.location.reload();
    // console.log('Tautan diklik.');
  }

  function pop(){
    var pop = window.confirm("Apakah anda yakin untuk menghapus data surat ini?")
    if (pop == true){
      handleClick()
    }else{
      //window.location.reload();
    }
  }

  //async function deleteKeluar() {
  //  await API.put('surat/keluar/distribusi/dl?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.sent_id)
  //}
  

  const script = document.createElement("script");

  script.src = 'js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {
  const status = (val) => {
    if (val === 1) return 'Dikirim'
    else return 'Disimpan'
  }
  
  const stype = (val) => {
    if (val === "S.Kep") return 'Surat Keputusan'
    else if (val === "S.Ket") return 'Surat Keterangan'
    else if (val === "S.Ed") return 'Surat Edaran'
    else if (val === "ST") return 'Surat Tugas'
    else if (val === "SK") return 'Surat Kuasa'
    else if (val === "SP") return 'Surat Peringatan'
    else if (val === "SIK") return 'Surat Ijin Kegiatan'
    else if (val === "S.Pn") return 'Surat Perjanjian'
    else if (val === "S.Tap") return 'Surat Ketetapan'
    else if (val === "S.Ker") return 'Surat Kerjasama'
    else if (val === "S.Per") return 'Surat Perintah'
    else if (val === "Und") return 'Surat Undangan'
    else if (val === "Peng") return 'Surat Pengumuman'
    else if (val === "L") return 'Surat Lain Lain'
    else return 'Surat Lain Lain'
  }
  const sts = (val) => {
    if (val === "S") return 'Surat Keluar'
    else return 'Emergency'
  }
  const reportview = (val) => {
    //if () return ''
  }

  const rep = (val) => {
    if (val === "S.Kep") return ''
    else if (val === "S.Ket") return ''
    else if (val === "S.Ed") return 'edaran'
    else if (val === "ST") return ''
    else if (val === "SK") return 'kuasa'
    else if (val === "SP") return ''
    else if (val === "SIK") return ''
    else if (val === "S.Pn") return ''
    else if (val === "S.Tap") return ''
    else if (val === "S.Ker") return 'kerjasama'
    else if (val === "S.Per") return ''
    else if (val === "Und") return ''
    else if (val === "Peng") return ''
    else if (val === "L") return ''
    else return 'Surat Lain Lain'
  }
  

  return (

    <tr>
      <td align="center"><input type="checkbox" name="check_list[]" value={v_distribusi.sent_id}></input></td>
      <td>{v_distribusi.No}</td>
      <td>{v_distribusi.sent_code}</td>
      <td>{stype(v_distribusi.sent_type)}</td>
      <td>{v_distribusi.sent_about}</td>
      <td>{v_distribusi.sent_date}</td>
      <td>{v_distribusi.sent_to}</td>
      <td>{v_distribusi.sent_category}</td>
      <td>{sts(v_distribusi.Sent_sts)}</td>
      <td><span className="label label-warning">{status(v_distribusi.sent_status)}</span></td>
      <td align="center">
        <Link target="_blank" to={`/${rep(v_distribusi.sent_type)}/${v_distribusi.sent_id}`} className="btn btn-success">Preview</Link> &nbsp;
        <Link to={"/suratedit/" + v_distribusi.sent_id} className="btn btn-primary">Edit</Link> &nbsp;
        <button onClick={pop} className="btn btn-danger">Hapus</button>
      </td>
    </tr>


  )
}

export default V_DistribusiSuratKeluar