import React, { Component } from 'react'
import V_DistribusiSurat from './V_DistribusiSurat'
import API from '../axios/Api'
import key_surat from '../axios/key'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API_ASTOR from '../axios/Api_astor'
import key_astor from '../axios/key_astor'
import conf_surat from '../axios/koneksi'

export default class DistribusiSurat extends Component {

  state = {
    distribusi: [],
    users: [],
    idusers: '',
    unusers: '',
    aksusers: ''
  }

  getuser(id) {
    const user = this.state.users.find(usr => usr.id_rektor === id)
    // .then(qwe => )
    if (user) {
      return user.rektor_name
    }
    return '-'
    // console.log(user)

  }

  rekidtujuan(id) {
    const rekid = this.state.users.find(rekidnya => rekidnya.id === id)
    // .then(qwe => )
    if (rekid) {
      return rekid.rektor_id
    }
    return '-'
    // console.log(user)

  }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })
    await API_ASTOR.get('surat/cari/tujuan/struktural' + key_astor.baseURL)
      .then(
        Response => this.setState({
          users: Response.data
        })
      )
    // console.log(this.state.users)
    // const idpengguna = sessionStorage.getItem("sid")
    // const akpengguna = sessionStorage.getItem("ak")
    await API.get("surat/masuk/distribusi/view" + key_surat.baseURL + "&id=" + this.state.idusers + "&ak=" + this.state.aksusers)
      .then(Response => this.setState({
        distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))
    this.timer = setInterval(() => this.cek(), 1000);
    // console.log(this.state.distribusi)



    // let ico = []
    // const logsurat = this.state.distribusi
    // const eserdata = await API_ASTOR.get('/surat/user/react/select/json' + key_astor.baseURL)
    // for (const lognya of logsurat) {
    //   const ketemuuser = eserdata.data.find(val => val.id === lognya.value)
    //   ico.push({ ...lognya, ...ketemuuser })
    // }
    // const iiccoo = ico
    // this.setState({
    //   tujuan: iiccoo
    // })

    // console.log(this.state.users)

  }

  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')
    }
  }

  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_DistribusiSurat v_distribusi={distribusi} key={distribusi.Recieve_code} />
      )
    })

    return (
      <div className="wrapper">
        <div className="">
          <div className="wrapper">
            <Menu />
            <Header />
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <section className="content-header">
                <h1>Distribusi Surat</h1>
              </section>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-success box-solid">
                      <div className="box-header">
                        <strong>Daftar Surat Mauk</strong>
                        <div className="box-tools pull-right">
                        </div>
                      </div>
                      {/* /.box-header */}
                      <div className="box-body">
                        <div className="table-responsive">

                          <table id="example1" className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                {/* <th>Pilih</th> */}
                                <th>No</th>
                                <th>Kode Surat</th>
                                <th>Pengirim</th>
                                <th>Perihal</th>
                                <th>Tgl Terima</th>
                                <th>Tujuan</th>
                                <th>Kategori</th>
                                <th>Status</th>
                                <th>Download PDF</th>
                                <th>Opsi</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {renderData} */}

                              {this.state.distribusi.map(v_distribusi => {
                                const script = document.createElement("script");

                                script.src = 'js/content.js';
                                script.async = true;

                                document.body.appendChild(script);


                                // componentDidMount (){
                                //     const script = document.createElement("script");

                                //     script.src ='js/content.js';
                                //     script.async = true;

                                //     document.body.appendChild(script);
                                // }

                                // render() {

                                const cekKondisi = (val) => {
                                  if (val == 1) return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-open" viewBox="0 0 16 16">
                                    <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.818l5.724 3.465L8 8.917l1.276.766L15 6.218V5.4a1 1 0 0 0-.53-.882l-6-3.2zM15 7.388l-4.754 2.877L15 13.117v-5.73zm-.035 6.874L8 10.083l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738zM1 13.117l4.754-2.852L1 7.387v5.73zM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2z"/>
                                  </svg>
                                  else return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                                  </svg>
                                }

                                return (

                                  <tr key={v_distribusi.No}>
                                    <td>{v_distribusi.No}</td>
                                    <td>{v_distribusi.Recieve_code}</td>
                                    <td>{v_distribusi.Recieve_sender}</td>
                                    <td>{v_distribusi.Recieve_about}</td>
                                    <td>{v_distribusi.Recieve_date}</td>
                                    <td>{this.getuser(v_distribusi.Recieve_to)}</td>
                                    <td>{v_distribusi.Recieve_category}</td>
                                    <td align="center">
                                      {/* <span className="label label-primary fontku" style={{ fontSize: '12px' }}> */}
                                      {cekKondisi(v_distribusi.Recieve_distribution_status)}
                                      {/* {v_distribusi.Recieve_distribution_status} */}
                                      {/* </span> */}
                                      
                                    </td>
                                    <td>
                                      {/* <button className="btn btn-success" onClick={() => window.open(`${conf_surat.baseURL}${v_distribusi.Recieve_file}`, '_blank')}>Download</button> */}
                                      <Link className="btn btn-success" to={`pdfterimasurat/` + v_distribusi.Recieve_code} target="blank">Download</Link>
                                    </td>
                                    <td>
                                      <a href={'/etsurat/' + v_distribusi.Recieve_id} className="btn btn-warning">Edit</a>
                                    </td>
                                  </tr>

                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        {/* <button type="button" className="btn btn-primary">Kirim</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}