import React, { Component } from 'react'
import Printer, { print } from 'react-pdf-print'
import API from '../axios/Api'




const ids = ['1']
function V_Kuasa({ v_pdf }) {

    return (
        <div></div>
    )
}
export default V_Kuasa
