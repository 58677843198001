import React, { Component } from 'react'
import V_Notif_ReqSurat from './V_Notif_ReqSurat'
import API from '../axios/Api'


export default class Notif_ReqSurat extends Component {



  state = {
    notif_reqsurat: []
  }

  async componentDidMount() {
    //await API.get("read_invite.php")
    //    .then(Response => this.setState({
    //        notif_reqsurat: Response.data
    //    }))

    //console.log(this.state)
  }

  render() {

    const renderData = this.state.notif_reqsurat.map(notif_reqsurat => {
      return (
        <V_Notif_ReqSurat v_notif_reqsurat={notif_reqsurat} key={notif_reqsurat.Invite_code} />
      )
    })

    return (
      <div className="">
        {/* <div className="">
          <div className="wrapper">

            <div className="content-wrapper"> */}
              {/* Content Header (Page header) */}

              {/* Main content */}
              <section className="content">
                <h2>Welcome !</h2>
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">

                    <div className="col-md-14 col-sm-14 margin-bottom-30">
                      <div className="box box-success box-solid">
                        <div className="box-header">
                          <strong>Notifikasi Request Surat dll</strong>
                          <div className="box-tools pull-right">
                          </div>
                        </div>
                        {/* /.box-header */}
                        <div className="box-body">
                          <div className="table-responsive">
                            <table id="example1" className="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>Nomer Surat / Undangan</th>
                                  <th>Pembuat</th>
                                  <th>Perihal</th>
                                  <th>Tgl Dibuat</th>
                                  <th>Tujuan</th>
                                  <th>Pilihan</th>
                                </tr>
                              </thead>
                              <tbody>
                                {renderData}
                              </tbody>
                            </table>
                          </div>
                          {/* /.box-body */}
                        </div>
                        {/* /.box */}
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </section>
              {/* /.content */}
            </div>

      //     </div>
      //   </div>
      // </div>
    )
  }
}

