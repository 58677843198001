import React, { Component } from 'react'

export default class Login extends Component {
  render() {

    return (
      <div>
        <div align="center" style={{paddingTop:'4%'}}>
          <h1>Anda Tidak Bisa Masuk Apliksi SIPA ?</h1>
          <h2>Hubungi Telpon : 302 / WA : 085852120148</h2>

        </div>
      </div>
    )
  }
}
