import React, { Component } from 'react'
import API from '../axios/Api'
import V_Edaran from './V_Edaran'
import Printer, { print } from 'react-pdf-print'
import ReactHtmlParser from 'react-html-parser';


const ids = ['1']

export default class Edaran extends Component {

    constructor() {
        super()
        this.Sent_groub = ''
        this.state = {
            pdf: [],
            data: [],
            voption: [],
            voptionb: [],
            sent_id: '',
            Sent_type: '',
            Sent_creator: '',
            Sentcreator: '',
            Sent_groub: '',
            Sent_to: '',
            Sent_category: '',
            Sent_date: '',
            Sent_about: '',
            Sent_code: '',
            Sent_content: '',
            Sent_dcreator: ''
        }
    }

    async componentDidMount() {

        const sentid = this.props.match.params.sent_id
        const res = await API.get('surat/keluar/report/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idr=' + sentid)
        this.setState({
            sent_id: res.data[0].sent_id,
            Sent_code: res.data[0].sent_code,
            Sent_type: res.data[0].sent_type,
            Sent_creator: res.data[0].sent_creator,
            Sent_dcreator: res.data[0].sent_dcreator,
            Sent_groub: res.data[0].sent_groub,
            Sent_category: res.data[0].sent_category,
            Sent_date: res.data[0].sentdate,
            Sent_about: res.data[0].sent_about,
            Sent_content: res.data[0].sent_content
        })

    }




    render() {
        const rep = (val) => {
            if (val === "R.0") return 'Rektor'
            else if (val === "R.1") return 'Wakil Rektor 1'
            else if (val === "R.2") return 'Wakil Rektor 2'
            else if (val === "R.3") return 'Wakil Rektor 3'
            else return 'Wakil Rektor 4'
          }

        return (

            <div>
                <button id="button" onClick={() => print(ids)} className="btn btn-primary">Download</button>

                <center>
                    <Printer>
                        <div id={ids[0]} style={{ border: '2px solid black', width: '210mm', height: '297mm', marginTop: '30px', marginRight: '20px', marginBottom: '30px', marginLeft: '30px', padding: '60px' }}>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <center><font size="5"><u><b>SURAT EDARAN</b></u></font></center>
                            <center><font size="2">No: {this.state.Sent_code}</font> </center>
                            <br />
                            <center><font color="black">Tentang :</font></center>
                            <br />
                            <p align='center' style={{ textIndent: '0rem' }}>
                                <b><font size="3" color="black">{this.state.Sent_about}</font></b>
                            </p>
                            <br />


                            <p align='justify' style={{ textIndent: '0rem' }}>
                                <font size="2" color="black">
                                    {ReactHtmlParser(this.state.Sent_content)}
                                </font>
                            </p>
                            <br />
                            <p align='justify'>
                                <font size="2" color="black">

                                    {this.state.Sent_footer_content}
                                </font>

                            </p>
                            <br />

                            <table width="100%" border="0">


                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width='' ></td>
                                    <td width='' align=''><font size="2" color="black">Ditetapkan di: Malang<br /></font></td>
                                </tr>
                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width=''></td>
                                    <td width='' align=''><font size="2" color="black">Pada tanggal : {this.state.Sent_date}</font></td>
                                </tr>
                                <br />

                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width=''></td>
                                    <td width='' align=''><font size="2" color="black">{rep(this.state.Sent_creator)}</font></td>
                                </tr>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width='0%'></td>
                                    <td width='' align=''><font size="2" color="black">{this.state.Sent_dcreator}</font></td>
                                </tr>
                            </table>
                        </div>
                    </Printer>
                </center>
            </div>

        )
    }
}
