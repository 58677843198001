import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom';
import { browserHistory } from 'history'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'
import koneksi from '../axios/koneksi';


export default class Header extends Component {

  constructor() {
    super()
    this.state = {
      uns: '',
      swt: [],
      userswt: [],
      RSasia: '',
      iduserswt: '',
      idusers: '',
      unusers: '',
      aksusers: '',
      // hasError: false
    }
  }

  // static getDerivedStateFromError(error) {
  //   // Perbarui state agar proses render berikutnya akan menampilkan antarmuka darurat.
  //   return { hasError: true };
  // }

  // componentDidCatch(error, errorInfo) {
  //   // Anda juga bisa mencatat kesalahan ke layanan pencatat kesalahan
  //   logErrorToMyService(error, errorInfo);
  // }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    this.setState({
      uns: sessionStorage.getItem("un", JSON.stringify(this.state.uns))
    })
    const iduss = sessionStorage.getItem('sid')
    await API_ASTOR.get("/surat/pindah/akses/usr" + key_astor.baseURL + '&ids=' + this.state.idusers + '&apps=1')
      .then(RS => this.setState({
        swt: RS.data
      }))
    // console.log("swt => ", this.state.swt.length) #4CAF50

    // await API_ASTOR.get("/surat/pindah/akses/usr" + key_astor.baseURL + '&ids=' + iduss + '&apps=1')
    //   .then(RSa => this.setState({
    //     RSasia: RSa.data
    //   }))

    const aks = sessionStorage.getItem('ak')
    await API_ASTOR.get("/surat/swict/akun/user" + key_astor.baseURL + '&aks=' + this.state.aksusers)
      .then(RS => this.setState({
        userswt: RS.data[0].level_name,
        iduserswt: RS.data[0].level_id,
      }))

    // this.timer = setInterval(() => this.cek(), 1000);

    // console.log(this.state.coba)
  }



  // cek(){

  //   if (this.state.uns = sessionStorage.getItem("un") == null) {
  //     // console.log("admin login")
  //     this.props.history.push('/')
  //     // window.location.reload('/');;

  //   } else {
  //     // console.log("user login")
  //     // this.props.history.push('/')
  //     // window.location.reload();
  //     // window.location.reload();;
  //   }
  //   console.log(this.state.uns)

  //   //console.log(sessionStorage.getItem("un",JSON.stringify(this.state.uns)))
  // }
  // componentDidCatch(error, info) {
  //   // Display fallback UI
  //   // this.setState({ hasError: true });
  //   // log the error to an error reporting service
  //   // logErrorToMyService(error, info);
  //   alert('error bro')
  // }

  render() {

    const swts = (val) => {
      if (val > 1) return <div className="navbar-custom-menu">
        <ul className="nav navbar-nav">
          <li className="dropdown messages-menu">
            <a href="#" className="dropdown-toggle fontku" data-toggle="dropdown" onClick={bukakModal}>
              <i className="fa fa-desktop" /> &nbsp;&nbsp;
          </a>
          </li>
        </ul>
      </div>
      else return ''
    }

    function bukakModal() {
      document.getElementById("modallu").classList.remove("modalku");
      document.getElementById("modallu").classList.add("muncul");
    }

    function tutupenModalmu() {
      document.getElementById("modallu").classList.remove("muncul");
      document.getElementById("modallu").classList.add("modalku");
    }

    return (
      <div className="wrapper">
        <header className="main-header">
          <a href="#" className="logo">
            <span className="logo-mini fontku"><b>S</b>IPA</span>
            <span className="logo-lg font1"><b>{this.state.userswt}</b></span>
          </a>
          <nav className="navbar navbar-static-top">
            <a href="fake_url" className="sidebar-toggle" data-toggle="push-menu" role="button">
              <span className="sr-only">Toggle navigation</span>
            </a>

            {swts(this.state.swt.length)}
            {/* <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown messages-menu">
                  <a href="#" className="dropdown-toggle fontku" data-toggle="dropdown" onClick={bukakModal}>
                    <i className="fa fa-desktop" /> &nbsp;&nbsp;
                  </a>
                </li>
              </ul>
            </div> */}

            <div className="modalku" id="modallu">

              <div className="modal-contentlu">
                <span className="fontku closeku" onClick={tutupenModalmu} style={{ color: 'white' }}><i className="fa fa-close"></i> tutup</span>
                <br />
                <br />
                <div className="row">
                  {
                    this.state.swt.map(rswt => {
                      async function handleClick() {
                        sessionStorage.removeItem('ak')
                        sessionStorage.setItem('ak', rswt.idlv2)

                        // await API.put('')
                        window.location.replace('/home');
                        // console.log('Tautan diklik.');

                      }
                      const cekwarna = (val) => {
                        if (this.state.iduserswt == val) return <div className="small-box bg-aqua">
                          <div className="inner" style={{ backgroundColor: 'rgb(22 112 134)' }}>
                            <p></p>
                            {/* <p className="fontfuc" style={{ color: 'white' }}>Anda Masuk Sebagai</p> */}
                            <h3 className="fontcu">{rswt.lnlv}</h3>
                            <p className="fontfuc" style={{ color: 'white' }}>{rswt.nmgr}</p>
                          </div>
                          <div className="icon">
                            <i className="fa fa-envelope-o"></i>
                          </div>
                          <a href="#" className="small-box-footer fontku" style={{ cursor: 'pointer', backgroundColor: 'rgb(0 0 0 / 50%)' }}>
                            <strong style={{color: '#fff'}}>Anda Masuk Sebagai {rswt.lnlv} </strong>
                            <i className="fa fa-arrow-circle-right"></i>
                          </a>
                        </div>
                        else return <div className="small-box bg-aqua">
                          <div className="inner" >
                            <h3 className="fontcu">{rswt.lnlv} </h3>
                            <p className="fontfuc" style={{ color: 'white' }}>{rswt.nmgr}</p>
                          </div>
                          <div className="icon">
                            <i className="fa fa-envelope-o"></i>
                          </div>
                          <a onClick={handleClick} className="small-box-footer fontku" style={{ cursor: 'pointer' }}>Pindah Akses <i className="fa fa-arrow-circle-right"></i></a>
                        </div>
                      }

                      return (
                        // <>

                        <div className="col-lg-6" key={rswt.nheader}>
                          {cekwarna(rswt.idlv)}
                        </div>

                        // </>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div >

    )
  }
}

