import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'

export default class Surat extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: `....`,
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      kakeanpolah: ''
    }
  }


  handlegoyang = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }

  handleJenisSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ ttdsurat: e.target.value })
  }

  masukjos = async (event) => {
    event.preventDefault()
    API.post('imassage/user/send/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE', this.state)
      .then(sembarang => console.log(sembarang))

  }

  async componentDidMount() {
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);

    await API.get("surat/keluar/nomor/2/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        backcode: Response.data[0].backoutcode
      }))

    await API.get("surat/keluar/nomor/1/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        firscode: Response.data[0].codepertama
      }))
    await API.get("date/now/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        kakeanpolah: Response.data[0].dt
      }))

    //console.log(this.state.kakeanpolah)
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }


  render() {


    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Permintaan Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Permintaan Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form action={`${config.baseURL}imassage/user/send/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`} method="post" >
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jenis Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select className="Sent_type" name="Sent_type" className="form-control" onChange={this.handleJenisSurat} required>
                              <option value="">--Jenis Surat--</option>
                              <option value="S.Kep">Surat Keputusan</option>
                              <option value="S.Ket">Surat Keterangan</option>
                              <option value="S.Ed">Surat Edaran</option>
                              <option value="ST">Surat Tugas</option>
                              <option value="SK">Surat Kuasa</option>
                              <option value="SP">Surat Peringatan</option>
                              <option value="SIK">Surat Ijin Kegiatan</option>
                              <option value="S.Pn">Surat Perjanjian</option>
                              <option value="S.Tap">Surat Ketetapan</option>
                              <option value="S.Ker">Surat Kerjasama</option>
                              <option value="S.Per">Surat Perintah</option>
                              <option value="Peng">Pengumuman</option>
                              <option value="L">Surat Lain-lain</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pembuat Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="Sent_creator" className="form-control" onChange={this.handleTtdSurat} required>
                              <option value="">--Pembuat Surat--</option>
                              <option value="R.0">Rektor</option>
                              <option value="R.1">Wakil Rektor 1</option>
                              <option value="R.2">Wakil Rektor 2</option>
                              <option value="R.3">Wakil Rektor 3</option>
                              <option value="R.4">Wakil Rektor 4</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Tujuan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-institution"></i>
                            </div>
                            <input type="text" name="Sent_to" className="form-control" onChange={this.handleInputChange} placeholder="Divisi / Bagian / Entitas Luar" required></input>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kategori</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select name="Sent_category" className="form-control" onChange={this.handleInputChange} required>
                              <option value="">--Kategori--</option>
                              <option value="Rahasia">Rahasia</option>
                              <option value="Penting">Penting</option>
                              <option value="Biasa">Biasa</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Setting Tanggal</label>
                          <div className="input-group date">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input type="date" name="Sent_date" defaultValue={this.state.kakeanpolah} className="form-control pull-right" onChange={this.handleInputChange} required></input>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Perihal Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" name="Sent_about" className="form-control" placeholder="Perihal Surat" onChange={this.handleInputChange} required></input>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Nomer Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-tag"></i>
                            </div>
                            {/*renderDatafirs*/}
                            <input type="text" value={`${this.state.firscode}/${this.state.jenissurat}/${this.state.ttdsurat}/${this.state.itb}/${this.state.backcode}`} readOnly name="Sent_code" className="form-control" placeholder="Generte Number" required></input>
                            {/*renderDataback*/}
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Isi Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <textarea id="editor2" name="Sent_content" onChange={this.handleInputChange} rows="10" cols="80" required></textarea>
                          </div>
                        </div>
                        <button type="Submit" className="btn btn-primary">Simpan</button>
                      </div>
                    </form>

                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
