import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import { Link } from 'react-router-dom'


function v_codelist({ v_distribusi }) {


  const script = document.createElement("script");

  script.src = 'js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {

    const cekKondisi = (val) => {
      if (val === 'R.0') return 'Rektor'
      else if (val === 'R.1') return 'Wakil Rektor 1'
      else if (val === 'R.2') return 'Wakil Rektor 2'
      else if (val === 'R.3') return 'Wakil Rektor 3'
      else if (val === 'R.4') return 'Wakil Rektor 4'
      else return ''
    }
  console.log(config.baseURL,v_distribusi.Recieve_file)
  
  return (

    <tr>

      <td>{v_distribusi.No}</td>
      <td>{v_distribusi.rektor_id}</td>
      <td>{v_distribusi.rektor_name}</td>
      <td>{cekKondisi(v_distribusi.rektor_id)}</td>

    </tr>


  )

}

export default v_codelist
