import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../axios/Api'
import config from '../axios/koneksi'


function v_dsuratkeluar({ v_distribusi }) {

  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {

    await API.put('surat/keluar/dellete/newnormal/ex?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.id_suratkeluar)
    window.location.reload();
    // console.log('Tautan diklik.');
  }

  function pop() {
    var pop = window.confirm("Apakah anda yakin untuk menghapus data surat ini?")
    if (pop == true) {
      handleClick()
    } else {
      //window.location.reload();
    }
  }

  async function handleAktif() {

    await API.put('surat/keluar/aktifkan/surat/newnormal/act?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.id_suratkeluar)
    window.location.reload();
    // console.log('Tautan diklik.');
  }

  function aktif() {
    var pop = window.confirm("Apakah anda yakin untuk Mengembalikan data surat ini?")
    if (pop == true) {
      handleAktif()
    } else {
      //window.location.reload();
    }
  }

  async function updatetrue() {
    await API.put('update/theme/surat/terbaru/update?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.id_suratkeluar)
    window.location.reload();
  }

  function updatetheme() {
    var pop = window.confirm("Apakah anda yakin untuk Mengembalikan data surat ini?")
    if (pop == true) {
      updatetrue()
    } else {
      //window.location.reload();
    }
  }

  const status = (val) => {
    if (val === 1) return 'Dikirim'
    else return 'Disimpan'
  }
  const stype = (val) => {
    if (val === "S.Kep") return 'Surat Keputusan'
    else if (val === "S.Ket") return 'Surat Keterangan'
    else if (val === "S.Ed") return 'Surat Edaran'
    else if (val === "ST") return 'Surat Tugas'
    else if (val === "SK") return 'Surat Kuasa'
    else if (val === "SP") return 'Surat Peringatan'
    else if (val === "SIK") return 'Surat Ijin Kegiatan'
    else if (val === "S.Pn") return 'Surat Perjanjian'
    else if (val === "S.Tap") return 'Surat Ketetapan'
    else if (val === "S.Ker") return 'Surat Kerjasama'
    else if (val === "S.Per") return 'Surat Perintah'
    else if (val === "Und") return 'Surat Undangan'
    else if (val === "Peng") return 'Surat Pengumuman'
    else if (val === "L") return 'Surat Lain Lain'
    else return 'Surat Lain Lain'
  }
  const sts = (val) => {
    if (val === "S") return 'Surat Keluar'
    else return 'Emergency'
  }
  const reportview = (val) => {
    //if () return ''
  }

  const rep = (val) => {
    if (val === "S.Kep") return ''
    else if (val === "S.Ket") return ''
    else if (val === "S.Ed") return 'edaran'
    else if (val === "ST") return ''
    else if (val === "SK") return 'kuasa'
    else if (val === "SP") return ''
    else if (val === "SIK") return ''
    else if (val === "S.Pn") return ''
    else if (val === "S.Tap") return ''
    else if (val === "S.Ker") return 'kerjasama'
    else if (val === "S.Per") return ''
    else if (val === "Und") return ''
    else if (val === "Peng") return ''
    else if (val === "L") return ''
    else return 'Surat Lain Lain'

  }

  const pdfopen = () => {
    window.open(`${config.baseURL}` + v_distribusi.file_suratkeluar, '_blank');
  }

  const notulen = (val) => {
    if (val == "-") return <td align="center">-</td>
    else if (val == "0") return <td align="center"><a href={'/isinotulen/' + v_distribusi.id_suratkeluar} style={{ width: '110px', marginTop: '2px', marginBottom: '2px', backgroundColor: '#6ba3d1', borderColor: '#6ba3d1' }} className="btn btn-info">Isi Notulen</a></td>
    // else if (val === "1") return <Link to="#" className="btn btn-primary">Edit Notulen</Link>
    else return <td align="center">
      <a href={'/editnotulen/' + v_distribusi.id_suratkeluar} className="btn btn-warning" style={{ width: '110px', marginTop: '2px', marginBottom: '2px' }}>Edit Notulen</a><br />
      <a onClick={() => pdfopen()} style={{ width: '110px', marginTop: '2px', marginBottom: '2px', marginLeft: '0px' }} className="btn btn-primary">Absensi</a>
      {/* <Link to={'/notulenview/' + v_distribusi.id_suratkeluar} className="btn btn-success">Lihat Dokumen</Link> */}
      {/* <a href={config.baseURL + v_distribusi.file_suratkeluar} className="btn btn-success">Lihat Dokumen</a> */}
      {/* <Link to={"./" + config.baseURL + v_distribusi.id_suratkeluar} className="btn btn-success">Lihat Dokumen</Link> */}
    </td>

  }

  const valstatus = (val) => {
    if (val === "0") return <a style={{ fontSize: '12px' }} className="label label-primary fontku" > Surat </a>
    else if (val === "1") return <a style={{ fontSize: '12px' }} className="label label-warning fontku"> Emergensi </a>
    else return ''
  }

  const preview = (val) => {
    if (val == "-") return '-'
    else if (val == "0") return ""
    else if (val == "1") return <a href={`${config.baseURL}` + v_distribusi.file_suratkeluar} className="btn btn-success" style={{ marginLeft: "10px" }}>Download PDF</a>
    else return ''
  }

  const hhdn = (val) => {
    if (val == "1") return <td align="center">
      <a href={"/esurat/" + v_distribusi.id_suratkeluar} style={{ width: '70px', marginTop: '2px', marginBottom: '2px', marginLeft: '3px' }} className="btn btn-info">Edit</a>
      <Link target="_blank" to={`/laporan/${v_distribusi.id_suratkeluar}`} style={{ width: '70px', marginTop: '2px', marginBottom: '3px', marginLeft: '2px' }} className="btn btn-success">Preview</Link>
      <button onClick={pop} className="btn btn-danger" style={{ width: '70px', marginTop: '2px', marginBottom: '2px', marginLeft: '3px' }}>Hapus</button>
    </td>
    else if (val == "0") return <td align="center">
      <button onClick={aktif} className="btn btn-primary" style={{ width: '110px', marginTop: '2px', marginBottom: '2px' }}>Aktifkan</button>
      {/* <a href={"/" + v_distribusi.id_suratkeluar} className="btn btn-primary">Aktifkan</a> */}
    </td>
    else return ''
  }

  const ttheme = (val) => {
    if (val == v_distribusi.vtheme_jenis) return <span style={{ color: 'green' }}>Terbaru</span>
    else return <button onClick={updatetheme} id="btnckanan" style={{ color: 'red' }}>Update Theme</button>
  }

  const ntujuan = (val) => {
    if (val == 0) return <td>{v_distribusi.tujuan_suratkeluar}</td>
    else return <td><div>
      {v_distribusi.tujuan_suratkeluar.map(ttujuan => {
        return (
          <>
          <span className="label label-primary fontku">{ttujuan.id}</span>&nbsp;
          </>
        )
      })}
      </div>
    </td>
  }

  return (

    <tr>
      <td>{v_distribusi.No}</td>
      <td>{v_distribusi.no_suratkeluar}</td>
      <td>{v_distribusi.id_jenis}</td>
      <td>{v_distribusi.hal_suratkeluar}</td>
      <td>{v_distribusi.tgl_suratkeluar}</td>
      {ntujuan(v_distribusi.nintujuan)}
      {/* {v_distribusi.tujuan_suratkeluar} */}
      <td>{v_distribusi.ketegori_surat}</td>

      {notulen(v_distribusi.file_suratkeluar)}
      <td> {valstatus(v_distribusi.status)} </td>
      <td align="center">{ttheme(v_distribusi.vtheme_suratkeluar)}</td>

      {hhdn(v_distribusi.hdn)}
    </tr>
  )
}

export default v_dsuratkeluar