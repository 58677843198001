import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../axios/Api'
import config from '../axios/koneksi'


function v_themeemergensi({ v_distribusi }) {

  async function handleClick() {

    await API.put('surat/keluar/distribusi/dl?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.sent_id)
    //window.location.reload();
    console.log('Tautan diklik.');
  }

  function pop() {
    var pop = window.confirm("Apakah anda yakin untuk menghapus data surat ini?")
    if (pop == true) {
      handleClick()
    } else {
      //window.location.reload();
    }
  }



  const script = document.createElement("script");

  script.src = 'js/content.js';
  script.async = true;

  document.body.appendChild(script);


  const sts = (val) => {
    if (val === "S") return 'Surat Keluar'
    else return 'Emergency'
  }


  return (
    <div>
      {/* <a href={"/bsurat/" + v_distribusi.id_theme} className="btn btn-primary">Buat</a> */}

      <a href={"/femergensi/" + v_distribusi.id_jenis + "/" + v_distribusi.tgl + "/" + v_distribusi.bln + "/" + v_distribusi.thn}>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="info-box">
            <div className="info-box-icon bg-aqua">
              <i className="glyphicon glyphicon-list-alt"></i>
            </div>
            <div className="info-box-content">
              <span className="info-box-number">{v_distribusi.ket_jenis}</span>
              <span>{/*v_distribusi.ket_jenis*/} ({v_distribusi.kode_jenis})</span>
            </div>
          </div>
        </div>
      </a>
    </div>


  )
}

export default v_themeemergensi