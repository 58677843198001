import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import config from '../axios/koneksi'
import API from '../axios/Api'
import con_astor from '../axios/koneksi_astor'
import API_ASTOR from '../axios/Api_astor'
// import API_Astor from '../axios/Api_astor'
import key_astor from '../axios/key_astor'

export default class Menu extends Component {
  constructor() {
    super()
    this.state = {
      distribusi: [],
      users: [],
      userz: [],
      namauser: '',
      uns: '',
      swt: [],
      userswt: [],
      coba: [],
      hijau: '',
      nmuser: '',
      idusers: '',
      aksusers: '',
      unusers: ''
    }
  }



  usernames = async () => {
    await API_ASTOR.get('surat/tujuan/multi/srh' + key_astor.baseURL)
      .then(
        Response =>
          // console.log(Response) 
          this.setState({
            users: Response.data.filter(m => m.user_id === this.state.unusers),
            userz: Response.data
          })
      )
    this.setState({
      nmuser: this.state.users[0].namauser
    })
    // console.log(this.state.idusers)
  }

  async read() {
    this.setState({
      uns: sessionStorage.getItem("un", JSON.stringify(this.state.uns))
    })
    const iduss = sessionStorage.getItem('sid')
    const aks = sessionStorage.getItem('ak')

    await API_ASTOR.get("/surat/pindah/akses/usr" + key_astor.baseURL + '&ids=' + this.state.idusers + '&apps=1')
      .then(RS =>
        // console.log(RS)
        // console.log(RS.data.filter(x => x.idlv === this.state.aksusers), "===>", this.state.aksusers) 
        this.setState({
          swt: RS.data.filter(x => x.idlv === this.state.aksusers)
        })
      )

    this.setState({
      hijau: this.state.swt[0].nmgr
    })
    // console.log(this.state.swt, "=>")
  }

  async distribusi() {
    await API_ASTOR.get('menu/user/surat/ck' + key_astor.baseURL + '&id=' + this.state.idusers + '&ak=' + this.state.aksusers)
      .then(
        Response => this.setState({
          distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
        })
      ).catch(function (error) {
        this.props.history.push('./loadingout');
        //  console.log(error);
      });
  }

  // async componentDidMount() {
  //   await API_Astor.get('menu/user/surat/ck' + key_astor.baseURL + '&id=' + sessionStorage.getItem("sid") + '&ak=' + sessionStorage.getItem('ak'))
  //     .then(
  //       Response => this.setState({
  //         distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
  //       })
  //     )

  // }

  async componentDidMount() {
    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    } else if (sessionStorage.getItem("un") == undefined) {
      this.props.history.push('/loadingout')

    } else {
      this.distribusi();
      this.usernames();
      this.read();
    }
    // console.log(this.state.aksusers)
  }

  cek() {
    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
    if (sessionStorage.getItem("un") == undefined) {
      this.props.history.push('/loadingout')

    }
  }

  render() {
    return (
      <div>
        <aside className="main-sidebar">
          <section className="sidebar">
            {/* <div className="customProfil user-panel"> */}
            <div className="user-panel" style={{ whiteSpace: 'break-spaces', marginBottom: '10px', height: '100px' }}>
              {/* <div className="row info"> */}
              {/* <div className="info"> */}
              {/* <div className="col-md-3"> */}
              <div className="pull-left image">
                {/* <img className="gambarsiapa" src="https://i.ibb.co/t4fQLp5/Institut-Teknologi-dan-Bisnis-Asia-Malang-1080x1023.png" alt="User" /> */}
                <img className="img-circle" src="https://i.ibb.co/t4fQLp5/Institut-Teknologi-dan-Bisnis-Asia-Malang-1080x1023.png" alt="User" />
              </div>
              {/* <div className="col-md-9"> */}
              <div className="pull-left info">
                <ul className="namaluaja">
                  {/* {this.state.users.map((data, idx) => {
                    return (
                      <> */}
                  {/* <h5 style={{ color: 'white', textTransform: 'capitalize' }} className="fontkita"> */}
                  <li className="fa fa-user" id="minimap" style={{ width: '100%', wordBreak: 'break-all', border: '0px solid #fff', marginBottom: '5px', color: 'white' }}>
                    &nbsp; {this.state.nmuser}
                  </li>
                  {/* </h5> */}
                  {/* </>

                    )
                  })} */}

                  <li style={{ color: '#96ebad', fontSize: '13.5px' }}>
                    <span className="fontkita"> {this.state.hijau} </span>
                  </li>
                  {/* {this.state.swt.map((rswt, idz) => {
                    // console.log(rswt.nheader)
                    return (
                      <li style={{ color: '#96ebad', fontSize: '13.5px' }} key={rswt.nheader}>
                        <span className="fontkita" key={idz}> {rswt.nmgr} </span>
                      </li>
                    )
                  }) 
                  }*/}
                </ul>
              </div>
              {/* </div> */}
            </div>
            {/* <div className="user-panel"> */}
            {/* <div className="image">
                <img src="https://i.ibb.co/t4fQLp5/Institut-Teknologi-dan-Bisnis-Asia-Malang-1080x1023.png" className="img-circle" alt="User" />
              </div> */}
            {/* <span style={{ width: '80px', wordBreak: 'normal', border: '1px solid #fff' }}>
                      
                      ayam goreng enak tenan cok
                    </span> */}
            {/* </div> */}
            <ul className="sidebar-menu" data-widget="tree">
              <li className="header fontkita">Navigasi Menu</li>
              <li><Link to="/home"><i className="fa fa-home" /> <span className="fontkita">Home</span></Link></li>

              {/* <li className="active treeview menu-open">
                <Link to="#">
                  <i className="fa fa-download" /> <span>Surat Masuk</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </Link>
                <ul className="treeview-menu">
                  <li><Link to="/terimasurat"><i className="fa fa-envelope" /> Terima Surat</Link></li>
                  <li><Link to="/distribusisurat"><i className="fa fa-bullhorn" /> Distribusi Surat</Link></li>
                </ul>
              </li>

              <li className="active treeview menu-open">
                <Link to="#">
                  <i className="fa fa-upload" /> <span>Surat Keluar </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </Link>
                <ul className="treeview-menu">
                  <li><Link to="/suratk"><i className="fa fa-pencil" /> Buat Surat</Link></li>
                  <li><Link to="/bemergency"><i className="fa fa-warning" /><span> Surat Emergensi</span></Link></li>
                  <li><Link to="/hsuratkeluar/0"><i className="fa fa-upload" /> History Surat Keluar</Link></li>
                </ul>
              </li>
              <li className="active treeview menu-open">
                <Link to="#">
                  <i className="fa fa-cog" /> <span>Setting </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </Link>
                <ul className="treeview-menu">
                  <li><Link to="/djenis"><i className="fa fa-list" /> <span> Daftar Jenis Surat</span></Link></li>
                  <li><Link to="/tjenis"><i className="fa fa-plus" /> <span> Tambah Jenis Surat</span></Link></li>
                </ul>
              </li> */}

              {this.state.distribusi.map(diss => {

                const GR = (val) => {
                  if (val == 'grup') return <Link to="#">
                    <i className="fa fa-envelope-o" /> <span className="fontkami">{diss.gmenu}</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </Link>
                  else return <ul className="treeview-menu">
                    <li><a href={`/` + diss.mslink}><i className="fa fa-angle-right" /><span className="fontkami">{diss.gmenu}</span></a></li>
                  </ul>

                }
                return (
                  // <>
                  <li className="active menu-open" key={diss.n}>
                    {GR(diss.ckstm)}
                    {/* {diss.n} */}
                  </li>
                  // </>
                )
              })}

              <li><Link to="/loadingout"><i className="fa fa-sign-out" /> <span className="fontkami"> Log Out</span></Link></li>
            </ul>
          </section>
        </aside>
      </div>

    )
  }
}
