// import '../modules/paged.polyfill.js';

import React, { Component } from 'react'
import { Previewer } from "pagedjs";
import API from '../axios/Api'
import config from '../axios/koneksi'
import Axios from 'axios';
import V_Edaran from './V_Edaran'
import Printer, { print } from 'react-pdf-print'
import ReactHtmlParser from 'react-html-parser';
import rp from 'react-html-parser';
import CKEditor from 'ckeditor4-react';
import pdfjsLib from "pdfjs-dist/build/pdf";
import { fireEvent } from '@testing-library/react';
import koneksi_astor from '../axios/koneksi_astor';
import key_astor from '../axios/key_astor';
// import GET from '../pages/la';


// const ids = ['1']

export default class laporan extends Component {

    // const

    constructor() {
        super()
        this.state = {
            // vmenutheme: [],
            rwl: '',

            m1: '',
            m2: '',
            m3: '',
            m4: '',
            m5: '',
            m6: '',
            m7: '',
            m8: '',
            m9: '',
            m10: '',
            m11: '',
            m12: '',
            m13: '',
            m14: '',
            m15: '',
            m16: '',
            m17: '',
            m18: '',
            m19: '',
            m20: '',

            thes1: '',
            hdttd: '',
            resdata: '',

            notulen_suratkeluar: '',
            file_suratkeluar: '',
            resprint: '0',
            nttd: '',

            ttd1: '',
            ttd2: '',
            ttd3: '',
            ttd4: '',
            ttd: '',

            nidn1: '',
            nidn2: '',
            nidn3: '',
            nidn4: '',

            jbtn1: '',
            jbtn2: '',
            jbtn3: '',
            jbtn4: '',
            tjn: [],
            users: [],
            wkwk: 0
        }
    }

    componentDidMount() {
        this.datalaporan()
        // this.datapdf()
        this.datajenis()
        // console.log(this.state.users)
    }

    async datapdf() {
        let kode = this.props.match.params.id_suratkeluar
        API.get('/notulen/isi/isinotulen/tampil/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + kode)
            .then(Response => this.setState({
                notulen_suratkeluar: Response.data[0].notulen_suratkeluar,
                file_suratkeluar: Response.data[0].file_suratkeluar,
            }))
        // console.log(this.state.notulen_suratkeluar)
    }

    async datajenis() {
        const sentid = this.props.match.params.id_suratkeluar
        const cuk = await API.get('surat/keluar/preview/jenis/print/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + sentid)
        this.setState({
            id_jenis: cuk.data[0].id_jenis,
            kode_jenis: cuk.data[0].kode_jenis,
            ket_jenis: cuk.data[0].ket_jenis,
        })
        // console.log(cuk)
    }

    getuser(id) {
        const user = this.state.users.find(usr => usr.id === id)
        if (user) {
            return user.namauser
            // console.log(user.namauser)
        } else {
            return '-'
            // console.log("bbbb")
        }
         

        
    }

    async datalaporan() {

        const urlFetch = fetch(koneksi_astor.baseURL + 'surat/tujuan/multi/srh' + key_astor.baseURL)
        urlFetch.then(resa => {
            if (resa.status === 200)
                return resa.json()
        })
            .then(resJson =>
                this.setState({
                    users: resJson
                })

            )

        const sentid = this.props.match.params.id_suratkeluar
        const cuk = await API.get('surat/keluar/preview/print/pdf?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + sentid)
        this.setState({
            resdata: cuk.data[0].resdata,
            filedatas: cuk.data[0].filedatas
        })

        // console.log(this.state.users)

        if (this.state.resdata == 0) {
            window.location.reload()
        } else {
            this.setState({
                vmenutheme: cuk.data[0].datasurat[0].file_suratkeluar,

                m1: cuk.data[0].hasvalue,
                m2: cuk.data[1].hasvalue,
                m3: cuk.data[2].hasvalue,
                m4: cuk.data[3].hasvalue,
                m5: cuk.data[4].hasvalue,
                m6: cuk.data[5].hasvalue,
                m7: cuk.data[6].hasvalue,
                m8: cuk.data[7].hasvalue,
                m9: cuk.data[8].hasvalue,
                m10: cuk.data[9].hasvalue,
                m11: cuk.data[10].hasvalue,
                m12: cuk.data[11].hasvalue,
                m13: cuk.data[12].hasvalue,
                m14: cuk.data[13].hasvalue,
                m15: cuk.data[14].hasvalue,
                m16: cuk.data[15].hasvalue,
                m17: cuk.data[16].hasvalue,
                m18: cuk.data[17].hasvalue,
                m19: cuk.data[18].hasvalue,
                m20: cuk.data[19].hasvalue,

                rwl: cuk.data[0].filedatas,
                hdttd1: cuk.data[4].hdttd,


                id_suratkeluar: cuk.data[0].datasurat[0].id_suratkeluar,
                tjn: cuk.data[0].datasurat[0].tujuanmulti,
                rektor_id: cuk.data[0].datasurat[0].rektor_id,
                header_suratkeluar: cuk.data[0].datasurat[0].header_suratkeluar,
                id_rektor: cuk.data[0].datasurat[0].id_rektor,
                rektor_name: cuk.data[0].datasurat[0].rektor_name,
                ketegori_surat: cuk.data[0].datasurat[0].ketegori_surat,
                tgl_suratkeluar: cuk.data[0].datasurat[0].tgl_suratkeluar,
                blnthn: cuk.data[0].datasurat[0].blnthn,
                no_suratkeluar: cuk.data[0].datasurat[0].no_suratkeluar,
                nosurat: cuk.data[0].datasurat[0].nosurat,
                lam_suratkeluar: cuk.data[0].datasurat[0].lam_suratkeluar,
                hal_suratkeluar: cuk.data[0].datasurat[0].hal_suratkeluar,
                tujuan_suratkeluar: cuk.data[0].datasurat[0].tujuan_suratkeluar,
                alamat_suratkeluar: cuk.data[0].datasurat[0].alamat_suratkeluar,
                isi_suratkeluar: cuk.data[0].datasurat[0].isi_suratkeluar,
                tembuasan_suratkeluar: cuk.data[0].datasurat[0].tembuasan_suratkeluar,
                lampiran_suratkeluar: cuk.data[0].datasurat[0].lampiran_suratkeluar,
                notulen_suratkeluar: cuk.data[0].datasurat[0].notulen_suratkeluar,
                file_suratkeluar: cuk.data[0].datasurat[0].file_suratkeluar,
                nttd: cuk.data[0].datasurat[0].nttd,
                ttd: cuk.data[0].ttd,
                tgl_new: cuk.data[0].datasurat[0].tgl_new,
                bln_new: cuk.data[0].datasurat[0].bln_new,
                thn_new: cuk.data[0].datasurat[0].thn_new,

            })

            // console.log(this.state.header_suratkeluar)

            if (this.state.nttd == 4) {
                this.setState({
                    ttd1: this.state.ttd[0].nama_ttdsurat,
                    ttd2: this.state.ttd[1].nama_ttdsurat,
                    ttd3: this.state.ttd[2].nama_ttdsurat,
                    ttd4: this.state.ttd[3].nama_ttdsurat,
                    nidn1: this.state.ttd[0].nidn_ttdsurat,
                    nidn2: this.state.ttd[1].nidn_ttdsurat,
                    nidn3: this.state.ttd[2].nidn_ttdsurat,
                    nidn4: this.state.ttd[3].nidn_ttdsurat
                })

                if (this.state.ttd[0].nidn_ttdsurat == "") {
                    this.setState({
                        nidn1: ""
                    })

                } else {
                    this.setState({
                        nidn1: 'NIDN : ' + this.state.ttd[0].nidn_ttdsurat
                    })

                }
                if (this.state.ttd[1].nidn_ttdsurat == "") {
                    this.setState({
                        nidn2: ""
                    })

                } else {
                    this.setState({
                        nidn2: 'NIDN : ' + this.state.ttd[1].nidn_ttdsurat
                    })

                }
                if (this.state.ttd[2].nidn_ttdsurat == "") {
                    this.setState({
                        nidn3: ""
                    })

                } else {
                    this.setState({
                        nidn3: 'NIDN : ' + this.state.ttd[2].nidn_ttdsurat
                    })

                }
                if (this.state.ttd[3].nidn_ttdsurat == "") {
                    this.setState({
                        nidn4: ""
                    })

                } else {
                    this.setState({
                        nidn4: 'NIDN : ' + this.state.ttd[3].nidn_ttdsurat
                    })

                }

                this.setState({
                    jbtn1: this.state.ttd[0].ketkode_rektor,
                    jbtn2: this.state.ttd[1].ketkode_rektor,
                    jbtn3: this.state.ttd[2].ketkode_rektor,
                    jbtn4: this.state.ttd[3].ketkode_rektor
                })

            } else if (this.state.nttd == 3) {
                this.setState({
                    ttd1: this.state.ttd[0].nama_ttdsurat,
                    ttd2: this.state.ttd[1].nama_ttdsurat,
                    ttd3: this.state.ttd[2].nama_ttdsurat,
                    ttd4: ''
                })

                if (this.state.ttd[0].nidn_ttdsurat == "") {
                    this.setState({
                        nidn1: ""
                    })

                } else {
                    this.setState({
                        nidn1: 'NIDN : ' + this.state.ttd[0].nidn_ttdsurat
                    })

                }
                if (this.state.ttd[1].nidn_ttdsurat == "") {
                    this.setState({
                        nidn2: ""
                    })

                } else {
                    this.setState({
                        nidn2: 'NIDN : ' + this.state.ttd[1].nidn_ttdsurat
                    })

                }
                if (this.state.ttd[2].nidn_ttdsurat == "") {
                    this.setState({
                        nidn3: ""
                    })

                } else {
                    this.setState({
                        nidn3: 'NIDN : ' + this.state.ttd[2].nidn_ttdsurat
                    })

                }
                this.setState({
                    nidn4: ''
                })

                this.setState({
                    jbtn1: this.state.ttd[0].ketkode_rektor,
                    jbtn2: this.state.ttd[1].ketkode_rektor,
                    jbtn3: this.state.ttd[2].ketkode_rektor,
                    jbtn4: ''
                })

            } else if (this.state.nttd == 2) {
                this.setState({
                    ttd1: this.state.ttd[0].nama_ttdsurat,
                    ttd2: this.state.ttd[1].nama_ttdsurat,
                    ttd3: '',
                    ttd4: ''
                })

                if (this.state.ttd[0].nidn_ttdsurat == "") {
                    this.setState({
                        nidn1: ""
                    })

                } else {
                    this.setState({
                        nidn1: 'NIDN : ' + this.state.ttd[0].nidn_ttdsurat
                    })

                }
                if (this.state.ttd[1].nidn_ttdsurat == "") {
                    this.setState({
                        nidn2: ""
                    })

                } else {
                    this.setState({
                        nidn2: 'NIDN : ' + this.state.ttd[1].nidn_ttdsurat
                    })

                }

                this.setState({
                    nidn3: '',
                    nidn4: '',

                    jbtn1: this.state.ttd[0].ketkode_rektor,
                    jbtn2: this.state.ttd[1].ketkode_rektor,
                    jbtn3: '',
                    jbtn4: ''
                })

            } else {
                // if (this.state.ttd == "") {

                // } else {
                this.setState({
                    ttd1: this.state.ttd[0].nama_ttdsurat,
                    ttd2: '',
                    ttd3: '',
                    ttd4: ''
                })

                if (this.state.ttd[0].nidn_ttdsurat == "") {
                    this.setState({
                        nidn1: ""
                    })

                } else {
                    this.setState({
                        nidn1: 'NIDN : ' + this.state.ttd[0].nidn_ttdsurat
                    })

                }
                // this.state.nidn1 = this.state.ttd[0].nidn_ttdsurat
                this.setState({
                    nidn2: '',
                    nidn3: '',
                    nidn4: '',

                    jbtn1: this.state.ttd[0].ketkode_rektor,
                    jbtn2: '',
                    jbtn3: '',
                    jbtn4: ''
                })

            }

            // }
        }

        // console.log('log ttd1 =>', this.state.ttd1)


        // console.log(this.state.m7)

        // const ttdv = await API.get('surat/keluar/preview/pdf/ttd/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + sentid)
        // console.log(ttdv)

        // if (this.state.resprint == 0) {
        //     // const aaaa = "ssssss"
        //     console.log(ttdv)
        //     let timer = setInterval(() => {
        //         if (this.state.resprint >= 1) {
        //             clearInterval(timer)
        //             const previewer = new Previewer()
        //             previewer
        //                 .preview(
        //                     document.querySelector("#print-area").innerHTML,
        //                     [],
        //                     document.querySelector("#modified-print-area")
        //                 )
        //                 .then(flow => {
        //                     document.getElementById('print-area').style.display = 'none'
        //                     console.log("preview rendered, total pages", flow.total, { flow });


        //                     for (let i = 0; i < flow.pages.length; i++) {

        //                         flow.pages[i].pagebox.children[6].children[1].innerText = i + 1

        //                     }
        //                     // window.print()

        //                 });
        //         } else {
        //             this.setState({ resprint: this.state.resprint + 1 });
        //         }

        //     }, 1000);



        // this.fungsidewe(this.state.file_suratkeluar)

        // function printDiv(printarea) {
        //     var printContents = document.getElementById(printarea).innerHTML;
        //     var originalContents = document.body.innerHTML;
        //     document.getElementById('header').style.display = 'none';
        //     document.getElementById('footer').style.display = 'none';
        //     document.body.innerHTML = printContents;

        //     window.print();


        //     document.body.innerHTML = originalContents
        // }


        // }


        // setInterval(() => window.print(), 1000);
        let tmr = setInterval(() => {
            if (this.state.wkwk >= 1) {
                clearInterval(tmr);
                window.print()
            } else {
                this.setState({ wkwk: this.state.wkwk + 1 });
            }
        }, 2000);
        this.timer = setInterval(() => this.cek(), 1000);
    }

    // fungsidewe(urlpdf) {
    //     pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js';
    //     var url = config.baseURL + urlpdf;
    //     // var url = 'http://fe-surat.s2asia.ac.id/apitest/public/asset/file/surat/notulen/202009.pdf'
    //     var thePdf = null;
    //     var scale = 1.333; //scale A4

    //     pdfjsLib.getDocument(url).promise.then(function (pdf) {
    //         thePdf = pdf;
    //         var viewer = document.getElementById('pdf-viewer');
    //         for (var page = 1; page <= pdf.numPages; page++) {
    //             var canvas = document.createElement("canvas");
    //             canvas.className = 'pdf-page-canvas';
    //             viewer.appendChild(canvas);
    //             renderPage(page, canvas);
    //             // console.log(pdf.numPages)
    //         }
    //     });

    //     function renderPage(pageNumber, canvas) {
    //         thePdf.getPage(pageNumber).then(function (page) {
    //             var viewport = page.getViewport({ scale });
    //             // var viewport = page.getViewport(canvas.width / page.getViewport(1.0).width);
    //             canvas.height = viewport.height;
    //             canvas.width = viewport.width;
    //             page.render({
    //                 canvasContext: canvas.getContext('2d'),
    //                 viewport: viewport
    //             });
    //         });
    //         // console.log(url)
    //     }
    // }

    cek() {

        // if (sessionStorage.getItem('un') == null) {
        //     this.props.history.push('/loadingout')

        // }
        // else{
        //     window.print()
        // }
    }

    close() {
        window.close()
    }

    fotteerr(qwerty) {
        var qwerty = this.state.tjn.length
        // var rowQwerty = qwerty.length
    }

    render() {

        const headernya = (val) => {
            if (val == "") return ''
            else if (val == null) return ''
            else return <div className="col-md-12">
                <div className="form-group">
                    <div className="input-group" align="center">
                        <img src={config.baseURL + this.state.header_suratkeluar} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
        }

        const bulannya = (val) => {
            if (val == "1") return 'Januari'
            if (val == "2") return 'Februari'
            if (val == "3") return 'Maret'
            if (val == "4") return 'April'
            if (val == "5") return 'Mei'
            if (val == "6") return 'Juni'
            if (val == "7") return 'Juli'
            if (val == "8") return 'Agustus'
            if (val == "9") return 'September'
            if (val == "10") return 'Oktober'
            if (val == "11") return 'Nopember'
            if (val == "12") return 'Desember'
            else return this.state.bln_new
        }

        const b1 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '40px' }} align="center">
                <b><u style={{fontSize: '21px'}}>{this.state.ket_jenis}</u></b>
            </div>
            else return ''
        }

        const b2 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '0px', paddingLeft: '25%', paddingRight: '25%' }}>
                <div className="col-md-12" align="center" style={{ fontSize: '17px' }}>
                    No : {this.state.no_suratkeluar}
                </div>
            </div>
            else return ''
        }

        const b3 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{
                border: '0px solid black', marginTop: '20px',
                fontSize: '17px'
            }} align="center">
                <strong style={{fontSize: '17px'}}>Tentang :<b><br />{this.state.hal_suratkeluar}</b></strong>
            </div>
            else return ''
        }

        const b4 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{
                border: '0px solid black', marginTop: '10px', marginBottom: '40px',
                padding: '0px 0px 10px 0px', width: '21.5cm'
            }}>
                <div style={{ wordBreak: 'break-word', margin: '0' }}>
                    {ReactHtmlParser(this.state.isi_suratkeluar)}
                </div>
            </div>
            else return ''
        }

        const b5 = (val) => {
            if (val == "z1") return 'Mengetahui,'
            else if (val == "z2") return 'Menyetujui,'
            else if (val == "z3") return <div style={{fontSize: '17px'}}>Ditetapkan di : Malang <br /> Pada Tanggal : {`${this.state.tgl_new} ${bulannya(this.state.bln_new)} ${this.state.thn_new}`}</div>
            else if (val == "z4") return 'Hormat Kami'
            else if (val == "z5") return <div style={{fontSize: '17px'}}>Malang, {`${this.state.tgl_new} ${bulannya(this.state.bln_new)} ${this.state.thn_new}`}</div>
            else return ''
        }

        const b6 = (val) => {
            if (val == "z1") return 'Mengetahui,'
            else if (val == "z2") return 'Menyetujui,'
            else if (val == "z3") return <div style={{fontSize: '17px'}}>Ditetapkan di : Malang <br /> Pada Tanggal : {this.state.tgl_suratkeluar}</div>
            else if (val == "z4") return 'Hormat Kami'
            else if (val == "z5") return <div style={{fontSize: '17px'}}>Malang, {this.state.tgl_suratkeluar}</div>
            else return ''
        }
        
        const b7 = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.ttd1}<br />{this.state.nidn1}
                {/* {console.log(this.state.ttd1)} */}

                {/* sadasdasdasdasdasd */}
            </div>
            else return ''
        }

        const b7jabatan = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.jbtn1}
            </div>
            else return ''
        }

        const b8 = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.ttd2}<br />{this.state.nidn2}
            </div>
            else return ''
        }

        const b8jabatan = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.jbtn2}
            </div>
            else return ''
        }

        const b9 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '40px', padding: '0px', fontSize: '17px'}}>
                Lampiran Surat {this.state.ket_jenis} Nomor : {this.state.no_suratkeluar}<br />
                Tentang :<br /><br />{ReactHtmlParser(this.state.lampiran_suratkeluar)}
            </div>
            else return ''
        }

        const b10 = (val) => {
            if (val == "z1") return 'Mengetahui,'
            else if (val == "z2") return 'Menyetujui,'
            else if (val == "z3") return <div style={{fontSize: '17px'}}>Ditetapkan di : Malang <br /> Pada Tanggal : {this.state.tgl_suratkeluar}</div>
            else if (val == "z4") return 'Hormat Kami'
            else if (val == "z5") return <div style={{fontSize: '17px'}}>Malang, {this.state.tgl_suratkeluar}</div>
            else return ''
        }

        const b11 = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.ttd3}<br />{this.state.nidn3}
            </div>
            else return ''
        }

        const b11jabatan = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.jbtn3}
            </div>
            else return ''
        }

        const b12 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '40px', padding: '0px' }}>
                {ReactHtmlParser(this.state.tembuasan_suratkeluar)}
            </div>
            else return ''
        }

        const b13 = (val) => {
            if (val == "v") return <div className="col-md-6" align="left" style={{ border: '0px solid black', padding: '0px', fontSize: '17px'}} >
                Kepada Yth.<br />{this.state.tujuan_suratkeluar}
            </div>
            else return ''
        }

        const b14 = (val) => {
            if (val == "v") return <div className="col-md-5" align="left" style={{ border: '0px solid black', padding: '0px', fontSize: '17px'}}>
                Di <br /> {this.state.alamat_suratkeluar}
            </div>
            else return ''
        }

        const b15 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', paddingLeft: '0px', marginTop: '20px', fontSize: '17px'}} align="left">
                Kepada Yth.<br />{this.state.tujuan_suratkeluar}
            </div>
            else return ''
        }

        const b16 = (val) => {
            if (val == "v") return <div className="col-md-5" style={{ border: '0px solid black', paddingLeft: '0px', marginTop: '0px', fontSize: '17px'}} align="left">
                Di <br />{this.state.alamat_suratkeluar}
            </div>
            else return ''
        }

        const b17 = (val) => {
            if (val == "v") return <div className="col-md-12" style={{border: '0px solid black', paddingLeft: '0px', marginTop: '40px', fontSize: '17px'}} align="left">
                <table border="0">
                    <tbody>
                        <tr>
                            <td>No.</td>
                            <td style={{ paddingLeft: '2px' }}>: {this.state.no_suratkeluar}</td>
                        </tr>
                        <tr>
                            <td>Lampiran</td>
                            <td style={{ paddingLeft: '2px' }}>: {this.state.lam_suratkeluar}</td>
                        </tr>
                        <tr>
                            <td>Perihal</td>
                            <td style={{ paddingLeft: '2px' }}>: {this.state.hal_suratkeluar}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            else return ''
        }

        const b18 = (val) => {
            if (val == "v") return <div className={col13(this.state.m13.has_value)} style={{ border: '0px solid black', fontSize: '17px'}} align="right">
                Malang, {this.state.tgl_suratkeluar}
            </div>
            else return ''
        }

        const b19 = (val) => {
            if (val == "z1") return 'Mengetahui,'
            else if (val == "z2") return 'Menyetujui,'
            else if (val == "z3") return <div style={{fontSize: '17px'}}>Ditetapkan di : Malang <br /> Pada Tanggal : {this.state.tgl_suratkeluar}</div>
            else if (val == "z4") return 'Hormat Kami'
            else if (val == "z5") return <div style={{fontSize: '17px'}}>Malang, {this.state.tgl_suratkeluar}</div>
            else return ''
        }

        const b20 = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.ttd4}<br />{this.state.nidn4}
            </div>
            else return ''
        }

        const b20jabatan = (val) => {
            if (val == "v") return <div style={{fontSize: '17px'}}>
                {this.state.jbtn4}
            </div>
            else return ''
        }

        const col13 = (val) => {
            if (val == "v") return 'col-md-6'
            else return 'col-md-12'
        }
        // ========================================================================================

        const rep = (val) => {
            if (val === "R.0") return 'Rektor'
            else if (val === "R.1") return 'Wakil Rektor I'
            else if (val === "R.2") return 'Wakil Rektor II'
            else if (val === "R.3") return 'Wakil Rektor III'
            else if (val === "R.4") return 'Wakil Rektor IV'
            else return ''
        }

        const tujuanmulti = this.state.tjn.map(tjuan => {


            const b13multi = (val) => {
                if (val == "v") return <div className="col-md-6" style={{ border: '0px solid black', fontSize: '17px'}} >
                    Kepada Yth.<br />{this.getuser(tjuan.id)}
                </div>
                else return ''
            }

            const b15multi = (val) => {
                if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', paddingLeft: '0px', marginTop: '20px', fontSize: '17px'}} align="left">
                    Kepada Yth.<br />
                    {this.getuser(tjuan.id)}
                    {/* {tjuan.id} */}
                </div>
                else return ''
            }
            // ,  /*marginTop: '7px',paddingTop: '70px', paddingLeft: '70px', paddingRight: '70px', paddingBottom: '0px'*/
            return (
                <div>
                    <div className="kirik">
                        <div className="font-surat font-12" style={{ width: '210mm', height: 'auto', backgroundColor: '#FFF', border: '0px solid black', padding: '0px' }}>
                            <div className="row">
                                {headernya(this.state.header_suratkeluar)}
                                <div className="col-md-12" style={{ border: '0px solid black' }}>
                                    {/* header  */}
                                    {b13multi(this.state.m13)}
                                    {b18(this.state.m18)}
                                    {b14(this.state.m14)}
                                    {b1(this.state.m1)}
                                    {b2(this.state.m2)}
                                    {b3(this.state.m3)}
                                    {b17(this.state.m17)}
                                    {b15multi(this.state.m15)}
                                    {b16(this.state.m16)}
                                </div>

                                {/* isi */}
                                <div className="col-md-12" align="left" style={{ border: '0px solid black' }}>
                                    {b4(this.state.m4)}
                                </div>

                                {/* ttd 2  */}
                                <div className="col-md-12" align="Left" style={{ border: '0px solid black' }}>
                                    <table style={{ margin: '0', width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '47%' }}>{b6(this.state.m6)}</td>
                                                <td style={{ width: '6%' }} ></td>
                                                <td style={{ width: '47%' }}>{b5(this.state.m5)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '47%' }}>{b8jabatan(this.state.m8)}<br /><br /><br /><br /><br />{b8(this.state.m8)}</td>
                                                <td style={{ width: '6%' }} ></td>
                                                <td style={{ width: '47%' }}>{b7jabatan(this.state.m7)}<br /><br /><br /><br /><br />{b7(this.state.m7)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="p" align="left">
                                    {/* lampiran  */}
                                    <div className="col-md-12" style={{ border: '0px solid black' }}>
                                        {b9(this.state.m9)}
                                    </div>

                                    {/* ttd */}
                                    <div className="col-md-12" align="Left" style={{ border: '0px solid black' }} >
                                        <table style={{ marginTop: '20px', width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '47%' }}>{b19(this.state.m19)}</td>
                                                    <td style={{ width: '6%' }} ></td>
                                                    <td style={{ width: '47%' }}>{b10(this.state.m10)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '47%' }}>{b20jabatan(this.state.m20)}<br /><br /><br /><br /><br />{b20(this.state.m20)}</td>
                                                    <td style={{ width: '6%' }} ></td>
                                                    <td style={{ width: '47%' }}>{b11jabatan(this.state.m11)}<br /><br /><br /><br /><br />{b11(this.state.m11)}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* tembusan  */}
                                    <div className="col-md-12" style={{ border: '0px solid black' }}>
                                        {b12(this.state.m12)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{pageBreak:'always'}}></div> */}
                    {/* <div align="center" style={{bottom:'0', position: "ini", left: '50%', fontSize: '12'}}>
                        {tjuan.noutujuan}
                    </div> */}
                    {/* <div className="footer" id="non-printable">sasasas</div> */}

                    <style>
                        {`@media print {

                            
                            .kirik {
                                page-break-inside: avoid;
                            }
                        }`}
                    </style>
                </div>

            )
        })

        const tjnsrtklwr = (val) => {
            if (val == "-") return tujuanmulti
            else return <div className="box-body">
                <div className="font-surat font-12" style={{ width: '210mm', height: 'auto', backgroundColor: '#FFF', border: '0px solid black' }}>
                    <div className="row">
                        {headernya(this.state.header_suratkeluar)}
                        <div className="col-md-12" style={{ border: '0px solid black' }}>
                            {/* header  */}
                            {b13(this.state.m13)}
                            {b18(this.state.m18)}
                            {b14(this.state.m14)}
                            {b1(this.state.m1)}
                            {b2(this.state.m2)}
                            {b3(this.state.m3)}
                            {b17(this.state.m17)}
                            {b15(this.state.m15)}
                            {b16(this.state.m16)}
                        </div>

                        {/* isi */}
                        <div className="col-md-12" align="left" style={{ border: '0px solid black'}}>
                            {b4(this.state.m4)}
                        </div>

                        {/* ttd 2  */}
                        <div className="col-md-12" align="Left" style={{ border: '0px solid black' }}>
                            <table style={{ margin: '0', width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '40%' }}>{b6(this.state.m6)}</td>
                                        <td style={{ width: '20%' }} ></td>
                                        <td style={{ width: '40%' }}>{b5(this.state.m5)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '40%' }}>{b8jabatan(this.state.m8)}<br /><br /><br /><br /><br />{b8(this.state.m8)}</td>
                                        <td style={{ width: '20%' }} ></td>
                                        <td style={{ width: '40%' }}>{b7jabatan(this.state.m7)}<br /><br /><br /><br /><br />{b7(this.state.m7)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="p" align="left">
                            {/* lampiran  */}
                            <div className="col-md-12" style={{ border: '0px solid black' }}>
                                {b9(this.state.m9)}
                            </div>

                            {/* ttd */}
                            <div className="col-md-12" align="Left" style={{ border: '0px solid black' }} >
                                <table style={{ marginTop: '10px', width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '40%' }}>{b19(this.state.m19)}</td>
                                            <td style={{ width: '20%' }} ></td>
                                            <td style={{ width: '40%' }}>{b10(this.state.m10)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '40%' }}>{b20jabatan(this.state.m20)}<br /><br /><br /><br /><br />{b20(this.state.m20)}</td>
                                            <td style={{ width: '20%' }} ></td>
                                            <td style={{ width: '40%' }}>{b11jabatan(this.state.m11)}<br /><br /><br /><br /><br />{b11(this.state.m11)}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* tembusan  */}
                            <div className="col-md-12" style={{ border: '0px solid black' }}>
                                {b12(this.state.m12)}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="pageBreak"></div> */}
            </div>
        }

        return (
            <div >
                <a href="#" className="download-btn"
                    data-toggle="tooltip" title="Download"
                    onClick={() => window.print()}><i className="fa fa-download"></i>
                </a>

                <a href="#" className="tutup-btn" onClick={this.close}
                    data-toggle="tooltip" title="Tutup">
                    <i className="fa fa-times"></i>
                </a>
                <div>
                    <center>
                        <div id="print-area" style = {{ paddingLeft : '3cm', paddingRight : '2cm'}}>
                            {/* <div id="printarea"> */}
                            {tjnsrtklwr(this.state.tujuan_suratkeluar)}
                        </div>
                        {/* <div id="modified-print-area"></div> */}
                    </center>
                </div>
            </div >
        )
    }
}
