import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Loading from './V_Loading'
import V_KelolaUser from './administrator/V_KelolaUser'
import ClearCache from 'react-clear-cache'
import keyastor from '../axios/key_astor'
import API_ASTOR from '../axios/Api_astor'

export default class Login extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      un: '',
      ua: '',
      usd: '',
      user_name: '',
      user_password: '',
      filedatas: '',
      semongko: '',
      v_versi: '',
      b_versi: '',
      n_versi: '',
      Lot: [],
      cklink: '0'
    }
  }

  async componentDidMount() {
    const lsun = this.props.match.params.usr
    const lspss = this.props.match.params.pss

    this.setState({
      user_name: lsun,
      user_password: lspss
    })

    const scp = this.props.match.params.un
    const res = await API_ASTOR.get('aplikasi/surat/search/lv/join/app/inview' + keyastor.baseURL + '&uid=' + scp + '&app=1')
    this.setState({
      un: res.data[0].user_id,
      uas: res.data[0].level_id,
      ua: res.data[0].level_id,
      usd: res.data[0].usid,
      v_versi: res.data[0].v_versi,
      b_versi: res.data[0].b_versi,
      n_versi: res.data[0].n_versi,
      Lot: res.data[0].lots
    })

    if (this.state.Lot == '') {
      // console.log('null lot')
      // alert("Anda tidak memiliki akses untuk masuk ke aplikasi ini. Hubungi Admin UPT-SI / Server Untuk Mengakses Aplikasi Ini")
      localStorage.clear()
      sessionStorage.clear()
      // window.location.replace('/notdash')
      this.setState({
        cklink: '0'
      })
    } else {
      if (this.state.Lot[0].akses == 0) {
        // alert("Anda tidak memiliki akses untuk masuk ke aplikasi ini")
        localStorage.clear()
        sessionStorage.clear()
        // window.location.replace('/notdash')
        this.setState({
          cklink: '0'
        })
      } else {
        if (this.state.b_versi == 1) {
          this.setState({
            semongko: 1,
          })
        } else {
          this.setState({
            semongko: 0,
          })
        }


        await API_ASTOR.post('login/staf/dash/in/ul' + keyastor.baseURL, this.state)
          .then(Response =>
            this.setState({
              filedatas: Response.data[0].filedatas,
            })
          )

        if (this.state.filedatas == 1) {
          sessionStorage.setItem("un", this.state.un)
          // localStorage.setItem("ak", JSON.stringify(this.state.uas))
          sessionStorage.setItem("ak", this.state.Lot[0].akses)
          sessionStorage.setItem("sid", this.state.usd)
          

          const ar = [{ "is verivy": "true", }]
          localStorage.setItem('l', JSON.stringify(ar))

          this.setState({
            cklink: '1'
          })

          this.sesscek()
        } else {
          // sessionStorage.clear()
          // localStorage.clear()
          // window.location.replace('/notdash')
          this.setState({
            cklink: '0'
          })
        }
      }
    }


    // console.log(this.state)

  }

  sesscek() {
    if (sessionStorage.getItem("un") == null) {
      localStorage.clear()
      sessionStorage.clear()
      // window.location.replace('/notdash')
      this.setState({
        cklink: '0'
      })
    }
    else {
      if (this.state.b_versi == 1) {
        localStorage.removeItem('s')
        window.location.replace('/home')
      } else {

        if (this.state.semongko == 1) {
          window.location.replace('/home')
        } else {
          localStorage.removeItem('s')
          // window.location.replace('/notdash')
          this.setState({
            cklink: '0'
          })
        }
      }
    }
  }

  lainkalidiupdate = async () => {
    const idui = sessionStorage.getItem('sid')
    const sem = await API_ASTOR.post('update/versi/app/surat/lain/kali/no' + keyastor.baseURL + '&idus=' + idui + '&appid=1')

    window.location.replace('/home')
  }

  onupdateversiapp = async () => {
    const idui = sessionStorage.getItem('sid')
    const sem = await API_ASTOR.post('update/versi/app/surat/up' + keyastor.baseURL + '&idus=' + idui + '&appid=1')
    this.setState({
      semongko: sem.data[0].semongko
    })
    alert("Berhasil Update Aplikasi. Silahkan Login Kembali")
    this.sesscek()
  }


  render() {
    const linked = (val) => {
      if  (val == 0) return window.location.replace('../../../notdash')
    }

    const sis = (val) => {
      
      if (val === 0) return <div id="aku">
        <div className="modal-dialog">
          <div className="modal-content" style={{ borderRadius: '13px' }}>
            <div className="modal-header" style={{ paddingLeft: '3%' }}>
              <h4 className="modal-title fontku" style={{ color: '#5793ba' }}> <b>Pembaharuan Aplikasi</b></h4>
            </div>
            <div className="modal-body" style={{ paddingLeft: '3%' }}>
              <p className="fontku" style={{ color: '#6d8570', fontWeight: 'bolder' }}>Anda memakai aplikasi versi {this.state.v_versi} .<br />Apakah anda ingin memperbaharui aplikasi ke versi {this.state.n_versi} ?</p>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={this.lainkalidiupdate} className="btn btn-light fontku">Lain kali</button>
              <button type="button" onClick={this.onupdateversiapp} className="btn btn-primary fontku">Perbarui Versi</button>
            </div>
            <img src="https://i.ibb.co/WWmWhq7/lampu.png" style={{
              position: 'absolute', width: '18%',
              marginTop: '-40%', marginLeft: '88%'
            }}></img>
          </div>
        </div>
      </div>
      else return ''
    }

    return (
      <div>
        {sis(this.state.semongko)}
        {/* {linked(this.state.cklink)} */}
        {/* <iframe src="t-rex-runner-gh-pages" style={{ top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '90vh', border: 'none', margin: 0, padding: 0, overflow: 'hidden', zIndex: 9999, overflow: 'hidden' }}></iframe> */}
        {/* <div className="limiter">
          <div className="container-login100">
            <div className="col-md-3">
              <div className="box box-solid">
                <div className="overlay">
                  <li className="fa fa-refresh fa-spin" />
                </div>
              </div><br />
              <div className=" box-solid" align="center">
                <h4><b style={{ color: '#fff' }}>LOADING DATA ....{ }</b></h4>
                
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
