import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_opjenis from './v_opjenis'

export default class etheme extends Component {

  constructor() {
    super()

    this.state = {
      distribusi: [],
      voption: [],
      id_theme: '',
      id_jenis: '',
      nama_theme: '',
      ket_theme: '',
      jsurat: '',
      kettheme:'',
      filedatas:''
    }
    this.handleChange = this.handleChange.bind( this );
    this.onEditorChange = this.onEditorChange.bind( this );
  }

  // cange ck ===================
  onEditorChange( evt ) {
		this.setState( {
			ket_theme: evt.editor.getData()
    } );
    
    
	}

	handleChange( changeEvent ) {
		this.setState( {
			ket_theme: changeEvent.target.value
		} );
  }
  
  // batas ck===================

  handlegoyang = (e) => {
    // console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }

  handleJenisSurat = (e) => {
    // console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ kettheme: e.target.value })
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value

    })
    //console.log(this.setState)
  }


  async componentDidMount() {
    // this.loaddaya()
    const aks = sessionStorage.getItem("ak")

    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);

    const id_theme = this.props.match.params.id_theme
    const res = await API.get('surat/theme/etheme/edit/all/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&id=' + id_theme)

    this.setState({
      id_theme: res.data[0].id_theme,
      id_jenis: res.data[0].id_jenis,
      nama_theme: res.data[0].nama_theme,
      ket_theme: res.data[0].ket_theme,
      jsurat: res.data[0].ket_jenis
    })

    await API.get('surat/theme/ttheme/jenis/theme/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + aks)
      .then(Response => this.setState({
        //this.setState({
        distribusi: Response.data
      }))
      console.log(this.state.kettheme)
    // const idthe = this.props.match.params.id_theme
    // await API.get('buat/surat/jenis/cari/j?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + idthe)
    //   .then(Response => this.setState({
    //     jsurat: Response.data
    //   }))

    // console.log(res)
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    // const scp = sessionStorage.getItem("ak")
    await API.post('surat/theme/edit/theme/up/up?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + this.state.id_theme, this.state)
    .then(Response =>
      this.setState({
        filedatas: Response.data[0].filedatas
      })
      
    )

    if (this.state.filedatas == 1) {
      alert("Berhasil Edit")
      window.location.replace('/ptheme')
    }else{
      alert("Gagal Edit")
    }

    // console.log(this.state)
  }

  render() {
    const { id_theme, id_jenis, nama_theme, ket_theme, jsurat } = this.state

    const ttj = this.state.distribusi.map(distribusi => {
      return (
        <V_opjenis v_opjenis={distribusi} key={distribusi.id_jenis} />
      )
    })

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          <section className="content-header">
            <h1>
              Edit Theme Jenis Surat {this.state.jsurat}
            </h1>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Theme Jenis Surat {this.state.jsurat}</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  <div className="box-body">
                    {/* <form action={`${config.baseURL}surat/theme/edit/theme/up/up?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid= ${this.state.id_theme}`} method="post" > */}
                    <form onSubmit={this.handleSubmit}>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Nama Theme</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <input type="text" defaultValue={this.state.nama_theme} name="nama_theme" className="form-control" onChange={this.handleInputChange} placeholder="Judul Theme" required />
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Jenis Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select className="Sent_type" name="jenis" className="form-control" onChange={this.handlegoyang} required>
                              <option value="">--Jenis Surat--</option>
                              {ttj}
                            </select>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-md-12">

                        <div className="form-group">
                          <label>Isi Theme (Isi Surat)</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.ket_theme}
                              name='kettheme'
                              // onChange={this.ckchange}
                              onChange={this.onEditorChange}
                            />
                            {/* <textarea id="editor2" defaultValue={this.state.ket_theme} name="kettheme" onChange={this.handleTtdSurat} rows="10" cols="80" required>
                            </textarea> */}
                          </div>
                        </div>
                        <button type="Submit" className="btn btn-primary">Update Theme Theme</button>&nbsp;
                        <Link to="/ptheme" className="btn btn-primary">Kembali</Link>
                      </div>
                    </form>


                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>

    )
  }
}
