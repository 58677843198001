import React, { Component } from 'react'
import API from '../axios/Api'
// import V_Kerjasama from './V_Kerjasama'
import Printer, { print } from 'react-pdf-print'
import ReactHtmlParser from 'react-html-parser';

const ids = ['1']
export default class Kerjasama extends Component {

    state = {
        pdf: [],
        data: [],
        voption: [],
        voptionb: [],
        sent_id: '',
        Sent_type: '',
        Sent_creator: '',
        Sentcreator: '',
        Sent_groub: '',
        Sent_to: '',
        Sent_category: '',
        Sent_date: '',
        Sent_about: '',
        Sent_code: '',
        Sent_content: '',
        Sent_dcreator: '',
        Sent_nidn: ''
    }

    async componentDidMount() {
        // await API.get("read_sent.php")
        //     .then(Response => this.setState({
        //         pdf: Response.data
        //     }))

        // console.log(this.state)

        const sentid = this.props.match.params.sent_id
        const res = await API.get('surat/keluar/report/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idr=' + sentid)
        this.setState({
            sent_id: res.data[0].sent_id,
            Sent_code: res.data[0].sent_code,
            Sent_type: res.data[0].sent_type,
            Sent_creator: res.data[0].sent_creator,
            Sent_dcreator: res.data[0].sent_dcreator,
            Sent_groub: res.data[0].sent_groub,
            Sent_category: res.data[0].sent_category,
            Sent_date: res.data[0].sentdate,
            Sent_about: res.data[0].sent_about,
            Sent_content: res.data[0].sent_content,
            Sent_to: res.data[0].sent_to,
            Sent_nidn: res.data[0].sent_nidn
        })
    }


    render() {

        // const renderData = this.state.pdf.map(pdf => {
        //     return (
        //         <V_Kerjasama v_pdf={pdf} key={pdf.Sent_code} />
        //     )
        // })

        return (

            //   <div>
            //       {renderData}
            //   </div>
            <div>
                <button id="button" onClick={() => print(ids)} className="btn btn-primary">Download</button>
                <center>
                    <Printer>
                        <div id={ids[0]} style={{ border: '2px solid black', width: '210mm', height: '297mm', marginTop: '30px', marginRight: '20px', marginBottom: '30px', marginLeft: '30px', padding: '60px' }}>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <center><font size="5"><b><u>SURAT PENGAJUAN KERJASAMA</u></b></font></center>
                            <center><font size="2" color="black">Nomor: {this.state.Sent_code}</font></center>
                            <br />
                            <p align='justify' style={{ textIndent: '0rem' }}>
                                <font size="2" color="black">
                                    <b>Kepada Yth.</b><br />
                                    {this.state.Sent_to}
                                    <br />
                                    Di Malang
                                    <br />
                                    <h5>Jawa Timur</h5>
                                    <br />
                                    <font size="2" color="black">Dengan Hormat,</font>
                                </font>
                            </p>
                            <br />

                            <p align='justify' style={{ textIndent: '0rem' }}>
                                <font size="2" color="black">
                                    {ReactHtmlParser(this.state.Sent_content)}
                                </font>
                            </p>


                            <p align='justify'>
                                <font size="2" color="black">
                                    Demikian surat ini kami buat. Atas kerjasamanya kami ucapkan terima kasih.
                                </font>
                            </p>

                            <br />

                            <table width="100%" border="0">

                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width='%'></td>
                                    <td width='' align=''><font size="2" color="black">Malang,  {this.state.Sent_date}<br /></font></td>
                                </tr>

                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width=''></td>
                                    <td width='' align=''><font size="2" color="black">,</font></td>
                                </tr>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width=''></td>
                                    <td width='' align=''><font size="2" color="black"><b><u>{this.state.Sent_dcreator}</u></b></font><br /></td>
                                </tr>
                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width='2%'></td>
                                    <td width='' align=''><font size="2" color="black"><b>NIDN. {this.state.Sent_nidn}</b></font></td>
                                </tr>




                            </table>
                        </div>
                    </Printer>
                </center>
            </div>

        )
    }
}
