import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_suratk from './v_optionbulan'
import V_thn from './v_optionthn'
import V_theme from './v_themeemergensi'
import V_bln from './v_optiontgl'
import API_ASTOR from '../axios/Api_astor'
// import API_Astor from '../axios/Api_astor'
import key_astor from '../axios/key_astor'
import key_surat from '../axios/key'


export default class bemergency extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: `....`,
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      kakeanpolah: '',
      distribusi: [],
      optiontahun: [],
      optiontanggal: [],
      bulan: '',
      tahun: '',
      jnssurat: [],
      tgl: '',
      filedatas: '',
      idusers: '',
      aksusers: '',
      unusers: '',
      idusers: '',
      aksusers: '',
      unusers: ''
    }
  }

  handlegoyang = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.name]: event.target.value

    })
  }

  async tanggalhari() {
    const aks = sessionStorage.getItem("ak")
    await API.get('emergensi/pilih/Hari/tampil/h' + key_surat.baseURL)
      .then(Response => this.setState({
        optiontanggal: Response.data
      }))
  }

  async tanggalbulan() {
    const aks = sessionStorage.getItem("ak")
    await API.get('emergensi/pilih/bulan/tampil/v' + key_surat.baseURL)
      .then(Response => this.setState({
        distribusi: Response.data
      }))
  }

  async tanggaltahun() {
    const aks = sessionStorage.getItem("ak")
    await API.get('emergensi/pilih/tahun/tampil/view' + key_surat.baseURL + '&aks=' + aks)
      .then(Response => this.setState({
        optiontahun: Response.data
      }))
  }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    const aks = sessionStorage.getItem("ak")
    await API.get('emergensi/pilih/tahun/tampil/view' + key_surat.baseURL + '&aks=' + this.state.aksusers)
      .then(Response => this.setState({
        optiontahun: Response.data
      }))

    this.tanggalhari()
    this.tanggalbulan()
    // this.tanggaltahun()

    this.timer = setInterval(() => this.cek(), 1000);

  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }

  async loaddaya() {
    const aks = sessionStorage.getItem("ak")
    await API.get('surat/bbaru/theme/all/v' + key_surat.baseURL + '&aks=' + this.state.aksusers + '&tgl=' + this.state.tgl + '&bln=' + this.state.bulan + '&thn=' + this.state.tahun)
      .then(Response => this.setState({
        jnssurat: Response.data
      }))
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const scp = sessionStorage.getItem("ak")
    await API.post('emergensi/cek/ketersediaan/bulan/tahun/view' + key_surat.baseURL + '&aks=' + this.state.aksusers, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Surat Emergensi Tersedia")
      this.loaddaya()
    } else if (this.state.filedatas == 33) {
      alert("Surat Emergensi Habis")
    } else {
      alert("Surat Emergensi Tidak Tersedia")
    }

  }

  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_suratk v_distribusi={distribusi} key={distribusi.b} />
      )
    })

    const renderthn = this.state.optiontahun.map(optiontahun => {
      return (
        <V_thn v_optionthn={optiontahun} key={optiontahun.t} />
      )
    })

    const rendertgl = this.state.optiontanggal.map(optiontanggal => {
      return (
        <V_bln v_optiontgl={optiontanggal} key={optiontanggal.hari} />
      )
    })

    const sts = (val) => {
      if (val == 1) return <section className="content">
        <div className="row">
          <div className="col-md-12 col-sm-12 margin-bottom-30">
            <div className="box box-primary box-solid">
              <div className="box-header">
                <strong>Pilih Theme Surat Emergensi</strong>
                <div className="box-tools pull-right">
                </div>
              </div>
              <div className="box-body">
                {ren}
              </div>
            </div>
          </div>
        </div>
      </section>
      else return ''
    }

    const ren = this.state.jnssurat.map(jnssurat => {
      return (
        <V_theme v_distribusi={jnssurat} key={jnssurat.id_jenis} />
      )
    })

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Buat Surat Emergensi</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Pilih Bulan Dan Tahun</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form action={`${config.baseURL}emergensi/cek/ketersediaan/bulan/tahun/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`} method="post" > */}
                    <form onSubmit={this.handleSubmit}>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Tanggal</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <select name="tgl" className="form-control" onChange={this.handlegoyang} required>
                              <option value=''>Tanggal</option>
                              <option value=''></option>
                              {rendertgl}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Bulan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <select name="bulan" className="form-control" onChange={this.handlegoyang} required>
                              <option value=''>Bulan</option>
                              <option value=''></option>
                              {renderData}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Tahun</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <select name="tahun" className="form-control" onChange={this.handlegoyang} required>
                              <option value=''>Tahun</option>
                              <option value=''></option>
                              {renderthn}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6" style={{marginTop: '24.49px', marginBottom: '10px'}}>
                        <button type="Submit" className="btn btn-primary text-right">Cek Ketersediaan Surat Emergensi</button> &nbsp;
                        <a href="/hsuratkeluar/0" className="btn btn-warning text-right">Kembali</a>
                      </div>

                    </form>
                  </div>
                </div>

              </div>
            </div>
          </section>
          {sts(this.state.filedatas)}
        </div>
        <Footer />
      </div>

    )
  }
}
