import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_suratk from './v_suratk'
import keysurat from '../axios/key'

import API_ASTOR from '../axios/Api_astor'
// import API_Astor from '../axios/Api_astor'
import key_astor from '../axios/key_astor'

export default class suratk extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: `....`,
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      kakeanpolah: '',
      distribusi:[],
      idusers: '',
      unusers: '',
      aksusers: '',
      // as: '0',
    }
  }

  async loaddaya() {
    const aks = sessionStorage.getItem("ak")
    await API.get('surat/baru/theme/ttheme/all/v' + keysurat.baseURL + '&aks=' + this.state.aksusers)
      .then(Response => this.setState({
        //this.setState({
        distribusi: Response.data
      }))
  }

  async componentDidMount() {
    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    this.loaddaya()

    // let timerloading = setInterval(() => {
    //   if (this.state.as >= 1) {
    //     clearInterval(timerloading);
    //     var loadingmamak = document.getElementById("preloader");
    //     loadingmamak.classList.add("loading");
    //   } else {
    //     this.setState({ as: this.state.as + 1 });
    //   }
    // }, 100);

    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }


  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_suratk v_distribusi={distribusi} key={distribusi.id_jenis} />
      )
    })
    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* <div id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div> */}
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Pilih Theme Jenis Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Daftar Theme Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {renderData}
                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
