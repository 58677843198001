import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'

export default class editnotulen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      objek: [],
      ckload: '0',
      // notulen_suratkeluar: '',
      isEdit: false,
      file_suratkeluar: null,
      notulen_suratkeluar: '',
      fiv: ''
    }
    this._CKEditor = null;
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  handleCkChange = (e, editor) => {
    const data = e.editor.getData();
    this.setState({
      notulen_suratkeluar: data
    })
  }

  onEditorChange(evt) {
    this.setState({
      notulen_suratkeluar: evt.editor.getData()
    });

  }

  handleChange(changeEvent) {
    this.setState({
      notulen_suratkeluar: changeEvent.target.value
    });
  }

  componentWillUnmount() {
    window.CKEDITOR.instances.editor1.destroy();
    // console.log('unmount');
  }


  async componentDidMount() {
    // const id_surat = this.props.match.params.id_surat
    // const res = await API.get('/notulen/isi/isinotulen/tampil/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + id_surat)
    // console.log(this._CKEditor)
    // this.setState({
    //   data1: res.data[0].notulen_suratkeluar,
    //   no_suratkeluar: res.data[0].no_suratkeluar,
    //   tujuan_suratkeluar: res.data[0].tujuan_suratkeluar,
    //   hal_suratkeluar: res.data[0].hal_suratkeluar,
    //   id_suratkeluar: res.data[0].id_suratkeluar,
    //   s_suratkeluar: res.data[0].s_suratkeluar,
    //   file_suratkeluar: res.data[0].file_suratkeluar
    // })
    // this._CKEditor.editor.setData(res.data[0].notulen_suratkeluar)
    // let timer = setInterval(() => {
    //   if (this.state.rwl >= 1) {
    //     clearInterval(timer);
    //     this.onCKloaded()
    //   } else {
    //     this.setState({ rwl: this.state.rwl + 1 });
    //   }
    // }, 500);

    const id_surat = this.props.match.params.id_surat
    const res = await API.get('/notulen/isi/isinotulen/tampil/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + id_surat)
    // console.log(this._CKEditor)
    let timers = setInterval(() => {

      if (this.state.ckload >= 1) {
        clearInterval(timers);

        this.setState({
          notulen_suratkeluar: res.data[0].notulen_suratkeluar,
          no_suratkeluar: res.data[0].no_suratkeluar,
          tujuan_suratkeluar: res.data[0].tujuan_suratkeluar,
          hal_suratkeluar: res.data[0].hal_suratkeluar,
          id_suratkeluar: res.data[0].id_suratkeluar,
          s_suratkeluar: res.data[0].s_suratkeluar,
          filesnya: res.data[0].file_suratkeluar
        })
        // console.log(this.state.notulen_suratkeluar)

      } else {
        this.setState({ ckload: this.state.ckload + 1 });
      }
    }, 1000);
    
  }

  // handleCkChange = (e, editor) => {
  //   const data = e.editor.getData();
  //   this.setState({
  //     notulen_suratkeluar: data
  //   })
  // }

  // handleCkChange = (evt) => {
  //   this.setState({
  //     data1: evt.editor.getData()
  //   });
  //   // console.log(evt)
  // }

  ValidasiFile = (event) => {
    event.preventDefault()
    this.setState({ file_suratkeluar: event.target.files[0] })
    // console.log(this.state.file_suratkeluar)


    var file = document.querySelector("#file");
    if (/\.(pdf)$/i.test(file.files[0].name) === false) {
      document.getElementById('file').innerHTML = '';
      document.getElementById('size').innerHTML = 'Error: file yang diupload harus berformat PDF';
      document.getElementById('file').value = '';
      document.getElementById('sesuai').innerHTML = '';
    } else {
      let fi = document.getElementById('file');
      // cek ukuran mb 
      if (fi.files.length > 0) {
        for (var i = 0; i <= fi.files.length - 1; i++) {
          var fsize = fi.files.item(i).size;
          const file = Math.round((fsize / 1024));
          if (file > 2048) {
            document.getElementById('file').value = '';
            document.getElementById('size').innerHTML = 'Error: file yang diupload harus kurang dari 2MB';
            document.getElementById('sesuai').innerHTML = '';
            document.getElementById('nama').innerHTML = '';
          } else {
            document.getElementById('size').innerHTML = '';
            document.getElementById('sesuai').innerHTML = 'File sudah sesuai';
            document.getElementById('nama').innerHTML = '';
          }
        }
      }
    }
  }
  onCKloaded = async () => {
    

    // this._CKEditor.editor.setData(res.data[0].notulen_suratkeluar)
  }



  validasiKirim = (event) => {
    var ckq = document.getElementById('ckFile').value
    if (ckq != '-' && ckq != '') return alert('Berhasil Edit Notulen!!')
    else
      event.preventDefault();
    alert('Isi Notulen Harus Diisi')
    return false
  }

  simpannotulen = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()



    // console.log(this.state.file_suratkeluar)
    await API.post('notulen/coba/upload/isi/notulen/' + this.state.id_suratkeluar + '?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE', this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas,
          nomer: Response.data[0].nomer
        })

      )
    console.log(this.state.filedatas,"f")
    if (this.state.filedatas == 1) {
      if (this.state.file_suratkeluar == null) { }
      else {
        const formData = new FormData();

        formData.append(
          "file_suratkeluar",
          this.state.file_suratkeluar,
          this.state.file_suratkeluar.name,
          this.state
        );

        await API.post(`notulen/upload/file/${this.state.id_suratkeluar}?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`, formData, {
          mode: 'no-cors',
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data"
          }

        })
          .then(Response =>
            this.setState({
              fiv: Response.data[0].fiv
            }),
          )
        // .catch(e => console.log(e))
        
      }
      if (this.state.filedatas == 1) {
        // alert("Berhasil Edit Data Notulen")
        // window.location.replace('/hsuratkeluar/0')
        
        var loadingmamak = document.getElementById('preloader')
        loadingmamak.classList.add('loading-jon')

        var popup = document.querySelector('.popbox')
        popup.classList.add('active')
        var popup1 = document.querySelector('.popup-box')
        popup1.classList.add('active')
        
        document.querySelector(".checkjon").onclick = function() {
          var popup2 = document.querySelector('.popbox')
          popup2.classList.remove('active')
          
          var popup3 = document.querySelector('.popup-box')
          popup3.classList.remove('active')
  
          window.location.replace('/hsuratkeluar/0')

        }
      } 
      else {
        var loadingmamak = document.getElementById('preloader')
        loadingmamak.classList.add('loading-jon')

        alert("Proses Gagal, Silakan Ulangi Lagi")
        window.location.reload();
      }

      // console.log(this.state, "v")

    }
  }

  render() {

    const namaFile = (val) => {
      let str = this.state.file_suratkeluar
      if (val == "0")
        return '-'
      else return 'file sebelumnya : ' + str
    }

    return (

      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">
          
        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon" onChange={this.okjek}></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Edit Notulen</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <h1></h1>
                    <strong>Form Edit Notulen</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form method="POST" action={`${config.baseURL}data123/update/esurat/${this.state.id_suratkeluar}?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=${this.state.id_suratkeluar}`} encType="multipart/form-data"> */}
                    <form onSubmit={this.simpannotulen}>
                      {/* <form method='post' action={`${config.baseURL}notulen/coba/upload/isi/notulen/${this.state.id_suratkeluar}?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`}> */}
                      <div className="form-group-row">
                        {/* start form group */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-tag"></i>
                              </div>
                              <input name="no_suratkeluar" defaultValue={this.state.no_suratkeluar} readOnly required className="form-control" onChange={this.handleInputChange} placeholder="Pembuat Undangan" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hal / Perihal</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bookmark"></i>
                              </div>
                              <input name="hal_suratkeluar" defaultValue={this.state.hal_suratkeluar} readOnly required className="form-control" onChange={this.handleInputChange} placeholder="Kategori" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tujuan</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-home"></i>
                              </div>
                              <input name="tujuan_suratkeluar" defaultValue={this.state.tujuan_suratkeluar} readOnly required className="form-control" onChange={this.handleInputChange} placeholder="Kategori" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Upload File <sup>*daftar hadir</sup></label>

                            <input type="file" name="file_suratkeluar"
                              id="file"
                              accept=".pdf"
                              placeholder="Digitalisasi Surat"
                              onChange={this.ValidasiFile}
                            />

                            {/* <input type="file" name="file_suratkeluar"
                              id="file"
                              accept=".pdf"
                              placeholder="Digitalisasi Surat"
                              onChange={this.ValidasiFile}
                              required
                            /> */}
                            <input type="hidden" id="valFile" defaultValue={this.state.filesnya}></input>
                            <p id="sesuai" style={{ color: 'green' }}></p>
                            <p id="size" style={{ color: 'red' }}></p>
                            <a href={`${config.baseURL}` + this.state.filesnya} target="_blank" id="nama">{namaFile(this.state.filesnya)}</a>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Isi Notulen Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-envelope"></i>
                              </div>
                              <CKEditor
                                data={this.state.notulen_suratkeluar}
                                ref={n => this._CKEditor = n}
                                // onLoaded={this.onCKloaded}
                                name="notulen_suratkeluar"
                                onChange={this.handleCkChange}
                              />

                              {/* <CKEditor
                                data={this.state.notulen_suratkeluar}
                                name="notulen_suratkeluar"
                                onChange={this.handleCkChange}
                              /> */}
                            </div>
                            {/* <input readOnly
                              type="hidden"
                              className="form-control"
                              name="ayamgeprek"
                              id="ckFile"
                              value={this.state.data1}>
                            </input> */}
                          </div>
                        </div>
                        {/* end form group */}
                      </div>
                      <div className="col-md-12 text-right">
                        <Link to="/hsuratkeluar/0" className="btn btn-warning" >Kembali</Link>
                        <button type="submit" className="btn btn-primary" style={{ marginLeft: '5px' }} >Kirim</button>&nbsp;
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
