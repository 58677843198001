import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_rektor from './v_rektor'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'

import makeAnimated from 'react-select/animated';
import { colourOptions } from '../data/datarjs';
import Select from 'react-select';

export default class femergensi extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      filedatas: '',
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: '',
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      kakeanpolah: '',
      jsurat: [],
      rektor: [],
      csurat: [],
      vcsurat: '',
      sc: '',
      kdj: '',
      ket_nttd: '',

      id_jenis: '',
      Lampiran: '0',
      isi_lamp: '',
      isi_surat: '',
      alamat: 'Tempat',

      data: '',
      Tembusan: '',

      tgl_surat: '',
      pembuat: '',
      pembuatsurat: '....',
      ttd_jenis: '',
      posisittd_jenis: '',
      pmbwt: '',
      tujuan: '',

      hddn2: '',
      hddn3: '',
      hddn4: '',

      ckttd: '',
      x2: '',
      x3: '',
      x4: '',

      rwl: '',

      ttd2: '',
      ttd3: '',
      ttd4: '',

      jbtn2: '',
      jbtn3: '',
      jbtn4: '',

      pmbwt2: '',
      pmbwt3: '',
      pmbwt4: '',

      cuy: '',
      fileheader: null,
      vile: '',
      nomernya: '',

      logolainjenis: '',
      usertag: [],
      listuser: [],
      inexscontrol: '-',
      nidn2: '',
      nidn3: '',
      nidn4: '',
      idusers: '',
      aksusers: '',
      unusers: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);

    this.handleChange2 = this.handleChange2.bind(this);
    this.onEditorChange2 = this.onEditorChange2.bind(this);

    this.handleChange3 = this.handleChange3.bind(this);
    this.onEditorChange3 = this.onEditorChange3.bind(this);

  }

  hc = usertag => {
    this.setState({ usertag });
    this.setState({
      tujuan: "-"
    });
    // console.log(this.state.usertag);
  };

  tjn = (ev) => {
    this.setState({
      tujuan: ev.target.value,
      usertag: ""
    })
  }

  // cange ck ===================
  onEditorChange(evt) {
    this.setState({
      data: evt.editor.getData()
    });

  }

  handleChange(changeEvent) {
    this.setState({
      data: changeEvent.target.value
    });
  }

  handleChange2(evt2) {
    this.setState({
      Tembusan: evt2.editor.getData()
    });

  }

  onEditorChange2(changeEvent2) {
    this.setState({
      Tembusan: changeEvent2.target.value
    });
  }

  handleChange3(evt2) {
    this.setState({
      isi_lamp: evt2.editor.getData()
    });

  }

  onEditorChange3(changeEvent2) {
    this.setState({
      isi_lamp: changeEvent2.target.value
    });
  }

  // batas ck===================

  handlegoyang = (e) => {
    this.setState({ jenissurat: e.target.value })
  }

  ckchange = (ck) => {
    this.setState({ sc: ck.target.value })
  }
  handleJenisSurat = (e) => {
    this.setState({ jenissurat: e.target.value })
  }

  ck = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  ckx2 = (e) => {
    this.setState({
      x2: e.target.value
    })

  }

  ckx3 = (e) => {
    this.setState({
      x3: e.target.value
    })
  }

  ckx4 = (e) => {
    this.setState({
      x4: e.target.value
    })
  }

  lampcange = (e) => {
    this.setState({
      Lampiran: e.target.value
    })

  }

  pmbuat = async (e) => {
    this.setState({ pembuat: e.target.value })

    let timer = setInterval(() => {
      if (this.state.pmbwt >= 1) {
        this.cnomor()
        clearInterval(timer);
      } else {
        this.setState({ pmbwt: this.state.pmbwt + 1 });
      }
    }, 500);
  }

  async cnomor() {
    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.pembuat)

    this.setState({
      pembuatsurat: crek.data.rektor_id,
      narex: crek.data.rektor_name
    })
  }

  handleTtdSurat = async (e) => {
    this.setState({ ttdsurat: e.target.value })
    if (e.target.value == "") {
      this.state.csurat = ""
    } else {
      await API_ASTOR.get('buat/surat/cari/rektor/on/change/c' + key_astor + '&aks=' + e.target.value)
        .then(Response => this.setState({
          csurat: Response.data[0].rektor_id
        }))
    }

  }

  masukjos = async (event) => {
    // event.preventDefault()
    API.post('imassage/user/send/add' + key_surat.baseURL, this.state)
      .then(sembarang => console.log(sembarang))

  }

  async componentDidMount() {

    const bln2 = this.props.match.params.bln
    const thn2 = this.props.match.params.thn

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    await API.get("jeneng/surat/keluar/view" + key_surat.baseURL + '&bln=' + bln2 + '&thn=' + thn2)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        backcode: Response.data[0].backoutcode
      }))

    
    const idus = sessionStorage.getItem("sid")
    const akak = sessionStorage.getItem("ak")
    await API.get('surat/emergensi/nomor/terakhir/new/normal/view' + key_surat.baseURL + '&ids=' + this.state.idusers + '&ak=' + this.state.aksusers + '&bln=' + bln2 + '&thn=' + thn2)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        firscode: Response.data[0].nomor
      }))

    // console.log(this.state.idusers)

    await API.get("date/now/view" + key_surat.baseURL)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        kakeanpolah: Response.data[0].dt
      }))

    // const id_theme = this.props.match.params.id_theme
    await API_ASTOR.get('surat/buat/rektor/tampil/all/view' + key_astor.baseURL + '&aks=' + this.state.aksusers)
      .then(Response => this.setState({
        rektor: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    await API_ASTOR.get('user/list/teg/view' + key_astor.baseURL)
      .then(Response => this.setState({
        listuser: Response.data
      }))

    const idtheme = this.props.match.params.id_theme

    const tgl = this.props.match.params.tgl
    const bln = this.props.match.params.bln
    const thn = this.props.match.params.thn

    this.state.tgl_surat = thn + '-' + bln + '-' + tgl

    // console.log(this.state.tgl_surat)

    const rew = await API.get('buat/surat/jenis/cari/j' + key_surat.baseURL + '&aks=' + idtheme)
    this.setState({
      data: rew.data[0].theme_jenis,
      logolainjenis: rew.data[0].logolain_jenis,
      // sc: rew.data[0].ket_theme,
      jsurat: rew.data[0].ket_jenis,
      kdj: rew.data[0].kode_jenis,
      // ket_nttd: rew.data[0].ket_nttd,
      id_jenis: rew.data[0].id_jenis,
      ttd_jenis: rew.data[0].ttd_jenis
    })

    if (this.state.ttd_jenis == 4) {
      this.state.ckttd = 4
      this.state.hddn2 = "hitfalse"
      this.state.hddn3 = "hitfalse"
      this.state.hddn4 = "hitfalse"
    } else if (this.state.ttd_jenis == 3) {
      this.state.ckttd = 3
      this.state.hddn2 = "hitfalse"
      this.state.hddn3 = "hitfalse"
      this.state.hddn4 = "hittrue"
    } else if (this.state.ttd_jenis == 2) {
      this.state.ckttd = 2
      this.state.hddn2 = "hitfalse"
      this.state.hddn3 = "hittrue"
      this.state.hddn4 = "hittrue"
    } else {
      this.state.ckttd = 1
      this.state.hddn2 = "hittrue"
      this.state.hddn3 = "hittrue"
      this.state.hddn4 = "hittrue"
    }

    let timer = setInterval(() => {
      if (this.state.rwl >= 1) {
        clearInterval(timer);
      } else {
        this.setState({ rwl: this.state.rwl + 1 });
      }
    }, 500);

    // console.log(rew)
    this.cek()
    // this.timer = setInterval(() => this.cek(), 1000);
  }

  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')
    }
  }

  cng() {
    console.log('ck cange')
  }

  savesurat() {
    if (!window.CKEDITOR.instances.editor2.getData())
      return
    // if (this.state.data==null){
    //   alert('isi tyidak boleh kosong')
    // }
  }

  tampilgambar = async (event) => {
    event.preventDefault()
    this.setState({ fileheader: event.target.files[0] })

    var output = document.getElementById('keluaran');
    output.src = URL.createObjectURL(event.target.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src)
    }

    var tes = document.getElementById('fileku').value;

    if (tes != null) {
      document.getElementById("btnmo").classList.remove("ilang");
    } else {
      document.getElementById("btnmo").classList.add("ilang");
    }
  };

  handleSubmit = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()
    // const scp = sessionStorage.getItem("ak")
    const tgl = this.props.match.params.tgl
    await API.post('surat/tambah/surat/emergensi/new/normal/add' + key_surat.baseURL + '&ids=' + this.state.idusers + '&idjns=' + this.state.id_jenis + '&bulan=' + this.props.match.params.bln + '&tahun=' + this.props.match.params.thn + '&ak=' + this.state.aksusers + '&hr=' + tgl, this.state)
      // .then(Response => console.log(Response))  
      .then(der =>
        this.setState({
          filedatas: der.data[0].filedatas,
          nomernya: der.data[0].nomor
        })
 
      )

    if (this.state.filedatas == 1) {
      if (this.state.fileheader == null) { } else if (this.state.fileheader == "") { } else {
        const formData = new FormData();

        formData.append(
          "fileheader",
          this.state.fileheader,
          this.state.fileheader.name,
          this.state
        );

        // console.log(this.state.nomernya)

        await API.post(`surat/tambah/surat/new/normal/file/header/add${key_surat.baseURL}&uid=${this.state.idusers}&ak=${this.state.aksusers}&nosurat=${this.state.nomernya}`, formData, {
          mode: 'no-cors',
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(Response =>
            this.setState({
              vile: Response.data[0].valdasi
            }),
          )
      }

      // alert("Berhasil Membuat Surat")
      // window.location.replace('/hsuratkeluar/0')
      var loadingmamak = document.getElementById('preloader')
      loadingmamak.classList.add('loading-jon')

      var popup = document.querySelector('.popbox')
      popup.classList.add('active')
      var popup1 = document.querySelector('.popup-box')
      popup1.classList.add('active')
      
      var elmnt = document.getElementById("Lamp");
      elmnt.scrollIntoView({behavior: 'smooth'});

    } else if (this.state.filedatas == 2) {
      alert("Lampiran harus mengandung angka 0 ateu lebih besar dari 0, selain itu tidak bisa")
    } else {
      alert("Gagal Membuat Surat")
    }
  }
  okjek () {
    var x = document.querySelector(".checkjon");
    if (x.checked == true) {
      var popup2 = document.querySelector('.popbox')
      popup2.classList.remove('active')
      
      var popup3 = document.querySelector('.popup-box')
      popup3.classList.remove('active')

      window.location.replace('/hsuratkeluar/0')
    } 
    else {}

  }



  render() {

    const { sc, data, jsurat, kdj } = this.state

    const colourOptions = this.state.listuser

    const renderData = this.state.rektor.map(rektor => {
      return (
        <V_rektor v_distribusi={rektor} key={rektor.No} />
      )
    })

    if (this.state.csurat == undefined, this.state.csurat == null, this.state.csurat == "") {
      this.state.vcsurat = "...."
    } else {
      this.state.vcsurat = this.state.csurat
    }

    const logolain = (val) => {
      if (val == 1) return <div className="col-md-12">
        <div className="col-md-6">
          <div className="form-group">
            <label>Logo / header Instansi Lain</label>
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-clone"></i>
              </div>
              <input id="fileku" type="file" accept=".jpg,.jpeg,.png,.PNG" className="form-control" name="fileheader" style={{ height: '34.5px', width: '100%' }} onChange={this.tampilgambar} />

            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Contoh surat & preview file</label>
            <div className="input-group">
              <p onClick={bukaModal} className="btn btn-primary">Lihat Contoh</p> &nbsp;
            <p id="btnmo" onClick={bukaGambar} className="btn btn-success ilang">Lihat File</p>
              <div className="modalku" id="modalmu">
                <div className="modal-contentku">
                  <span className="closeku fa fa-close" onClick={tutupModal}></span>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <h4 align="center"> <b>Panduan ukuran header yang disarankan :</b> </h4>
                      <img src="../assets/images/headerku.png"
                        style={{ width: '100%', marginTop: '15px' }}>
                      </img>
                      <a href="../assets/images/header.psd"
                        className="btn btn-success fontku" style={{ marginTop: '10px', color: 'white', float: 'right', fontWeight: 'bolder' }}
                        download >download contoh header</a>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '40px' }}>
                      <h4 align="center"> <b>Contoh surat yang telah ada header :</b></h4>
                      <img src="../assets/images/csurat.jpg"
                        style={{ width: '100%', marginTop: '15px', border: '1px solid black' }}>
                      </img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalku" id="modalcu">
                <div className="modal-contentku" style={{ backgroundColor: 'transparent', border: '0px' }}>
                  <span className="closeku fa fa-close" onClick={tutupGambar}></span>
                  <br />
                  <br />
                  <img id="keluaran"
                    style={{ width: '100%' }}
                  >
                  </img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group has-error">
            <div className="input-group">
              <span className="help-block">*** Isi / Upload file Header Jika Dibutuhkan <b>(.jpg)</b></span>

            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group has-error">
            <div className="input-group">
              <span className="help-block">*** Klik tombol "Lihat Contoh Header" untuk ketentuan dan contoh</span>

            </div>
          </div>
        </div>
      </div>
      else return ''

    }

    const lamp = (val) => {
      if (val == 0) return <div>
        <input type="hidden" name='isi_lamp' defaultValue="-" required />
      </div>
      else return <div className="col-md-6">
        <div className="form-group">
          <label>Form Isian Lampiran Surat</label>
          <div className="input-group">
            <div className="input-group-addon">
              <i className="fa fa-envelope"></i>
            </div>
            <CKEditor
              name='isi_lamp'
              data={this.state.isi_lamp}
              onChange={this.handleChange3}
            />
            {/* <textarea id="editor1" name="Tembusan" onChange={this.handleInputChange} rows="10" cols="80" required></textarea> */}
          </div>
        </div>
      </div>

    }


    const jttd2 = (val) => {
      if (val == 1) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <select name="ttd2" className="form-control" onChange={this.rekttd2} required>
              <option value="">--Yang Bertandatangan--</option>
              <option value=""></option>
              {renderData}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn2" defaultValue={this.state.jbtn2} className="form-control" onChange={this.ck} placeholder="Jabatan" disabled readOnly required></input>
          </div>
        </div>
      </div >
      else if (val == 2) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="jbtn2" defaultValue='' className="form-control" onChange={this.ck} placeholder="Jabatan" required></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="nidn2" className="form-control" onChange={this.ck} placeholder="NIDN Jika Ada" ></input>
          </div>
        </div>
      </div>
      // else if (val == null) return <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
      else return <div className="col-md-4">
        <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
        <input type="hidden" name="jbtn2" className="form-control" onChange={this.ck} placeholder="Jabatan"></input>
      </div>
    }

    const jttd3 = (val) => {
      if (val == 1) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <select name="ttd3" className="form-control" onChange={this.rekttd3} required>
              <option value="">--Yang Bertandatangan--</option>
              <option value=""></option>
              {renderData}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn3" defaultValue={this.state.jbtn3} className="form-control" onChange={this.ck} placeholder="Jabatan" disabled readOnly required></input>
          </div>
        </div>
      </div >
      else if (val == 2) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="ttd3" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="jbtn3" defaultValue='' className="form-control" onChange={this.ck} placeholder="Jabatan" required></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="nidn3" className="form-control" onChange={this.ck} placeholder="NIDN Jika Ada"></input>
          </div>
        </div>
      </div>
      // else if (val == null) return <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
      else return <div className="col-md-4">
        <input type="hidden" defaultValue="-" name="ttd3" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
        <input type="hidden" name="jbtn3" className="form-control" onChange={this.ck} placeholder="Jabatan"></input>
      </div>
    }

    const jttd4 = (val) => {
      if (val == 1) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <select name="ttd4" className="form-control" onChange={this.rekttd4} required>
              <option value="">--Yang Bertandatangan--</option>
              <option value=""></option>
              {renderData}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn4" defaultValue={this.state.jbtn4} className="form-control" onChange={this.ck} placeholder="Jabatan" disabled readOnly required></input>
          </div>
        </div>
      </div >
      else if (val == 2) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="ttd4" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="jbtn4" defaultValue='' className="form-control" onChange={this.ck} placeholder="Jabatan" required></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="nidn4" className="form-control" onChange={this.ck} placeholder="NIDN Jika Ada"></input>
          </div>
        </div>
      </div>
      // else if (val == null) return <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
      else return <div className="col-md-4">
        <input type="hidden" defaultValue="-" name="ttd4" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
        <input type="hidden" name="jbtn4" className="form-control" onChange={this.ck} placeholder="Jabatan"></input>
      </div>
    }

    function bukaModal() {
      document.getElementById("modalmu").classList.remove("modalku");
      document.getElementById("modalmu").classList.add("muncul");

    }

    function tutupModal() {
      document.getElementById("modalmu").classList.remove("muncul");
      document.getElementById("modalmu").classList.add("modalku");
    }

    function bukaGambar() {
      document.getElementById("modalcu").classList.remove("modalku");
      document.getElementById("modalcu").classList.add("muncul");
    }

    function tutupGambar() {
      document.getElementById("modalcu").classList.remove("muncul");
      document.getElementById("modalcu").classList.add("modalku");
    }

    const customStyles = {
      menu: (base) => ({
        ...base,
        zIndex: '3000',
      }),
    };

    const inexsinput = (val) => {
      if (val == 1) return <div className="form-group" id="selectku">
        <label>Tujuan Internal</label>
        <div className="input-group">
          <div className="input-group-addon">
            <i className="fa fa-institution"></i>
          </div>
          <Select
            defaultValue={this.state.usertag}
            isMulti
            name="usertag"
            options={this.state.listuser}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            required
            classNamePrefix="select"
            onChange={this.hc}
          />
        </div>
      </div>
      if (val == 2) return <div className="form-group" id="selectku">
        <label>Tujuan External</label>
        <div className="input-group">
          <div className="input-group-addon">
            <i className="fa fa-institution"></i>
          </div>
          <input type="text" name="tujuan" className="form-control" onChange={this.tjn} placeholder="Divisi / Bagian / Entitas Luar" required></input>
        </div>
      </div>
      else return ''

    }

    const hitnm = (val) => {
      if (val == "hitfalse") return <div className="col-md-12">
        <div className="col-md-2">
          <div className="form-group">
            <label></label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group" align="center">
            <label>Opsi TTD</label>
          </div>
        </div>
        <div className="col-md-8">
          <div className="col-md-4">
            <div className="form-group" align="center">
              <label>Nama TTD</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group" align="center">
              <label>Jabatan TTD</label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group" align="center">
              <label>NIDN TTD</label>
            </div>
          </div>
        </div>

      </div>
      else return ''
    }

    const hit2 = (val) => {
      if (val == "hitfalse") return <div className="col-md-12" >
        <div className="col-md-2">
          <div className="form-group">
            <label>Tandatangan Ke 2</label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select name="x2" className="form-control" onChange={this.ckx2} >
              <option value="">--Kategori--</option>
              <option value="1">Internal</option>
              <option value="2">External</option>
            </select>
          </div>
        </div>

        {jttd2(this.state.x2)}

      </div>
      else return ''
    }

    const hit3 = (val) => {
      if (val == "hitfalse") return <div className="col-md-12" >
        <div className="col-md-2">
          <div className="form-group">
            <label>Tandatangan Ke 3</label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select name="x3" className="form-control" onChange={this.ckx3} >
              <option value="">--Kategori--</option>
              <option value="1">Internal</option>
              <option value="2">External</option>
            </select>
          </div>
        </div>
        {jttd3(this.state.x3)}
      </div>
      else return ''
    }

    const hit4 = (val) => {
      if (val == "hitfalse") return <div className="col-md-12">
        <div className="col-md-2">
          <div className="form-group">
            <label>Tandatangan Ke 4 </label>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select name="x4" className="form-control" onChange={this.ckx4} >
              <option value="">--Kategori--</option>
              <option value="1">Internal</option>
              <option value="2">External</option>
            </select>
          </div>
        </div>
        {jttd4(this.state.x4)}
      </div>
      else return ''
    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">

        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon" onChange={this.okjek}></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          <section className="content-header">
            <h1>
              Buat Surat Emergensi ({this.state.jsurat})
            </h1>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Isian Surat Emergensi ({this.state.jsurat})</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  <div className="box-body">
                    {/* <form action={`${config.baseURL}surat/tambah/surat/emergensi/new/normal/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=${sessionStorage.getItem("sid")}&idjns=${this.state.id_jenis}&bulan=${this.props.match.params.bln}&tahun=${this.props.match.params.thn}ak=${sessionStorage.getItem("ak")}&hr=${this.props.match.params.tgl}`} method="post" > */}
                    <form onSubmit={this.handleSubmit}>
                      <div className="col-md-12" style={{ padding: '0px' }}>
                        <div className="form-group">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Jenis Tujuan</label>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-institution"></i>
                                </div>
                                <select name="inexscontrol" required className="form-control pull-right" onChange={this.ck}>
                                  <option value="">Pilih Tujuan</option>
                                  <option value="1">Internal / Staf Kampus</option>
                                  <option value="2">External / Luar Kampus</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            {inexsinput(this.state.inexscontrol)}
                          </div>
                        </div>
                      </div>

                      {logolain(this.state.logolainjenis)}

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pembuat Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="pembuat" className="form-control" onChange={this.pmbuat} required>
                              <option value="">--Pembuat Surat--</option>
                              <option value=""></option>
                              {renderData}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tanggal</label>
                          <div className="input-group date">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input type="date" name="tgl_surat" defaultValue={this.state.tgl_surat} className="form-control pull-right" onChange={this.ck} disabled readOnly required></input>

                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Di / Alamat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-institution"></i>
                            </div>
                            <input type="text" name="alamat" defaultValue={this.state.alamat} className="form-control" onChange={this.ck} placeholder="Alamat / Di" required></input>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kategori</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select name="kategori" className="form-control" onChange={this.ck} required>
                              <option value="">--Kategori--</option>
                              <option value="Rahasia">Rahasia</option>
                              <option value="Penting">Penting</option>
                              <option value="Biasa">Biasa</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tentang / Perihal Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" name="perihal" className="form-control" placeholder="Perihal Surat" onChange={this.ck} required></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomer Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-tag"></i>
                            </div>
                            {/*renderDatafirs*/}
                            <input type="text" value={`${this.state.firscode}/${this.state.kdj}/${this.state.pembuatsurat}/${this.state.itb}/${this.state.backcode}`} readOnly name="Sent_code" className="form-control" placeholder="Generte Number" required></input>
                            {/*renderDataback*/}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lampiran / lembar</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input id="Lamp" min='0' step='0' type="number" defaultValue={this.state.Lampiran} name="Lampiran" className="form-control" placeholder="Lampiran" onChange={this.lampcange} required></input>
                          </div>
                        </div>
                        <div className="form-group has-error">
                          {/* <label>Keterangan Untuk Yang Bertandatangan</label> */}
                          <div className="input-group">
                            <span className="help-block">*** Isi Lampiran dengan angka, jika tidak ada lampiran isi angka 0</span>
                          </div>
                        </div>
                      </div>



                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Isi Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.data}
                              name='isi_surat'
                              // onChange={this.ckchange}
                              onChange={this.onEditorChange}
                            />
                            {/* <textarea id="editor1" value={this.state.sc} name="isi_surat" onChange={this.handleInputChange} rows="10" cols="80" required></textarea> */}
                            {/* <textarea id="editor1" onChange={this.handleInputChange} name="Sent_content" rows="10" cols="80" required defaultValue={this.state.sc}>
                          </textarea> */}
                          </div>
                        </div>
                      </div>

                      {lamp(this.state.Lampiran)}

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Catatan / Tembusan Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.Tembusan}
                              name="Tembusan"
                              onChange={this.handleChange2}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="form-group">
                          <label style={{ color: 'red' }}>Keterangan Untuk Tembusan</label>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Jika Surat Terdapat Tembusan, maka isi kolom dengan number format</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Jika Surat Tidak Ada Tembusan, maka Kosongi saja</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Yang Bertandatangan {this.state.ttd_jenis} Orang</label>
                          <div className="input-group">
                            Tandatangan Pembuat Surat ( [{this.state.pembuatsurat}] {this.state.narex} )
                          </div>
                        </div>
                      </div>

                      {hitnm(this.state.hddn2)}
                      {hit2(this.state.hddn2)}
                      {hit3(this.state.hddn3)}
                      {hit4(this.state.hddn4)}
                      
                      <div className="col-md-12">
                        <div className="form-group">
                          <button type="Submit" className="btn btn-primary">Buat Surat Emergensi</button> &nbsp;
                          <Link to="/suratk" className="btn btn-primary">Kembali</Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    )
  }
}
