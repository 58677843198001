import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_rektor from './v_rektor'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'
import koneksi from '../axios/koneksi';

import makeAnimated from 'react-select/animated';
import { colourOptions } from '../data/datarjs';
import Select from 'react-select';
import koneksi_astor from '../axios/koneksi_astor';

export default class esurat extends Component {


  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      filedatas: '',
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: '',
      bulantahun: 'IV/2020',
      // backcode: [],
      // firscode: [],
      kakeanpolah: '',
      jsurat: [],
      rektor: [],
      csurat: [],
      vcsurat: '',
      sc: '',
      kdj: '',
      ket_nttd: '',

      id_jenis: '',
      Lampiran: '',
      isi_lamp: '',
      isi_surat: '',
      alamat: '',

      data: '',
      Tembusan: '',

      tgl_surat: '',
      pembuat: '',
      ttd_jenis: '',
      posisittd_jenis: '',

      idrektor: [],
      rektorid: '',
      rektorname: '',
      pmbwt: '0',
      ketegorisurat: '',
      halsuratkeluar: '',
      lamsuratkeluar: '',
      tujuan: '',
      Sent_code: '',
      kategori: '',
      perihal: '',
      header_suratkeluar: '',

      hddn2: '',
      hddn3: '',
      hddn4: '',

      ckttd: '',
      rwl: '',

      pmbwt2: '',
      pmbwt3: '',
      pmbwt4: '',

      cuy: '',
      jttd: [],
      inex_id2: '',
      inex_id3: '',
      inex_id4: '',

      inex_ttdid1: '',
      inex_ttdid2: '',
      inex_ttdid3: '',
      inex_ttdid4: '',
      ls: '0',
      ckload: '0',
      ckloads: '0',
      fileheader: null,
      vile: '',
      nomernya: '',

      logolainjenis: '',
      usertag: [],
      listuser: [],
      inexscontrol: '-',
      comboinorex: '',
      users: [],
      finduser: [],
      tagtagtag: [],
      idrektorss: [],
      nidn2: '',
      nidn3: '',
      nidn4: '',
      idusers: '',
      unusers: '',
      aksusers: '',
      x2: '',
      x3: '',
      x4: '',
      jbtn2: '',
      jbtn3: '',
      jbtn4: '',
      inexketkode2: '',
      inexketkode3: '',
      inexketkode4: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);

    this.handleChange2 = this.handleChange2.bind(this);
    this.onEditorChange2 = this.onEditorChange2.bind(this);

    this.handleChange3 = this.handleChange3.bind(this);
    this.onEditorChange3 = this.onEditorChange3.bind(this);
  }

  hc = usertag => {
    // if (usertag == null) {
    //   this.setState({
    //     tagtagtag: [],

    //     usertag
    //     // value: ""

    //   })

    // } else if (usertag = "") {
    //   this.setState({
    //     tagtagtag: [],

    //     usertag
    //     // value: ""

    //   })
    // } else {
    this.setState({
      tagtagtag: usertag,

      usertag
      // usertag: usertag.map(u => ({
      //   value: u.value
      // }))
    })
    // }

    // this.setState({ usertag });
    // this.setState({
    //   tujuan: "-"
    // });
    // console.log(this.state.usertag);
  };

  tjn = (ev) => {
    this.setState({
      tujuan: ev.target.value,
      usertag: ""
    })
  }

  tampilgambar = async (event) => {
    event.preventDefault()
    this.setState({ fileheader: "" })
    this.setState({ fileheader: event.target.files[0] })

    // console.log(event.target.files[0],"==>",this.state.fileheader)

    // var file = document.querySelector("#file");
    // if (/\.(jpg)$/i.test(file.files[0].name) === false) {
    //   document.getElementById('file').innerHTML = '';
    //   document.getElementById('size').innerHTML = 'Error: file yang diupload harus berformat PDF';
    //   document.getElementById('file').value = '';
    //   document.getElementById('sesuai').innerHTML = '';
    // } else {
    //   let fi = document.getElementById('file');
    //   // cek ukuran mb 
    //   if (fi.files.length > 0) {
    //     for (var i = 0; i <= fi.files.length - 1; i++) {
    //       var fsize = fi.files.item(i).size;
    //       const file = Math.round((fsize / 1024));
    //       if (file > 2048) {
    //         document.getElementById('file').value = '';
    //         document.getElementById('size').innerHTML = 'Error: file yang diupload harus kurang dari 2MB';
    //         document.getElementById('sesuai').innerHTML = '';
    //       } else {
    //         document.getElementById('size').innerHTML = '';
    //         document.getElementById('sesuai').innerHTML = 'File sudah sesuai';
    //       }
    //     }
    //   }
    // }

    var output = document.getElementById('keluaran');
    output.src = URL.createObjectURL(event.target.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src)
    }

    var tes = document.getElementById('fileku').value;

    if (tes != null) {
      document.getElementById("btnmo").classList.remove("ilang");
    } else {
      document.getElementById("btnmo").classList.add("ilang");
    }
  }

  // cange ck ===================
  onEditorChange(evt) {
    this.setState({
      data: evt.editor.getData()
    });
    // console.log(evt.editor.getData())
  }

  handleChange(changeEvent) {
    this.setState({
      data: changeEvent.target.value
    });
    // console.log()
  }

  handleChange2(evt2) {
    this.setState({
      Tembusan: evt2.editor.getData()
    });

    // console.log(evt2.editor.getData())
  }

  onEditorChange2(changeEvent2) {
    this.setState({
      Tembusan: changeEvent2.target.value
    });
    // console.log()
  }

  handleChange3(evt2) {
    this.setState({
      isi_lamp: evt2.editor.getData()
    });

    // console.log(evt2.editor.getData())
  }

  onEditorChange3(changeEvent2) {
    this.setState({
      isi_lamp: changeEvent2.target.value
    });
    // console.log()
  }

  // batas ck===================

  // ========================= ttd ==================================

  rekttd2 = async (e) => {

    this.setState({
      ttd2: e.target.value
    })

    let timer = setInterval(() => {
      if (this.state.pmbwt2 >= 1) {
        this.cnomor2()
        clearInterval(timer);
      } else {
        this.setState({ pmbwt2: this.state.pmbwt2 + 1 });
      }
    }, 500);
  }

  async cnomor2() {

    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.ttd2)

    this.setState({
      jbtn2: crek.data.rektor_id
    })
    // console.log(this.state.jbtn2)
  }

  rekttd3 = async (e) => {

    this.setState({ ttd3: e.target.value })
    let timer = setInterval(() => {
      if (this.state.pmbwt3 >= 1) {
        this.cnomor3()
        clearInterval(timer);
      } else {
        this.setState({ pmbwt3: this.state.pmbwt3 + 1 });
      }
    }, 500);
  }

  async cnomor3() {
    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.ttd3)

    this.setState({
      jbtn3: crek.data.rektor_id
    })
  }

  rekttd4 = async (e) => {

    this.setState({ ttd4: e.target.value })
    let timer = setInterval(() => {
      if (this.state.pmbwt4 >= 1) {
        this.cnomor4()
        clearInterval(timer);
      } else {
        this.setState({ pmbwt4: this.state.pmbwt4 + 1 });
      }
    }, 500);
  }

  async cnomor4() {
    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.ttd4)

    this.setState({
      jbtn4: crek.data.rektor_id
    })
  }

  // =================================================================

  handlegoyang = (e) => {
    this.setState({ Sent_code: e.target.value })
  }

  ckchange = (ck) => {
    this.setState({ sc: ck.target.value })
  }

  handleJenisSurat = (e) => {
    this.setState({ jenissurat: e.target.value })
  }

  ck = (e) => {
    this.setState({ [e.target.name]: e.target.value })

  }

  ckx2 = (e) => {
    this.setState({
      x2: e.target.value,
      inex_id2: '',
      ttd2: '',
      jbtn2: '',
      nidn2: ''
    })
    // setInterval(() => {
    //   console.log(this.state)
    //  }, 500);

  }

  ckx3 = (e) => {
    this.setState({
      x3: e.target.value,
      inex_id3: '',
      ttd3: '',
      jbtn3: '',
      nidn3: ''
    })
  }

  ckx4 = (e) => {
    this.setState({
      x4: e.target.value,
      inex_id4: '',
      ttd4: '',
      jbtn4: '',
      nidn4: ''
    })
  }

  lampcange = (e) => {
    this.setState({
      Lampiran: e.target.value
    })

  }

  handleTtdSurat = async (e) => {
    this.setState({ ttdsurat: e.target.value })
    if (e.target.value == "") {
      this.state.csurat = ""
    } else {
      await API_ASTOR.get('buat/surat/cari/rektor/on/change/c' + key_astor.baseURL + '&aks=' + e.target.value)
        .then(Response => this.setState({
          csurat: Response.data[0].rektor_id
        }))
    }

  }


  masukjos = async (event) => {
    // event.preventDefault()
    API.post('imassage/user/send/add' + key_surat, this.state)
      .then(sembarang => console.log(sembarang))

  }

  pmbuat = async (e) => {
    // console.log(e.target.value)
    this.setState({ pembuat: e.target.value })
    let timer = setInterval(() => {
      if (this.state.pmbwt >= 1) {
        this.cnomorscuy()
        clearInterval(timer);
      } else {
        this.setState({ pmbwt: this.state.pmbwt + 1 });
      }
    }, 500);


  }

  getuser(id) {
    const user = this.state.users.find(usr => usr.id === id)
    if (user) {
      return user.user_name
    }
    return ''
  }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    await API_ASTOR.get('surat/tujuan/multi/srh' + key_astor.baseURL)
      .then(
        Response => this.setState({
          users: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
        })
      )

    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);
    const id_suratkeluar = this.props.match.params.id_suratkeluar
    const rew = await API.get('surat/keluar/edit/new/normal/view' + key_surat.baseURL + '&ids=' + id_suratkeluar)

    this.setState({
      id_jenis: rew.data[0].id_jenis,
      usertag: rew.data[0].usertag,
      inexscontrol: rew.data[0].comboinorex,
      logolainjenis: rew.data[0].logolainjenis,
      jsurat: rew.data[0].ket_jenis,
      firscode: rew.data[0].no_suratkeluar,
      kdj: rew.data[0].kode_jenis,
      backcode: rew.data[0].blnthn,
      idrektor: rew.data[0].id_rektor,
      rektorid: rew.data[0].rektor_id,
      rektorname: rew.data[0].rektor_name,
      tgl_surat: rew.data[0].tgl_suratkeluar,
      tujuan: rew.data[0].tujuan_suratkeluar,
      alamat: rew.data[0].alamat_suratkeluar,
      kategori: rew.data[0].ketegori_surat,
      perihal: rew.data[0].hal_suratkeluar,
      Lampiran: rew.data[0].lam_suratkeluar,
      // data: rew.data[0].isi_suratkeluar,
      Sent_code: rew.data[0].nosurat,
      // fileheader: rew.data[0].header_suratkeluar,
      header_suratkeluar: rew.data[0].header_suratkeluar,

    })
    if (this.state.pmbwt == 0) {
      this.state.pembuat = this.state.idrektor
      this.cnomor()
    }

    ////////////////////////////////////////////////////

    // console.log(rew)

    // this.state.tagtagtag = this.state.finduser


    ////////////////////////////////////////////////////

    await API.get("date/now/view" + key_surat.baseURL)
      .then(Response => this.setState({
        tgl_surat: Response.data[0].dt
      }))

    // const id_theme = this.props.match.params.id_theme
    await API_ASTOR.get('surat/buat/rektor/tampil/all/view' + key_astor.baseURL + '&aks=' + this.state.aksusers)
      .then(Response => this.setState({
        rektor: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    await API_ASTOR.get('user/list/teg/view' + key_astor.baseURL)
      .then(Response => this.setState({
        listuser: Response.data
      }))
    // console.log(this.state.listuser)




    // ======================================================================================================
    // const idtheme = this.props.match.params.id_theme

    const req = await API.get('edit/surat/keluar/ttd/cari/v' + key_surat.baseURL + '&idsurat=' + id_suratkeluar)
    this.setState({
      ttd_jenis: req.data[0].banyak
    })


    if (this.state.ttd_jenis == 4) {
      this.setState({
        x2: rew.data[0].jttd[1].inex_kode,
        x3: rew.data[0].jttd[2].inex_kode,
        x4: rew.data[0].jttd[3].inex_kode,
        ttd2: rew.data[0].jttd[1].inex_id,
        ttd3: rew.data[0].jttd[2].inex_id,
        ttd4: rew.data[0].jttd[3].inex_id,
        jbtn2: rew.data[0].jttd[1].korek_ttdsurat,
        jbtn3: rew.data[0].jttd[2].korek_ttdsurat,
        jbtn4: rew.data[0].jttd[3].korek_ttdsurat,

        x2: rew.data[0].jttd[1].inex_kode,
        x3: rew.data[0].jttd[2].inex_kode,
        x4: rew.data[0].jttd[3].inex_kode,
        // jbtn2: rew.data[0].jttd[1].inex_ketkode,
        // jbtn3: rew.data[0].jttd[2].inex_ketkode,
        // jbtn4: rew.data[0].jttd[3].inex_ketkode,
        inexketkode2: rew.data[0].jttd[1].inex_ketkode,
        inexketkode3: rew.data[0].jttd[2].inex_ketkode,
        inexketkode4: rew.data[0].jttd[3].inex_ketkode,

        nidn2: rew.data[0].jttd[1].inex_nidn,
        nidn3: rew.data[0].jttd[2].inex_nidn,
        nidn4: rew.data[0].jttd[3].inex_nidn,

        inex_id2: rew.data[0].jttd[1].inex_nama,
        inex_id3: rew.data[0].jttd[2].inex_nama,
        inex_id4: rew.data[0].jttd[3].inex_nama,
        inex_ttdid1: rew.data[0].jttd[0].inex_ttdid,
        inex_ttdid2: rew.data[0].jttd[1].inex_ttdid,
        inex_ttdid3: rew.data[0].jttd[2].inex_ttdid,
        inex_ttdid4: rew.data[0].jttd[3].inex_ttdid

      })
      this.state.ckttd = 4
      this.state.hddn2 = "hitfalse"
      this.state.hddn3 = "hitfalse"
      this.state.hddn4 = "hitfalse"
    } else if (this.state.ttd_jenis == 3) {
      this.setState({
        x2: rew.data[0].jttd[1].inex_kode,
        x3: rew.data[0].jttd[2].inex_kode,
        x4: "",
        ttd2: rew.data[0].jttd[1].inex_id,
        ttd3: rew.data[0].jttd[2].inex_id,
        ttd4: "",
        jbtn2: rew.data[0].jttd[1].korek_ttdsurat,
        jbtn3: rew.data[0].jttd[2].korek_ttdsurat,
        jbtn4: "",

        x2: rew.data[0].jttd[1].inex_kode,
        x3: rew.data[0].jttd[2].inex_kode,
        x4: "",

        inexketkode2: rew.data[0].jttd[1].inex_ketkode,
        inexketkode3: rew.data[0].jttd[2].inex_ketkode,
        inexketkode4: '',

        inex_id2: rew.data[0].jttd[1].inex_nama,
        inex_id3: rew.data[0].jttd[2].inex_nama,
        inex_id4: "",

        nidn2: rew.data[0].jttd[1].inex_nidn,
        nidn3: rew.data[0].jttd[2].inex_nidn,
        nidn4: "",

        inex_ttdid1: rew.data[0].jttd[0].inex_ttdid,
        inex_ttdid2: rew.data[0].jttd[1].inex_ttdid,
        inex_ttdid3: rew.data[0].jttd[2].inex_ttdid,
        inex_ttdid4: ""
      })
      this.state.ckttd = 3
      this.state.hddn2 = "hitfalse"
      this.state.hddn3 = "hitfalse"
      this.state.hddn4 = "hittrue"
    } else if (this.state.ttd_jenis == 2) {
      this.setState({
        x2: rew.data[0].jttd[1].inex_kode,
        x3: "",
        x4: "",
        ttd2: rew.data[0].jttd[1].inex_id,
        ttd3: "",
        ttd4: "",
        jbtn2: rew.data[0].jttd[1].korek_ttdsurat,
        jbtn3: "",
        jbtn4: "",

        nidn2: rew.data[0].jttd[1].inex_nidn,
        nidn3: "",
        nidn4: "",

        inexketkode2: rew.data[0].jttd[1].inex_ketkode,
        inexketkode3: "",
        inexketkode4: "",

        x2: rew.data[0].jttd[1].inex_kode,
        x3: "",
        x4: "",

        inex_id2: rew.data[0].jttd[1].inex_nama,
        inex_id3: "",
        inex_id4: "",
        inex_ttdid1: rew.data[0].jttd[0].inex_ttdid,
        inex_ttdid2: rew.data[0].jttd[1].inex_ttdid,
        inex_ttdid3: "",
        inex_ttdid4: ""
      })
      this.state.ckttd = 2
      this.state.hddn2 = "hitfalse"
      this.state.hddn3 = "hittrue"
      this.state.hddn4 = "hittrue"
    } else if (this.state.ttd_jenis == 1) {
      this.setState({
        x2: "",
        x3: "",
        x4: "",
        ttd2: "",
        ttd3: "",
        ttd4: "",
        jbtn2: "",
        jbtn3: "",
        jbtn4: "",

        inexketkode2: "",
        inexketkode3: "",
        inexketkode4: "",

        x2: "",
        x3: "",
        x4: "",

        nidn2: "",
        nidn3: "",
        nidn4: "",

        inex_id2: "",
        inex_id3: "",
        inex_id4: "",
        inex_ttdid1: rew.data[0].jttd[0].inex_ttdid,
        inex_ttdid2: "",
        inex_ttdid3: "",
        inex_ttdid4: ""
      })
      this.state.ckttd = 1
      this.state.hddn2 = "hittrue"
      this.state.hddn3 = "hittrue"
      this.state.hddn4 = "hittrue"
    } else {
      this.setState({
        x2: "",
        x3: "",
        x4: "",
        ttd2: "",
        ttd3: "",
        ttd4: "",
        jbtn2: "",
        jbtn3: "",
        jbtn4: "",

        nidn2: "",
        nidn3: "",
        nidn4: "",

        inex_id2: "",
        inex_id3: "",
        inex_id4: "",
        inex_ttdid1: "",
        inex_ttdid2: "",
        inex_ttdid3: "",
        inex_ttdid4: ""
      })
      this.state.ckttd = 1
      this.state.hddn2 = "hittrue"
      this.state.hddn3 = "hittrue"
      this.state.hddn4 = "hittrue"
    }

    // jbtn2: rew.data[0].jttd[1].korek_ttdsurat,
    //   jbtn3: rew.data[0].jttd[2].korek_ttdsurat,
    //     jbtn4: rew.data[0].jttd[3].korek_ttdsurat,

    // if (rew.data[0].jttd[1].korek_ttdsurat == "Ext") {
    //   this.setState({
    //     jbtn2: rew.data[0].jttd[1].korek_ttdsurat,
    //   })
    // }

    // const Countries = this.state.usertag
    // console.log(this.state)

    // console.log(Countries)

    let timer = setInterval(() => {
      if (this.state.rwl >= 1) {
        clearInterval(timer);
      } else {
        this.setState({ rwl: this.state.rwl + 1 });
      }
    }, 500);


    let timers = setInterval(() => {

      if (this.state.ckload >= 1) {
        clearInterval(timers);
        this.onCKloaded()
        // console.log(this._CKEditor)

      } else {
        this.setState({ ckload: this.state.ckload + 1 });
      }
    }, 10);

    // ======================================================================================================

    // console.log(this.state.pembuat)
    // console.log(this.state)

    let ico = []
    const logsurat = this.state.usertag
    const eserdata = await API_ASTOR.get('/surat/user/react/select/json' + key_astor.baseURL)
    for (const lognya of logsurat) {
      const ketemuuser = eserdata.data.find(val => val.id === lognya.value)
      ico.push({ ...lognya, ...ketemuuser })
    }
    // this.state.finduser = ico
    const iiccoo = ico
    this.setState({
      tagtagtag: iiccoo
    })


    let icotujuan = []
    const pmbwt2 = this.state.idrektor
    const pembuatre = await API_ASTOR.get('/surat/user/cari/rektor/json' + key_astor.baseURL)
    for (const logidrektor of pmbwt2) {
      const ketemupembuat = pembuatre.data.find(ss => ss.id_rektor === logidrektor)
      icotujuan.push({ ...logidrektor, ...ketemupembuat })

      // console.log(logidrektor)
    }
    // this.state.finduser = ico
    const icotujuannnn = icotujuan
    // this.setState({
    //   namarektoreditujuan: icotujuannnn[0].rektor_name,
    //   koderektoredit: icotujuannnn[0].rektor_id,
    //   idrektoredit: icotujuannnn[0].id_rektor,
    // })

    // console.log("usertag =>", this.state.usertag)
    // console.log("ico =>", ico) 
    // console.log(this.state.idrektor)
    // console.log("cooookkk =>", this.state.tagtagtag)
    // console.log(this.state)

    this.cek()
    // this.timer = setInterval(() => this.cek(), 1000);
  }

  onCKloaded = async (e) => {
    const id_suratkeluar = this.props.match.params.id_suratkeluar
    const res = await API.get('surat/keluar/edit/new/normal/view' + key_surat.baseURL + '&ids=' + id_suratkeluar)
    this.setState({
      data: res.data[0].isi_suratkeluar,
      Tembusan: res.data[0].tembuasan_suratkeluar,
      dataisi_lamp: res.data[0].lampiran_suratkeluar,
    })
  }

  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }

  async cnomor() {
    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.idrektor)

    this.setState({
      pembuatsurat: "",
      narex: ""
    })


    if (this.state.pembuatsurat == "", this.state.narex == "") {
      this.setState({
        pembuatsurat: crek.data.rektor_id,
        narex: crek.data.rektor_name,
        namarektoreditujuan: crek.data.rektor_name,
        koderektoredit: crek.data.rektor_id,
        idrektoredit: crek.data.id_rektor,
      })
    } else {
      this.setState({
        pembuatsurat: crek.data.rektor_id,
        narex: crek.data.rektor_name,
        namarektoreditujuan: crek.data.rektor_name,
        koderektoredit: crek.data.rektor_id,
        idrektoredit: crek.data.id_rektor,
      })
    }

    // this.setState({
    //   pembuatsurat: crek.data.rektor_id,
    //   narex: crek.data.rektor_name
    // })

    // console.log(this.state.pembuatsurat)
  }

  async cnomorscuy() {
    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.pembuat)

    this.setState({
      pembuatsurat: "",
      narex: ""
    })



    if (this.state.pembuatsurat == "", this.state.narex == "") {
      this.setState({
        pembuatsurat: crek.data.rektor_id,
        narex: crek.data.rektor_name
      })
    } else {
      this.setState({
        pembuatsurat: crek.data.rektor_id,
        narex: crek.data.rektor_name
      })
    }

    // this.setState({
    //   pembuatsurat: crek.data.rektor_id,
    //   narex: crek.data.rektor_name
    // })

    // console.log(this.state.pembuatsurat)
  }

  savesurat() {
    if (!window.CKEDITOR.instances.editor2.getData())
      return
    // if (this.state.data==null){
    //   alert('isi tyidak boleh kosong')
    // }
  }

  handleSubmit = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()
    // const scp = sessionStorage.getItem("ak")
    const suratkeluar = this.props.match.params.id_suratkeluar
    await API.post('surat/keluar/edit/surat/new/normal/up' + key_surat.baseURL + '&ids=' + this.state.idusers + '&idjns=' + this.state.id_jenis + '&suratid=' + suratkeluar, this.state)
      // .then(Response => console.log(Response))  
      .then(der =>
        this.setState({
          filedatas: der.data[0].filedatas,
          nomernya: der.data[0].nomor
        })

      )

    // console.log(this.state.filedatas)

    if (this.state.filedatas == 1) {
      if (this.state.fileheader == null) { } else if (this.state.fileheader == "") { } else {
        const formData = new FormData();

        formData.append(
          "fileheader",
          this.state.fileheader,
          this.state.fileheader.name,
          this.state
        );

        // console.log(this.state.nomernya)

        await API.post(`surat/tambah/surat/new/normal/file/header/add${key_surat.baseURL}&uid=${this.state.idusers}&ak=${this.state.aksusers}&nosurat=${this.state.Sent_code}`, formData, {
          mode: 'no-cors',
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(Response =>
            this.setState({
              vile: Response.data[0].valdasi
            }),
          )
      }
      // alert("Berhasil Mengedit Surat")
      // console.log(this.state)
      // window.location.replace('/hsuratkeluar/0')
      // window.location.reload()

      var loadingmamak = document.getElementById('preloader')
      loadingmamak.classList.add('loading-jon')

      var popup = document.querySelector('.popbox')
      popup.classList.add('active')
      var popup1 = document.querySelector('.popup-box')
      popup1.classList.add('active')

    } else if (this.state.filedatas == 2) {
      alert("Lampiran harus mengandung angka 0 ateu lebih besar dari 0, selain itu tidak bisa")
    } else {
      alert("Gagal Membuat Surat")
    }

    // console.log(this.state.filedatas)
  }
  okjek () {
    var x = document.querySelector(".checkjon");
    if (x.checked == true) {
      var popup2 = document.querySelector('.popbox')
      popup2.classList.remove('active')
      
      var popup3 = document.querySelector('.popup-box')
      popup3.classList.remove('active')

      window.location.replace('/hsuratkeluar/0')
    } 
    else {}

  }



  tesonclick() {
    const teststate = `${this.state.pembuat}`
    console.log(teststate)
  }

  render() {

    const { sc, data, jsurat, kdj, tagtagtag } = this.state
    {
      // console.log(this.state.usertag)
    }

    const renderData = this.state.rektor.map(rektor => {
      return (
        <V_rektor v_distribusi={rektor} key={rektor.No} />
      )
    })

    const logolain = (val) => {
      if (val == 1) return <div className="col-md-12">
        <div className="col-md-6">
          <div className="form-group">
            <label>Logo / header Instansi Lain</label>
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-clone"></i>
              </div>
              <input id="fileku" type="file" accept=".jpg,.jpeg,.png,.PNG" className="form-control" name="fileheader" style={{ height: '34.5px', width: '100%' }} onChange={this.tampilgambar} />

            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Contoh surat & preview file</label>
            <div className="input-group">
              <p onClick={bukaModal} className="btn btn-primary">Lihat Contoh</p> &nbsp;
              <p id="btnmo" onClick={bukaGambar} className="btn btn-success ilang">Lihat File</p>
              <div className="modalku" id="modalmu">
                <div className="modal-contentku">
                  <span className="closeku fa fa-close" onClick={tutupModal}></span>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <h4 align="center"> <b>Panduan ukuran header yang disarankan :</b> </h4>
                      <img src="../assets/images/headerku.png"
                        style={{ width: '100%', marginTop: '15px' }}>
                      </img>
                      <a href="../assets/images/header.psd"
                        className="btn btn-success fontku" style={{ marginTop: '10px', color: 'white', float: 'right', fontWeight: 'bolder' }}
                        download >download contoh header</a>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '40px' }}>
                      <h4 align="center"> <b>Contoh surat yang telah ada header :</b></h4>
                      <img src="../assets/images/csurat.jpg"
                        style={{ width: '100%', marginTop: '15px', border: '1px solid black' }}>
                      </img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalku" id="modalcu">
                <div className="modal-contentku" style={{ backgroundColor: 'transparent', border: '0px' }}>
                  <span className="closeku fa fa-close" onClick={tutupGambar}></span>
                  <br />
                  <br />
                  <img id="keluaran" style={{ width: '100%' }}>
                  </img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group has-error">
            <div className="input-group">
              <span className="help-block">*** Isi / Upload file Header Jika Dibutuhkan <b>(.jpg)</b></span>

            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group has-error">
            <div className="input-group">
              <span className="help-block">*** Klik tombol "Lihat Contoh Header" untuk ketentuan dan contoh</span>

            </div>
          </div>
        </div>
      </div>
      else return ''

    }

    const lamp = (val) => {
      if (val == 0) return <div>
        <input type="hidden" name='isi_lamp' defaultValue={this.state.isi_lamp} required />
      </div>
      else return <div className="col-md-6">
        <div className="form-group">
          <label>Form Isian Lampiran Surat</label>
          <div className="input-group">
            <div className="input-group-addon">
              <i className="fa fa-envelope"></i>
            </div>
            <CKEditor
              name='isi_lamp'
              data={this.state.dataisi_lamp}
              ref={n => this._CKEditor = n}
              // onLoaded={this.onCKloaded}
              onChange={this.handleChange3}
            />
            {/* <textarea id="editor1" name="Tembusan" onChange={this.handleInputChange} rows="10" cols="80" required></textarea> */}
          </div>
        </div>
      </div>

    }

    const jttd2 = (val) => {
      if (val == 1) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <select name="ttd2" className="form-control" onChange={this.rekttd2} >
              <option value={this.state.ttd2}>{this.state.inex_id2}</option>
              <option value=""></option>
              {renderData}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn2" defaultValue={this.state.jbtn2} className="form-control" placeholder="Jabatan" disabled readOnly ></input>
          </div>
        </div>
      </div >
      else if (val == 2) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="inex_id2" defaultValue={this.state.inex_id2} className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" ></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn2" defaultValue={this.state.jbtn2} className="form-control" onChange={this.ck} placeholder="kode Jabatan" ></input>
            <input type="text" name="inexketkode2" defaultValue={this.state.inexketkode2} className="form-control" onChange={this.ck} placeholder="Jabatan" ></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="nidn2" defaultValue={this.state.nidn2} className="form-control" onChange={this.ck} placeholder="NIDN Jika Ada"></input>
          </div>
        </div>
      </div>
      // else if (val == null) return <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
      else return <div className="col-md-4">
        <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" ></input>
        <input type="hidden" name="jbtn2" className="form-control" onChange={this.ck} placeholder="Jabatan"></input>
      </div>
    }

    const jttd3 = (val) => {
      if (val == 1) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <select name="ttd3" className="form-control" onChange={this.rekttd3} >
              <option value={this.state.ttd3}>{this.state.inex_id3}</option>
              <option value=""></option>
              {renderData}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn3" defaultValue={this.state.jbtn3} className="form-control" onChange={this.ck} placeholder="Jabatan" disabled readOnly ></input>
          </div>
        </div>
      </div >
      else if (val == 2) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="inex_id3" defaultValue={this.state.inex_id3} className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" ></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn3" defaultValue={this.state.jbtn3} className="form-control" onChange={this.ck} placeholder="Kode Jabatan" ></input>
            <input type="text" name="inexketkode3" defaultValue={this.state.inexketkode3} className="form-control" onChange={this.ck} placeholder="Jabatan" ></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="nidn3" defaultValue={this.state.nidn3} className="form-control" onChange={this.ck} placeholder="NIDN Jika Ada"></input>
          </div>
        </div>
      </div>
      // else if (val == null) return <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
      else return <div className="col-md-4">
        <input type="hidden" defaultValue="-" name="ttd3" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" ></input>
        <input type="hidden" name="jbtn3" className="form-control" onChange={this.ck} placeholder="Jabatan"></input>
      </div>
    }

    const jttd4 = (val) => {
      if (val == 1) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <select name="ttd4" className="form-control" onChange={this.rekttd4} >
              <option value={this.state.ttd4}>{this.state.inex_id4}</option>
              <option value=""></option>
              {renderData}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn4" defaultValue={this.state.jbtn4} className="form-control" onChange={this.ck} placeholder="Jabatan" disabled readOnly ></input>
          </div>
        </div>
      </div >
      else if (val == 2) return <div className="col-md-8">
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="inex_id4" defaultValue={this.state.inex_id4} className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" ></input>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="hidden" name="jbtn4" defaultValue={this.state.jbtn4} className="form-control" onChange={this.ck} placeholder="Kode Jabatan" ></input>
            <input type="text" name="inexketkode4" defaultValue={this.state.inexketkode4} className="form-control" onChange={this.ck} placeholder="Jabatan" ></input>

          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input type="text" name="nidn4" defaultValue={this.state.nidn4} className="form-control" onChange={this.ck} placeholder="NIDN Jika Ada"></input>
          </div>
        </div>
      </div>
      // else if (val == null) return <input type="hidden" defaultValue="-" name="ttd2" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" required></input>
      else return <div className="col-md-4">
        <input type="hidden" defaultValue="-" name="ttd4" className="form-control" onChange={this.ck} placeholder="Nama Yang Bertandatangan" ></input>
        <input type="hidden" name="jbtn4" className="form-control" onChange={this.ck} placeholder="Jabatan"></input>
      </div>
    }

    const cinex = (val) => {
      if (val == 1) return 'Internal'
      else if (val == 2) return 'External'
      else return 'Opsional'
    }

    const headder = (vir) => {
      if (vir == "") return ''
      else if (vir == null) return ''
      else return <div className="col-md-12">
        <div className="form-group">
          <label>tampilan Logo / Header</label>
          <div className="input-group" align="center">
            <img src={koneksi.baseURL + this.state.header_suratkeluar} style={{ width: '60%' }} />
          </div>
        </div>
      </div>
    }

    function bukaModal() {
      document.getElementById("modalmu").classList.remove("modalku");
      document.getElementById("modalmu").classList.add("muncul");

    }

    function tutupModal() {
      document.getElementById("modalmu").classList.remove("muncul");
      document.getElementById("modalmu").classList.add("modalku");
    }

    function bukaGambar() {
      document.getElementById("modalcu").classList.remove("modalku");
      document.getElementById("modalcu").classList.add("muncul");
    }

    function tutupGambar() {
      document.getElementById("modalcu").classList.remove("muncul");
      document.getElementById("modalcu").classList.add("modalku");
    }

    const customStyles = {
      menu: (base) => ({
        ...base,
        zIndex: '3000',
      }),
    };

    const inexsinput = (val) => {
      if (val == 1) return <div className="form-group" id="selectku">
        <label>Tujuan Internal</label>
        <div className="input-group">
          <div className="input-group-addon">
            <i className="fa fa-institution"></i>
          </div>
          <Select
            // defaultValue={this.state.tagtagtag}
            // defaultValue={this.state.usertag}
            value={this.state.tagtagtag}
            isMulti
            name="usertag"
            options={this.state.listuser}
            // options={this.state.tagtagtag}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            required
            classNamePrefix="select"
            onChange={this.hc}
          />
        </div>
      </div>
      if (val == 2) return <div className="form-group" id="selectku">
        <label>Tujuan External</label>
        <div className="input-group">
          <div className="input-group-addon">
            <i className="fa fa-institution"></i>
          </div>
          <input type="text" defaultValue={this.state.tujuan} name="tujuan" className="form-control" onChange={this.tjn} placeholder="Divisi / Bagian / Entitas Luar" required></input>
        </div>
      </div>
      else return ''

    }

    const vinorex = (val) => {
      if (val == 1) return <option value={this.state.inexscontrol}>Internal / Staf Kampus</option>
      else return <option value={this.state.inexscontrol}>External / Luar Kampus</option>
    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">

        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon" onChange={this.okjek}></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          <section className="content-header">
            <h1>
              Edit Surat ({this.state.jsurat})
            </h1>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Edit Surat ({this.state.jsurat})</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  <div className="box-body" style={{ backgroundColor: '#ecf0f5' }}>
                    {/* <textarea id="editor1"></textarea> */}
                    {/* <form action={`${config.baseURL}surat/keluar/edit/surat/new/normal/up?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=${sessionStorage.getItem("sid")}&idjns=${this.state.id_jenis}&suratid=${this.props.match.params.id_suratkeluar}`} method="post" > */}
                    {/* <form onSubmit={this.cksumbit}> */}

                    <form onSubmit={this.handleSubmit}>
                      {/* <button onClick={this.tesonclick} type='button'>test</button> */}
                      <div className="col-md-12" style={{ padding: '0px' }}>
                        <div className="form-group">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Jenis Tujuan</label>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-institution"></i>
                                </div>
                                <select name="inexscontrol" required className="form-control pull-right" onChange={this.ck}>
                                  {vinorex(this.state.inexscontrol)}
                                  <option value=""></option>
                                  <option value="1">Internal / Staf Kampus</option>
                                  <option value="2">External / Luar Kampus</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            {inexsinput(this.state.inexscontrol)}
                          </div>
                        </div>
                      </div>

                      {logolain(this.state.logolainjenis)}
                      {headder(this.state.header_suratkeluar)}

                      {/* {logolain(this.state.logolainjenis)} */}

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pembuat Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="pembuat" className="form-control" onChange={this.pmbuat} required>
                              <option value={this.state.idrektoredit} >[{this.state.koderektoredit}] {this.state.namarektoreditujuan}</option>
                              <option value=""></option>
                              {renderData}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tanggal</label>
                          <div className="input-group date">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input type="date" name="tgl_surat" value={this.state.tgl_surat} className="form-control pull-right" onChange={this.ck} disabled readOnly required></input>

                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Tujuan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-institution"></i>
                            </div>
                            <input type="text" name="tujuan" defaultValue={this.state.tujuan} className="form-control" onChange={this.ck} placeholder="Divisi / Bagian / Entitas Luar" required></input>
                          </div>

                        </div>
                      </div> */}

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Di / Alamat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-institution"></i>
                            </div>
                            <input type="text" name="alamat" defaultValue={this.state.alamat} className="form-control" onChange={this.ck} placeholder="Alamat / Di" required></input>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kategori</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select name="kategori" className="form-control" onChange={this.ck} required>
                              <option value={this.state.kategori}>--{this.state.kategori}--</option>
                              <option value=""></option>
                              <option value="Rahasia">Rahasia</option>
                              <option value="Penting">Penting</option>
                              <option value="Biasa">Biasa</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tentang / Perihal Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" defaultValue={this.state.perihal} name="perihal" className="form-control" placeholder="Perihal Surat" onChange={this.ck} required></input>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomer Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-tag"></i>
                            </div>
                            {/*renderDatafirs*/}
                            <input type="text" value={`${this.state.firscode}/${this.state.kdj}/${this.state.pembuatsurat}/${this.state.itb}/${this.state.backcode}`} onChange={this.handlegoyang} readOnly name="Sent_code" className="form-control" placeholder="Generte Number" required></input>
                            {/*renderDataback*/}
                          </div>
                        </div>
                        <div className="form-group has-error">
                          {/* <label>Keterangan Untuk Yang Bertandatangan</label> */}
                          <div className="input-group">
                            <span className="help-block"></span>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Lampiran / lembar</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-sticky-note"></i>
                            </div>
                            <input type="number" min='0' step='0' defaultValue={this.state.Lampiran} name="Lampiran" className="form-control" placeholder="Lampiran" onChange={this.lampcange} required></input>
                          </div>
                        </div>
                        <div className="form-group has-error">
                          {/* <label>Keterangan Untuk Yang Bertandatangan</label> */}
                          <div className="input-group">
                            <span className="help-block">*** Isi Lampiran dengan angka, jika tidak ada lampiran isi angka 0</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Isi Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.data}
                              name='isi_surat'
                              // onChange={this.ckchange}
                              onChange={this.onEditorChange}
                            />
                            {/* <textarea id="editor1" value={this.state.sc} name="isi_surat" onChange={this.handleInputChange} rows="10" cols="80" required></textarea> */}
                            {/* <textarea id="editor1" onChange={this.handleInputChange} name="Sent_content" rows="10" cols="80" required defaultValue={this.state.sc}>
                          </textarea> */}
                          </div>
                        </div>
                      </div>

                      {lamp(this.state.Lampiran)}

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Catatan / Tembusan Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.Tembusan}
                              name="Tembusan"
                              onChange={this.handleChange2}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="form-group">
                          <label style={{ color: 'red' }}>Keterangan Untuk Tembusan</label>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Jika Surat Terdapat Tembusan, maka isi kolom dengan number format</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Jika Surat Tidak Ada Tembusan, maka Kosongi saja</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Yang Bertandatangan {this.state.ttd_jenis} Orang</label>
                          <div className="input-group">
                            Tandatangan Pembuat Surat ( [{this.state.pembuatsurat}] {this.state.narex} )
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12" id={this.state.hddn2}>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label></label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group" align="center">
                            <label>Opsi TTD</label>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="col-md-4">
                            <div className="form-group" align="center">
                              <label>Nama TTD</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group" align="center">
                              <label>Jabatan TTD</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group" align="center">
                              <label>NIDN TTD</label>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-12" id={this.state.hddn2}>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Tandatangan Ke 2</label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <select name="x2" className="form-control" onChange={this.ckx2} >
                              <option value={this.state.x2}>{cinex(this.state.x2)}</option>
                              <option value=""></option>
                              <option value="1">Internal</option>
                              <option value="2">External</option>
                            </select>
                          </div>
                        </div>

                        {jttd2(this.state.x2)}

                      </div>

                      <div className="col-md-12" id={this.state.hddn3}>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Tandatangan Ke 3</label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <select name="x3" className="form-control" onChange={this.ckx3} >
                              <option value={this.state.x3}>{cinex(this.state.x3)}</option>
                              <option value="1">Internal</option>
                              <option value="2">External</option>
                            </select>
                          </div>
                        </div>
                        {jttd3(this.state.x3)}
                      </div>

                      <div className="col-md-12" id={this.state.hddn4}>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label>Tandatangan Ke 4 </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <select name="x4" className="form-control" onChange={this.ckx4} >
                              <option value={this.state.x4}>{cinex(this.state.x4)}</option>
                              <option value="1">Internal</option>
                              <option value="2">External</option>
                            </select>
                          </div>
                        </div>
                        {jttd4(this.state.x4)}
                      </div>

                      <div className="col-md-12 text-right">
                        <div className="form-group">
                          <Link to="/hsuratkeluar/0" className="btn btn-warning">Kembali</Link> &nbsp;
                          <button type="Submit" className="btn btn-primary">Update Surat</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    )
  }
}
