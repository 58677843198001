import React, { Component } from 'react'

function v_optionbulan({ v_distribusi }) {
  //console.log(v_suratedit)
  const stype = (val) => {
    if (val == "01") return 'Januari'
    else if (val == "02") return 'Februari'
    else if (val == "03") return 'Maret'
    else if (val == "04") return 'April'
    else if (val == "05") return 'Mei'
    else if (val == "06") return 'Juni'
    else if (val == "07") return 'Juli'
    else if (val == "08") return 'Agustus'
    else if (val == "09") return 'September'
    else if (val == "10") return 'Oktober'
    else if (val == "11") return 'November'
    else if (val == "12") return 'Desember'
    else return 'Null'
  }

  return (
    <option value={v_distribusi.bulan}>{stype(v_distribusi.bulan)}</option>
  )
}

export default v_optionbulan