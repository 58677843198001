import React, { Component } from 'react'

function V_Firstnoout_msg({ v_firstnoout_msg }) {
  console.log(v_firstnoout_msg)
  //constructor() {
  //  super()
  //  this.nomersurat = 101
  //  this.jenissurat = ''
  //  this.nosuratRes = ``
  //  this.state = {
  //    nomersurat: 101,
  //    jenissurat: `....`,
  //    ttdsurat: `....`,
  //    bulantahun: 'IV/2020'
  //  }
  //}

  return (
    //<input type="text" value={`${this.state.nomersurat}/${this.state.jenissurat}/${this.state.ttdsurat}/${v_noout_msg.backoutcode}`} readOnly name="Sent_code" className="form-control" placeholder="Generte Number" required></input>
    <input type="text" value={v_firstnoout_msg.codepertama} readOnly name="Sent_code" className="form-control" placeholder="Generte Number" required></input>
  )
}

export default V_Firstnoout_msg