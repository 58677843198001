import React, { Component } from 'react'


function V_KelolaUser({ v_kelolauser }) {
  

  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='../js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {
    const cekKondisi = (val) => {
      if (val === 1) return 'diterima'
      else return 'draft'
    }
  return (
    <tr>
      <td>{v_kelolauser.No}</td>
      <td>{v_kelolauser.user_id}</td>
      <td>{v_kelolauser.user_name}</td>
      <td>{v_kelolauser.user_create}</td>
      <td>{v_kelolauser.user_update}</td>
      <td>{v_kelolauser.user_pass_def}</td>
      <td align="center"><input type="checkbox" name="check_list[]" value={v_kelolauser.user_id}></input></td>
    </tr>
  )
}

export default V_KelolaUser