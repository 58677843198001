import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import keyastor from '../axios/key_astor'
import API_ASTOR from '../axios/Api_astor'

export default class Footer extends Component {

  constructor() {
    super()
    this.state = {
      versi_user : '',
      tahun : '',
      idusers: '',
      unusers: '',
      aksusers: '',
    }
  }

  async componentDidMount() {
    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + keyastor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + keyastor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })
    const un = sessionStorage.getItem("sid")
    await API_ASTOR.get('tahun/copy/right/inview' + keyastor.baseURL + '&idus=' + this.state.idusers, this.state)
      .then(Response =>
        this.setState({
          tahun: Response.data[0].tahun,
          versi_user: Response.data[0].versi
        })
      )
      
  }

  render() {
    return (
      <div className="wrapper">
        <footer className="main-footer">
          <div className="pull-right hidden-xs" data-toogle="tooltip" title="we can do it, you can't do it"
            style={{ cursor: "pointer" }}>
              Create by Divisi Experience Expert
          </div>
          <strong>V.{this.state.versi_user}</strong> Copyright © {this.state.tahun} <strong><a href="https://asia.ac.id/" target="__blank">Institut Asia Malang</a>.</strong>
        </footer>
      </div>
    )
  }
}
