import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_ptheme from './v_ptheme'

export default class ptheme extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: `....`,
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      kakeanpolah: '',
      distribusi: []
    }
  }


  handlegoyang = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }

  handleJenisSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ ttdsurat: e.target.value })
  }


  async componentDidMount() {
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);
    // this.dataapi()

    const aks = sessionStorage.getItem("ak")
    await API.get('surat/baru/theme/ttheme/all/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + aks)
      .then(Response => this.setState({
        //this.setState({
        distribusi: Response.data
      }))

    // console.log(this.state.distribusi)
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }


  render() {
    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_ptheme v_distribusi={distribusi} key={distribusi.id_theme} />
      )
    })

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Daftar Theme Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              {/* <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <Link to="./ttheme" className="btn btn-primary">Tambah Theme</Link>
                    <Link to="./ttheme" className="btn btn-primary">Jenis Surat</Link>
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Daftar Theme Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">

                  </div>
                  <div className="box-body">
                    {renderData}
                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div >

    )
  }
}
