import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_suratk from './v_suratk'

export default class notulenview extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: `....`,
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      kakeanpolah: '',
      distribusi: []
    }
  }

  async loaddaya() {
    const aks = sessionStorage.getItem("ak")
    await API.get('surat/baru/theme/ttheme/all/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + aks)
      .then(Response => this.setState({
        //this.setState({
        distribusi: Response.data
      }))
  }

  componentDidMount() {
    this.loaddaya()
    // const script = document.createElement("script");

    // script.src = 'js/content.js';
    // script.async = true;

    // document.body.appendChild(script);

    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }


  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_suratk v_distribusi={distribusi} key={distribusi.id_jenis} />
      )
    })
    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1></h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  {/* <div className="box-header">
                    <strong>Daftar Theme Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div> */}
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* {renderData} */}
                    <div className="col-md-12">
                      Silakan Tunggu, file Notulen akan di download secara Otomatis
                      <embed src={`${config.baseURL}asset/file/surat/terima/M0820-0001.pdf`} type="application/pdf" style={{width:'100%', height:'800px'}} />
                    </div>
                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
