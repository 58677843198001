import React, { Component } from 'react'
import V_Notif_tidakada_Notulen from './V_Notif_tidakada_Notulen'
import API from '../axios/Api'



export default class Notif_tidakada_Notulen extends Component {

  state = {
    notif_tidakada_notulen: []
  }
        
  async componentDidMount() {
    await API.get("surat/notulen/view/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        notif_tidakada_notulen: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

      //console.log(this.state)
  }

  render() {

    const renderData = this.state.notif_tidakada_notulen.map(notif_tidakada_notulen => {
      return (
        <V_Notif_tidakada_Notulen v_notif_tidakada_notulen={notif_tidakada_notulen} key={notif_tidakada_notulen.Invite_code} />
      )
    })
   
    return (


            <div className="">
              {/* Content Header (Page header) */}
              <section className="content-header">
                <h1>
                  Notulen Rapat
                
                </h1>
              </section>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="col-md-14 col-sm-14 margin-bottom-30">
                      <div className="box box-success box-solid">
                        <div className="box-header">
                          <strong>Daftar Undangan Belum Ada Notulen</strong>
                          <div className="box-tools pull-right">
                          </div>
                        </div>
                        {/* /.box-header */}
                        <div className="box-body">
                          <div className="table-responsive">
                            <table id="example1" className="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>Kode Undangan</th>
                                  <th>Pengundang</th>
                                  <th>Perihal</th>
                                  <th>Tgl Dibuat</th>
                                  <th>Tgl Event</th>
                                  <th>Tujuan</th>
                                  <th>Status</th>
                                  <th>Pilihan</th>
                                </tr>
                              </thead>
                              <tbody>
                              {renderData}
                              </tbody>
                            </table>
                          </div>
                        {/* /.box-body */}
                        </div>
                        {/* /.box */}
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </section>
              {/* /.content */}
            </div>

    )
  }
}

