import React from "react";
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'

function NotFound() {
  return (
    <div>
    <Menu/>
    <Header/>
 
 <div className="content-wrapper">
    
  <section className="content-header">
    <h1>
      404 Error Page
    </h1>
  </section>
  <section className="content">
    <div className="error-page">
      <h2 className="headline text-yellow"> 404</h2>
      <div className="error-content">
        <h3><i className="fa fa-warning text-yellow" /> Oops! Page not found.</h3>
        <p>
          We could not find the page you were looking for.
          Meanwhile, you may <Link to="/" >return to Home</Link> or try using the search form.
        </p>

      </div>
    </div>
  </section>
</div>
<Footer/>
</div>
  )

}

export default NotFound;