import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'

export default class tjenis extends Component {

  constructor() {
    super()
    this.state = {

      filedatas: '',
      id_jenis: '',
      k_jenis: '',
      n_jenis: '',
      j_ttd: '',
      notulen: '',
      ttd: '',
      posisittd: '',
      isi_theme: '',
      orang : '',

      rwl: '',
      logoheader: '',
      footer_jenis: '',

      idusers: '',
      unusers:'',
      aksusers: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  // cange ck ===================
  onEditorChange(evt) {
    this.setState({
      isi_theme: evt.editor.getData()
    });

  }

  handleChange(changeEvent) {
    this.setState({
      isi_theme: changeEvent.target.value
    });
  }

  // batas ck===================

  handlegoyang = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.name]: event.target.value

    })
  }

  handleSubmit = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()
    const scp = sessionStorage.getItem("ak")
    const id_jenis = this.props.match.params.id_jenis
    await API.post('surat/theme/jenis/surat/update/up?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + this.state.aksusers + '&idj=' + id_jenis, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })

      )

    if (this.state.filedatas == 1) {
      // alert("Berhasil Update Data Jenis Surat")
      // window.location.replace('/djenis')

      var loadingmamak = document.getElementById('preloader')
      loadingmamak.classList.add('loading-jon')

      var popup = document.querySelector('.popbox')
      popup.classList.add('active')
      var popup1 = document.querySelector('.popup-box')
      popup1.classList.add('active')
    }

    else {
      alert("Gagal Menambah Data Jenis Surat")
    }

    // console.log(this.state.filedatas)

  }
  okjek () {
    var x = document.querySelector(".checkjon");
    if (x.checked == true) {
      var popup2 = document.querySelector('.popbox')
      popup2.classList.remove('active')
      
      var popup3 = document.querySelector('.popup-box')
      popup3.classList.remove('active')

      window.location.replace('/djenis')
    } 
    else {}

  }


  handleJenisSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ ttdsurat: e.target.value })
  }

  async loaddaya() {
    const aks = sessionStorage.getItem("ak")
    const id_jenis = this.props.match.params.id_jenis
    const res = await API.get('surat/theme/jenis/list/edit/v?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + this.state.aksusers + '&idj=' + id_jenis)


    this.setState({
      id_jenis: res.data[0].id_jenis,
      logoheader: res.data[0].logolainjenis,
      k_jenis: res.data[0].kode_jenis,
      n_jenis: res.data[0].ket_jenis,
      orang: res.data[0].ttd_jenis,
      isi_theme: res.data[0].theme_jenis,
      notulen: res.data[0].not_jenis,
      footer_jenis: res.data[0].footer_jenis
    })

    // console.log(this.state)
  }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    let timer = setInterval(() => {
      if (this.state.rwl >= 1) {
        clearInterval(timer);
        this.loaddaya()
      } else {
        this.setState({ rwl: this.state.rwl + 1 });
      }
    }, 500);
    
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);
    //console.log(this.state.kakeanpolah)
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }


  render() {

    const { k_jenis, n_jenis, ttd, posisittd, notulen } = this.state

    const rnotulen = (val) => {
      if (val == 1) return 'Ada Notulen'
      else return 'Tidak Ada Notulen'

    }

    const logoheaderv = (val) => {
      if (val == 1) return 'Pakai Logo / Header'
      else return 'Tidak Pakai'

    }
    const footerjns = (val) => {
      if (val == 1) return 'Pakai Nomor Halaman'
      else return 'Tidak Pakai'

    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">

        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon" onChange={this.okjek}></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Edit Jenis Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Jenis Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">


                    {/* <form action={`${config.baseURL}surat/theme/jenis/surat/update/up?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=${sessionStorage.getItem("ak")}&idj=${this.props.match.params.id_jenis}`} method="post" > */}
                    <form onSubmit={this.handleSubmit}>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kode Jenis Surat <span className="label label-warning"> ex.(S.Kep, SP, SK, ....)</span></label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="text" defaultValue={this.state.k_jenis} name="k_jenis" onChange={this.handlegoyang} className="form-control" required placeholder="Kode Jenis Surat"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nama Jenis Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" defaultValue={this.state.n_jenis} name="n_jenis" onChange={this.handlegoyang} className="form-control" required placeholder="Nama Jenis Surat"></input>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Notulen</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-sticky-note"></i>
                            </div>
                            <select className="Sent_type" name="notulen" className="form-control" onChange={this.handlegoyang} required>
                              <option value={this.state.notulen}>-- {rnotulen(this.state.notulen)} --</option>
                              <option value=''></option>
                              <option value="1">Ada Notulen</option>
                              <option value="0">Tidak Ada Notulen</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jumlah Yang Tandatangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-users"></i>
                            </div>
                            <select className="Sent_type" name="orang" className="form-control" onChange={this.handlegoyang} required>
                              <option value={this.state.orang}>-- {this.state.orang} Orang --</option>
                              <option value="1">1 Orang</option>
                              <option value="2">2 Orang</option>
                              <option value="3">3 Orang</option>
                              <option value="4">4 Orang</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Logo / header Instansi Lain</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-users"></i>
                            </div>
                            <select className="logoheader" name="logoheader" className="form-control" onChange={this.handlegoyang} required>
                              {/* <option value="">--Logo / header Instansi Lain--</option> */}
                              <option value={this.state.logoheader}>-- {logoheaderv(this.state.logoheader)} --</option>
                              
                              <option value="1">Pakai Logo / Header</option>
                              <option value="0">Tidak Pakai</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomor Halaman</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-sort-numeric-asc"></i>
                            </div>
                            <select className="footer_jenis" name="footer_jenis" className="form-control" onChange={this.handlegoyang} required>
                              <option value={this.state.footer_jenis}>-- {footerjns(this.state.footer_jenis)} --</option>
                              <option value="1">Pakai Nomor Halaman</option>
                              <option value="0">Tidak Pakai</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Isi Theme (Isi Surat)</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.isi_theme}
                              name='isi_theme'
                              // onChange={this.ckchange}
                              onChange={this.onEditorChange}
                            />
                            {/* <textarea id="editor2" onChange={this.handleJenisSurat} name="isi_theme" rows="10" cols="80" required>
                            </textarea> */}
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Yang Bertandatangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" defaultValue={this.state.ttd} name="ttd" className="form-control" placeholder="Yang Bertandatangan" onChange={this.handlegoyang} required></input>
                          </div>
                          <br />
                          <label style={{ color: 'red' }}>Keterangan Untuk Yang Bertandatangan</label>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Gunakan kode untuk mengisi yang bertandatangan</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Jika yang bertandatangan lebih dari dari 1, maka gunakan tanda titik koma (;) untuk memisah</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Misalkan lebih dari 1 ttd diisi seperti ini <b>R.0;R.1</b> tanpa spasi, dan kode yang terakhir tidak memakai tanda ;</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Misalkan 1 ttd diisi seperti ini <b>R.0</b></span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Posisi Tandatangan Sesuai Urutan (Rata Kanan, Kiri, Tengah)</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" defaultValue={this.state.posisittd} name="posisittd" className="form-control" placeholder="Posisi Tandatangan" onChange={this.handlegoyang} required></input>
                          </div>
                          <br />
                          <label style={{ color: 'red' }}>Keterangan Untuk Posisi Tandatangan</label>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Gunakan kode "L" untuk rata kiri, "C" untuk posisi tengah, dan "R" untuk rata kanan</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Jika yang bertandatangan lebih dari dari 1, maka gunakan tanda titik koma (;) untuk memisah</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Misalkan lebih dari 1 ttd diisi seperti ini <b>L;R;C</b> tanpa spasi, dan kode yang terakhir tidak memakai tanda ;</span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Misalkan 1 ttd diisi seperti ini <b>L</b></span>
                          </div>
                          <div className="input-group">
                            <span style={{ color: 'red' }}>*** Urutan sesuaikan dengan urutan tandatangan</span>
                          </div>

                        </div>
                      </div> */}

                      <div className="col-md-12">
                        <div className="form-group">
                          <button type="Submit" className="btn btn-primary">Update Jenis Surat</button>&nbsp;
                        <Link to="/djenis" className="btn btn-primary">Kembali</Link>
                        </div>
                      </div>
                    </form>


                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
