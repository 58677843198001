import React, { Component } from 'react'
import API from '../axios/Api'
import V_rektor from './v_rektor'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'
import Select from 'react-select';

export default class showqr extends Component {

  constructor() {
    super()
    this.state = {
      filedatas: '',
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      ttdsurat: '',
      bulantahun: 'IV/2020',
      // backcode: [],
      // firscode: [],
      kakeanpolah: '',
      jsurat: [],
      rektor: [],
      csurat: [],
      vcsurat: '',
      sc: '',
      kdj: '',
      ket_nttd: '',

      id_jenis: '',
      Lampiran: '',
      isi_lamp: '',
      isi_surat: '',
      alamat: '',

      data: '',
      Tembusan: '',

      tgl_surat: '',
      pembuat: '',
      ttd_jenis: '',
      posisittd_jenis: '',

      idrektor: [],
      rektorid: '',
      rektorname: '',
      pmbwt: '0',
      ketegorisurat: '',
      halsuratkeluar: '',
      lamsuratkeluar: '',
      tujuan: '',
      Sent_code: '',
      kategori: '',
      perihal: '',
      header_suratkeluar: '',

      hddn2: '',
      hddn3: '',
      hddn4: '',

      ckttd: '',
      rwl: '',

      pmbwt2: '',
      pmbwt3: '',
      pmbwt4: '',

      cuy: '',
      jttd: [],
      inex_id2: '',
      inex_id3: '',
      inex_id4: '',

      inex_ttdid1: '',
      inex_ttdid2: '',
      inex_ttdid3: '',
      inex_ttdid4: '',
      ls: '0',
      ckload: '0',
      ckloads: '0',
      fileheader: null,
      vile: '',
      nomernya: '',

      logolainjenis: '',
      usertag: [],
      listuser: [],
      inexscontrol: '-',
      comboinorex: '',
      users: [],
      finduser: [],
      tagtagtag: [],
      idrektorss: [],
      nidn2: '',
      nidn3: '',
      nidn4: '',
      idusers: '',
      unusers: '',
      aksusers: '',
      x2: '',
      x3: '',
      x4: '',
      jbtn2: '',
      jbtn3: '',
      jbtn4: '',
      inexketkode2: '',
      inexketkode3: '',
      inexketkode4: '',
    };
  }

  handleChange(changeEvent) {
    this.setState({
      data: changeEvent.target.value
    });
  }

  getuser(id) {
    const user = this.state.users.find(usr => usr.id === id)
    if (user) {
      return user.user_name
    }
    return ''
  }

  async componentDidMount() {
    await API_ASTOR.get('surat/tujuan/multi/srh' + key_astor.baseURL)
      .then(
        Response => this.setState({
          users: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
        })
      )

    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);
    const id_suratkeluar = this.props.match.params.id_suratkeluar
    const rew = await API.get('surat/keluar/edit/new/normal/view' + key_surat.baseURL + '&ids=' + id_suratkeluar)

    this.setState({
      id_jenis: rew.data[0].id_jenis,
      usertag: rew.data[0].usertag,
      inexscontrol: rew.data[0].comboinorex,
      logolainjenis: rew.data[0].logolainjenis,
      jsurat: rew.data[0].ket_jenis,
      firscode: rew.data[0].no_suratkeluar,
      kdj: rew.data[0].kode_jenis,
      backcode: rew.data[0].blnthn,
      idrektor: rew.data[0].id_rektor,
      rektorid: rew.data[0].rektor_id,
      rektorname: rew.data[0].rektor_name,
      tgl_surat: rew.data[0].tgl_suratkeluar,
      tujuan: rew.data[0].tujuan_suratkeluar,
      alamat: rew.data[0].alamat_suratkeluar,
      kategori: rew.data[0].ketegori_surat,
      perihal: rew.data[0].hal_suratkeluar,
      Lampiran: rew.data[0].lam_suratkeluar,
      // data: rew.data[0].isi_suratkeluar,
      Sent_code: rew.data[0].nosurat,
      // fileheader: rew.data[0].header_suratkeluar,
      header_suratkeluar: rew.data[0].header_suratkeluar,
    })

    if (this.state.pmbwt == 0) {
      this.state.pembuat = this.state.idrektor
      this.cnomor()
    }

    await API.get("date/now/view" + key_surat.baseURL)
      .then(Response => this.setState({
        tgl_surat: Response.data[0].dt
      }))

    await API_ASTOR.get('surat/buat/rektor/tampil/all/view' + key_astor.baseURL + '&aks=' + this.state.aksusers)
      .then(Response => this.setState({
        rektor: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    await API_ASTOR.get('user/list/teg/view' + key_astor.baseURL)
      .then(Response => this.setState({
        listuser: Response.data
      }))

    const req = await API.get('edit/surat/keluar/ttd/cari/v' + key_surat.baseURL + '&idsurat=' + id_suratkeluar)
    this.setState({
      ttd_jenis: req.data[0].banyak
    })


    let ico = []
    const logsurat = this.state.usertag
    const eserdata = await API_ASTOR.get('/surat/user/react/select/json' + key_astor.baseURL)
    for (const lognya of logsurat) {
      const ketemuuser = eserdata.data.find(val => val.id === lognya.value)
      ico.push({ ...lognya, ...ketemuuser })
    }
    // this.state.finduser = ico
    const iiccoo = ico
    this.setState({
      tagtagtag: iiccoo
    })

    let icotujuan = []
    const pmbwt2 = this.state.idrektor
    const pembuatre = await API_ASTOR.get('/surat/user/cari/rektor/json' + key_astor.baseURL)
    for (const logidrektor of pmbwt2) {
      const ketemupembuat = pembuatre.data.find(ss => ss.id_rektor === logidrektor)
      icotujuan.push({ ...logidrektor, ...ketemupembuat })
    }
  }




  async cnomor() {
    const crek = await API_ASTOR.get('surat/cari/kode/rektor/view' + key_astor.baseURL + '&idpembuat=' + this.state.idrektor)

    this.setState({
      pembuatsurat: "",
      narex: ""
    })


    if (this.state.pembuatsurat == "", this.state.narex == "") {
      this.setState({
        pembuatsurat: crek.data.rektor_id,
        narex: crek.data.rektor_name,
        namarektoreditujuan: crek.data.rektor_name,
        koderektoredit: crek.data.rektor_id,
        idrektoredit: crek.data.id_rektor,
      })
    } else {
      this.setState({
        pembuatsurat: crek.data.rektor_id,
        narex: crek.data.rektor_name,
        namarektoreditujuan: crek.data.rektor_name,
        koderektoredit: crek.data.rektor_id,
        idrektoredit: crek.data.id_rektor,
      })
    }

  }

  render() {

    const renderData = this.state.rektor.map(rektor => {
      return (
        <V_rektor v_distribusi={rektor} key={rektor.No} />
      )
    })

    const customStyles = {
      menu: (base) => ({
        ...base,
        zIndex: '3000',
      }),
    };

    const inexsinput = (val) => {
      if (val == 1) return <div className="form-group" id="selectku">
        <label>Tujuan Internal</label>
        <div className="input-group">
          <div className="input-group-addon">
            <i className="fa fa-institution"></i>
          </div>
          <Select
            value={this.state.tagtagtag}
            isMulti
            name="usertag"
            options={this.state.listuser}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            isDisabled={true}
          />
        </div>
      </div>
      if (val == 2) return <div className="form-group" id="selectku">
        <label>Tujuan External</label>
        <div className="input-group">
          <div className="input-group-addon">
            <i className="fa fa-institution"></i>
          </div>
          <input type="text" defaultValue={this.state.tujuan} name="tujuan" className="form-control" onChange={this.tjn} placeholder="Divisi / Bagian / Entitas Luar" required disabled></input>
        </div>
      </div>
      else return ''

    }

    const vinorex = (val) => {
      if (val == 1) return <option value={this.state.inexscontrol}>Internal / Staf Kampus</option>
      else return <option value={this.state.inexscontrol}>External / Luar Kampus</option>
    }
    return (
      <div className="content">
        <section className="content-header">
          <h1>
            Digitalisasi Surat
          </h1>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-12 col-sm-12 margin-bottom-30">
              <div className="box box-primary box-solid">
                <div className="box-header">
                  <strong>{this.state.jsurat}</strong>
                  <div className="box-tools pull-right">
                  </div>
                </div>
                <div className="box-body" style={{ backgroundColor: '#ecf0f5' }}>
                  <form onSubmit="">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <div className="form-group">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Jenis Tujuan</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-institution"></i>
                              </div>
                              <select name="inexscontrol" required className="form-control pull-right" disabled>
                                {vinorex(this.state.inexscontrol)}
                                <option value=""></option>
                                <option value="1">Internal / Staf Kampus</option>
                                <option value="2">External / Luar Kampus</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {inexsinput(this.state.inexscontrol)}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Pembuat Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-user"></i>
                          </div>
                          <select name="pembuat" className="form-control" required disabled>
                            <option value={this.state.idrektoredit}>[{this.state.koderektoredit}] {this.state.namarektoreditujuan}</option>
                            <option value=""></option>
                            {renderData}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tanggal</label>
                        <div className="input-group date">
                          <div className="input-group-addon">
                            <i className="fa fa-calendar"></i>
                          </div>
                          <input type="date" name="tgl_surat" value={this.state.tgl_surat} className="form-control pull-right" disabled required ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Di / Alamat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-institution"></i>
                          </div>
                          <input type="text" name="alamat" defaultValue={this.state.alamat} className="form-control" placeholder="Alamat / Di" required disabled></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Kategori</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-bars"></i>
                          </div>
                          <select name="kategori" className="form-control" required disabled>
                            <option value={this.state.kategori}>{this.state.kategori}</option>
                            <option value=""></option>
                            <option value="Rahasia">Rahasia</option>
                            <option value="Penting">Penting</option>
                            <option value="Biasa">Biasa</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tentang / Perihal Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-bookmark"></i>
                          </div>
                          <input type="text" defaultValue={this.state.perihal} name="perihal" className="form-control" placeholder="Perihal Surat" required disabled></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nomer Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-tag"></i>
                          </div>
                          <input type="text" value={`${this.state.firscode}/${this.state.kdj}/${this.state.pembuatsurat}/${this.state.itb}/${this.state.backcode}`} name="Sent_code" className="form-control" placeholder="Generte Number" required disabled></input>
                        </div>
                      </div>
                      <div className="form-group has-error">
                        <div className="input-group">
                          <span className="help-block"></span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
