import React, { Component } from 'react'

function V_Inbox_Code({ v_inbox_code }) {
  //console.log(v_inbox_code)

  return (
    <input type="text" readOnly defaultValue={v_inbox_code.incode} name="Recieve_code" className="form-control" placeholder="Generate Kode Surat" required></input>
    /*<td>{v_inbox_code.incode} </td>*/
  )
}

export default V_Inbox_Code