import React, { Component } from 'react'
import API from '../axios/Api'
import V_Kuasa from './V_Kuasa'
import Printer, { print } from 'react-pdf-print'
import ReactHtmlParser from 'react-html-parser';

const ids = ['1']
export default class Kuasa extends Component {

    state = {
        pdf: [],
        data: [],
        voption: [],
        voptionb: [],
        sent_id: '',
        Sent_type: '',
        Sent_creator: '',
        Sentcreator: '',
        Sent_groub: '',
        Sent_to: '',
        Sent_category: '',
        Sent_date: '',
        Sent_about: '',
        Sent_code: '',
        Sent_content: '',
        Sent_dcreator: ''
    }

    async componentDidMount() {
        // await API.get("read_sent.php")
        //     .then(Response => this.setState({
        //         pdf: Response.data
        //     }))

        // console.log(this.state)

        const sentid = this.props.match.params.sent_id
        const res = await API.get('surat/keluar/report/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idr=' + sentid)
        this.setState({
            sent_id: res.data[0].sent_id,
            Sent_code: res.data[0].sent_code,
            Sent_type: res.data[0].sent_type,
            Sent_creator: res.data[0].sent_creator,
            Sent_dcreator: res.data[0].sent_dcreator,
            Sent_groub: res.data[0].sent_groub,
            Sent_category: res.data[0].sent_category,
            Sent_date: res.data[0].sentdate,
            Sent_about: res.data[0].sent_about,
            Sent_content: res.data[0].sent_content,
            Sent_to: res.data[0].sent_to,
            Sent_nidn: res.data[0].sent_nidn
        })
    }


    render() {

        // const renderData = this.state.pdf.map(pdf => {
        //     return (
        //         <V_Kuasa v_pdf={pdf} key={pdf.Sent_code} />
        //     )
        // })
        const rep = (val) => {
            if (val === "R.0") return 'Rektor'
            else if (val === "R.1") return 'Wakil Rektor 1'
            else if (val === "R.2") return 'Wakil Rektor 2'
            else if (val === "R.3") return 'Wakil Rektor 3'
            else return 'Wakil Rektor 4'
          }

        return (

            <div>

                <button id="button" onClick={() => print(ids)} className="btn btn-primary">Download</button>


                <center>
                    {/* paten */}
                    <Printer>
                        <div id={ids[0]} style={{ border: '2px solid black', width: '210mm', height: '297mm', marginTop: '30px', marginRight: '20px', marginBottom: '30px', marginLeft: '30px', padding: '60px' }}>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            {/* batas paten? */}
                            <p align="right"><h5><font size="2" color="black">Malang, {this.state.Sent_date}</font></h5></p>
                            <br />
                            <p align='justify' style={{ textIndent: '0rem' }}>
                                <font size="2" color="black">
                                    <tr>
                                        <td>Nomor</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{this.state.Sent_code}</td>
                                    </tr>
                                    <tr>
                                        <td>Lampiran</td>
                                        <td>&nbsp;:</td>
                                        <td>1 (Satu) Lembar</td>
                                    </tr>
                                    <tr>
                                        <td>Perihal	</td>
                                        <td>&nbsp;:</td>
                                        <td>{this.state.Sent_about}</td>
                                    </tr>
                                    <br /><br />
                                    <tr>
                                        <td>Kepada Yth.</td>
                                    </tr>
                                    <tr>
                                        <td>{this.state.Sent_to}</td>
                                    </tr>
                                    <tr>
                                        <td>Di Tempat</td>
                                    </tr>
                                </font>
                            </p>


                            <br />

                            <p align='justify' style={{ textIndent: 'rem' }}>
                                <font size="2" color="black">
                                    Dengan Hormat,
                                </font>
                            </p>

                            <br />
                            <p align='justify' style={{ textIndent: 'rem' }}>
                                {ReactHtmlParser(this.state.Sent_content)}
                            </p>
                            <br />

                            <p align='justify' style={{ textIndent: '5rem' }}>
                                <font size="2" color="black">
                                    Demikian surat permohonan ini kami sampaikan.
                                    Atas perhatian dan kerjasamanya kami ucapkan terima kasih.
                </font>
                            </p>


                            <br />

                            <table width="100%" border="0">


                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width='%'></td>
                                    <td width='' align=''><font size="2" color="black">Hormat Kami</font><br /></td>
                                </tr>

                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width=''></td>
                                    <td width='' align=''><font size="2" color="black">{rep(this.state.Sent_creator)},</font></td>
                                </tr>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width=''></td>
                                    <td width='' align=''><font size="2" color="black">{this.state.Sent_dcreator}</font><br /></td>
                                </tr>
                                <tr>
                                    <td width='65%' align='right'></td>
                                    <td width='2%'></td>
                                    <td width='' align=''><font size="2" color="black">NIDN. {this.state.Sent_nidn}</font></td>
                                </tr>

                            </table>

                            {/* batas paten */}
                        </div>
                    </Printer>
                </center>

            </div>

        )
    }
}
