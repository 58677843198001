import React, { Component } from 'react'

function V_optionthn({ v_optionthn }) {
  //console.log(v_suratedit)
  const stype = (val) => {
    if (val == "1") return 'Januari'
    else if (val == "2") return 'Februari'
    else if (val == "3") return 'Maret'
    else if (val == "4") return 'April'
    else if (val == "5") return 'Mei'
    else if (val == "6") return 'Juni'
    else if (val == "7") return 'Juli'
    else if (val == "8") return 'Agustus'
    else if (val == "9") return 'September'
    else if (val == "10") return 'Oktober'
    else if (val == "11") return 'November'
    else if (val == "12") return 'Desember'
    else return 'Null'
  }

  return (
    <option value={v_optionthn.thn}>Tahun {v_optionthn.thn}</option>
  )
}

export default V_optionthn