import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import API from "../axios/Api";
import jsPDF from "jspdf";
import config from "../axios/koneksi";
import V_Inbox_Code from "./V_Inbox_Code";
import V_rektor from "./v_rektor";
import API_ASTOR from "../axios/Api_astor";
import key_astor from "../axios/key_astor";
import key_surat from "../axios/key";
import { CallMerge } from "@material-ui/icons";
// import key_surat

export default class TerimaSurat extends Component {
  constructor() {
    super();
    this.state = {
      inbox_code: [],
      kakeanpolah: '',
      rektor: [],
      Recieve_file: null,
      filedatas: '',
      valdasi: '',
      idusers: '',
      unusers:'',
      aksusers: ''
    }
  }
  //===================================================
  inputngelunemen = (event) => {
    event.preventDefault();
    this.setState({ Recieve_file: event.target.files[0] });
    // var reader = new FileReader();
    // var er = reader.readAsText(event.target.files[0]);

    // console.log("ini file inputngelu =>",event.target.files[0], "=> ", this.state.Recieve_file)

    var file = document.querySelector("#file");
    if (/\.(pdf)$/i.test(file.files[0].name) === false) {
      document.getElementById('file').innerHTML = '';
      document.getElementById('size').innerHTML = 'Error: file yang diupload harus berformat PDF';
      document.getElementById('file').value = '';
      document.getElementById('sesuai').innerHTML = '';
    } else {
      let fi = document.getElementById('file');
      // cek ukuran mb 
      if (fi.files.length > 0) {
        for (var i = 0; i <= fi.files.length - 1; i++) {
          var fsize = fi.files.item(i).size;
          const file = Math.round((fsize / 1024));
          if (file > 2048) {
            document.getElementById('file').value = '';
            document.getElementById('size').innerHTML = 'Error: file yang diupload harus kurang dari 2MB';
            document.getElementById('sesuai').innerHTML = '';
          } else {
            document.getElementById('size').innerHTML = '';
            document.getElementById('sesuai').innerHTML = 'File sudah sesuai';
          }
        }
      }
    }
  }

  handleSubmitsertifikat = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()
    
    const Recieve_id = this.props.match.params.Recieve_id
    await API.post("edit/surat/masuk/distribusi/up" + key_surat.baseURL + "&uid=" + this.state.idusers + "&ak=" + this.state.aksusers + "&Recieve_id=" + Recieve_id,this.state)
      .then((Response) =>
        this.setState({
          filedatas: Response.data[0].filedatas,
          nomer: Response.data[0].nomer,
        })
      )
    if (this.state.filedatas == 1) {
      if (this.state.Recieve_file == null) { } else if (this.state.Recieve_file == "") { } else {
        const formData = new FormData();

        formData.append(
          "Recieve_file",
          this.state.Recieve_file,
          this.state.Recieve_file.name,
          this.state
        );

        // console.log("ini isi formData =>", formData)

        await API.post(`edit/file/terima/surat/masuk/up${key_surat.baseURL}&uid=${this.state.idusers}&ak=${this.state.aksusers}&nosurat=${this.state.nomer}`, formData, {
            mode: "no-cors",
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data"
            }
          
        })
          .then((Response) =>
            this.setState({
              valdasi: Response.data[0].valdasi,
            }),
          )
          .catch(e => console.log(e))
      }
    }
    // alert("Berhasil Menambah Data")
    // window.location.replace('/distribusisurat')
      var loadingmamak = document.getElementById('preloader')
      loadingmamak.classList.add('loading-jon')

      var popup = document.querySelector('.popbox')
      popup.classList.add('active')
      var popup1 = document.querySelector('.popup-box')
      popup1.classList.add('active')
      
  };
  okjek () {
    var x = document.querySelector(".checkjon");
    if (x.checked == true) {
      var popup2 = document.querySelector('.popbox')
      popup2.classList.remove('active')
      
      var popup3 = document.querySelector('.popup-box')
      popup3.classList.remove('active')

      window.location.replace('/distribusisurat')
    } 
    else {}

  }
  //===================================================
  ck = (e) => {
    e.preventDefault()
    // console.log(event)
    
    // console.log(event.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    })

    // console.log("ini data => ",e.target.name)
  }

  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    const script = document.createElement("script");

    script.src = "js/content.js";
    script.async = true;

    document.body.appendChild(script);

    // const asd = sessionStorage.getItem("ak");

    const idx = this.props.match.params.Recieve_id
    await API.get("surat/masuk/edit/id" + key_surat.baseURL + "&Recieve_id=" + idx + "&aks=" + this.state.aksusers)
    .then((res) =>
      this.setState({
        Recieve_code: res.data[0].Recieve_code,
        Recieve_number: res.data[0].Recieve_number,
        Recieve_sender: res.data[0].Recieve_sender,
        Recieve_address: res.data[0].Recieve_address,
        Recieve_to: res.data[0].Recieve_to,
        Recieve_date: res.data[0].Recieve_date,
        Recieve_date_send: res.data[0].Recieve_date_send,
        Recieve_about: res.data[0].Recieve_about,
        Recieve_category: res.data[0].Recieve_category,
        rektor_name: res.data[0].rektor_name,
        rektor_id: res.data[0].rektor_id,
        filesnya: res.data[0].Recieve_file,
        // wow: res.data[0]
      })
    );
    await API_ASTOR.get('surat/buat/rektor/tampil/all/view' + key_astor.baseURL + '&aks=' + this.state.aksusers)
    .then(Response => this.setState({
      rektor: Response.data
    }))
    // console.log(this.state.rektor)
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {
    if (sessionStorage.getItem("un") == null) {
      this.props.history.push("/loadingout");
    }
  }
  validasitanggalmulai = (e) => {
    var a = document.getElementById('startdate').value;
    var b = document.getElementById('enddate').value;

    if ((Date.parse(a) > Date.parse(b))) {
      document.getElementById('invalid1').innerHTML = 'Error: Tanggal Kirim harus lebih kecil dari Tanggal Terima';
      document.getElementById("startdate").value = "";
      document.getElementById("valid1").innerHTML = "";
    }
    else if((Date.parse(a) === Date.parse(b))){
      document.getElementById('invalid1').innerHTML = '';
      document.getElementById("valid1").innerHTML = "Tanggal Sudah Sesuai";
    }
    else{
      document.getElementById('invalid1').innerHTML = '';
      document.getElementById("valid1").innerHTML = "Tanggal Sudah Sesuai";
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  validasitanggalakhir = (e) => {
    var a = document.getElementById('startdate').value;
    var b = document.getElementById('enddate').value;

    if ((Date.parse(b) < Date.parse(a))) {
      document.getElementById('invalid').innerHTML = 'Error: Tanggal Terima harus lebih besar dari Tanggal Kirim';
      document.getElementById("enddate").value = "";
      document.getElementById("valid").innerHTML = "";
    }
    else if((Date.parse(b) === Date.parse(a))){
      document.getElementById('invalid').innerHTML = '';
      document.getElementById("valid").innerHTML = "Tanggal Sudah Sesuai";
    }
    else{
      document.getElementById('invalid').innerHTML = '';
      document.getElementById("valid").innerHTML = "Tanggal Sudah Sesuai";
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const renderData = this.state.rektor.map((rektor) => {
      return <V_rektor v_distribusi={rektor} key={rektor.id} />;
    })

    const inputview = this.state.inbox_code.map((inbox_code) => {
      return <V_Inbox_Code v_inbox_code={inbox_code} key={inbox_code.id} />;
    })
    const nomer = this.state.Recieve_code

    const namaFile = (val) => {
      let str = this.state.filesnya
      if (val == "0")
        return 'file sebelumnya : undefined'
      else return 'file sebelumnya : ' + str
    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">

          <div className="loading-jon" id="preloader">
              <div className="jumper">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="popbox">
              <input type="checkbox" className="checkjon" id="checkjon" onChange={this.okjek}></input>
              <div className="popup-box">
                <div className="icon">
                  <i className="fa fa-check"></i>
                </div>
                <header>Berhasil !</header>
                <p>Klik OK untuk melanjutkan</p>
                <label for="checkjon">OK</label>
              </div>
            </div>

            <section className="content-header">
              <h1>Edit Terima Surat</h1>
            </section>
            <section className="content">
              <div className="row">
                <div className="col-md-12 col-sm-12 margin-bottom-30">
                  <div className="box box-primary box-solid">
                    <div className="box-header">
                      <strong>Form Edit Terima Surat</strong>
                      <div className="box-tools pull-right"></div>
                    </div>
                    <div className="box-body">
                      {/* <form action={`${config.baseURL}imassage/user/inbox/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&uid=${sessionStorage.getItem("sid")}&ak=${sessionStorage.getItem("ak")}`} method="post" encType="multipart/form-data"> */}
                      <form onSubmit={this.handleSubmitsertifikat}>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Kode Surat Masuk</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-lock"></i>
                              </div>
                              <input
                                disabled
                                className="form-control"
                                defaultValue={this.state.Recieve_code}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Nomer Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-tag"></i>
                              </div>
                              <input
                                type="text"
                                name="Recieve_number"
                                className="form-control"
                                onChange={this.ck}
                                placeholder="Nomer Surat"
                                required
                                defaultValue={this.state.Recieve_number}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Pengirim</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input
                                type="text"
                                name="Recieve_sender"
                                className="form-control"
                                onChange={this.ck}
                                placeholder="Pengirim"
                                required
                                defaultValue={this.state.Recieve_sender}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tujuan</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-user"></i>
                              </div>
                              <select
                                name="Recieve_to"
                                className="form-control"
                                onChange={this.ck}
                                required
                              >
                                <option defaultValue={this.state.Recieve_to} hidden>[{this.state.rektor_id}]&nbsp;{this.state.rektor_name}</option>
                                {renderData}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Alamat Pengirim</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-home"></i>
                              </div>
                              <textarea
                                type="text"
                                name="Recieve_address"
                                className="form-control"
                                onChange={this.ck}
                                placeholder="Alamat Pengirim"
                                required
                                defaultValue={this.state.Recieve_address}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tanggal Kirim</label>
                            <div className="input-group date">
                              <div className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                              </div>
                              <input
                                type="date"
                                name="Recieve_date_send"
                                className="form-control pull-right"
                                onChange={this.validasitanggalmulai}
                                id="startdate"
                                defaultValue={this.state.Recieve_date_send}
                                required
                              ></input>
                            </div>
                              <div id="invalid1" style={{ color: 'red' }}></div>
                              <div id="valid1" style={{ color: 'green' }}></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tanggal Terima</label>
                            <div className="input-group date">
                              <div className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                              </div>
                              <input
                                type="date"
                                name="Recieve_date"
                                className="form-control pull-right"
                                onChange={this.validasitanggalakhir}
                                id="enddate"
                                defaultValue={this.state.Recieve_date}
                                required
                              ></input>
                            </div>
                              <div id="invalid" style={{ color: 'red' }}></div>
                              <div id="valid" style={{ color: 'green' }}></div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Perihal Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bookmark"></i>
                              </div>
                              <input
                                type="text"
                                name="Recieve_about"
                                className="form-control"
                                onChange={this.ck}
                                placeholder="Perihal Surat"
                                required
                                defaultValue={this.state.Recieve_about}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Kategori Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bars"></i>
                              </div>
                              <select
                                name="Recieve_category"
                                className="form-control"
                                onChange={this.ck}
                                required
                              >
                                <option defaultValue={this.state.Recieve_category} hidden>{this.state.Recieve_category}</option>
                                <option value="Rahasia">Rahasia</option>
                                <option value="Penting">Penting</option>
                                <option value="Biasa">Biasa</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Digitalisasi Surat</label> &nbsp;
                            <input 
                            type="file" 
                            name="Recieve_file" 
                            id="file" 
                            accept=".pdf" 
                            placeholder="Digitalisasi Surat" 
                            onChange={this.inputngelunemen}
                            ></input>
                            
                              {/* <input type="hidden" id="valFile" defaultValue={this.state.filesnya}></input> */}
                              <p id="sesuai" style={{ color: 'green' }}></p>
                              <p id="size" style={{ color: 'red' }}></p>
                              <a href={`${config.baseURL}` + this.state.filesnya} target="_blank" id="nama">{namaFile(this.state.filesnya)}</a>
                          </div>
                        </div>

                        <div style={{ marginTop: "15px" }} className="col-md-12 text-right">
                          <div className="form-group">
                            <Link to="/distribusisurat" className="btn btn-warning">Kembali</Link> &nbsp;
                            <button className="btn btn-primary" type="submit">Simpan</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        <Footer />
      </div>
    );
  }
}
