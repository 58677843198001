import React, { Component } from 'react'

function v_opjenis({ v_opjenis }) {
  //console.log(v_suratedit)
  const stype = (val) => {
    if (val === "R.0") return '( Rektor )'
    else if (val === "R.1") return '( Wakil Rektor 1 )'
    else if (val === "R.2") return '( Wakil Rektor 2 )'
    else if (val === "R.3") return '( Wakil Rektor 3 )'
    else return '( Wakil Rektor 4 )'
  }

  return (
    <option value={v_opjenis.id_jenis}>{v_opjenis.ket_jenis}  ({v_opjenis.kode_jenis})</option>
  )
}

export default v_opjenis