import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import { Link } from 'react-router-dom'
import key from '../axios/key'

function v_djenis({ v_distribusi }) {
  const script = document.createElement('script')

  script.src = 'js/content.js'
  script.async = true

  document.body.appendChild(script)

  async function handleClick() {
    await API.get(
      'surat/theme/jenis/dellete/up' + key.baseURL + '&sid=' +
        v_distribusi.id_jenis,
    )
    //window.location.reload();
    // console.log('Tautan diklik.')
  }

  function pop() {
    var pop = window.confirm('Apakah anda yakin untuk menghapus data?')
    if (pop == true) {
      handleClick()
      // window.location.reload()
    }
  }

  function preview() {
    window.location.replace('/vtheme/' + v_distribusi.id_jenis)
  }

  async function handleClick2() {
    await API.get(
      'surat/theme/jenis/dellete/up/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' +
        v_distribusi.id_jenis,
    )
    //window.location.reload();
    console.log('Tautan diklik.')
  }

  function pop2() {
    var pop = window.confirm('Apakah anda yakin untuk Menampilkan data?')
    if (pop == true) {
      handleClick2()
      window.location.reload()
    }
  }

  const rep = (val) => {
    if (val === '0') return 'Dihapus / Hiden'
    else if (val === '1') return 'Tampil'
    else return ''
  }

  const rep2 = (val) => {
    if (val == null) return '0'
    else return v_distribusi.ket_nttd
  }

  const tm = (val) => {
    if (val == '0')
      return (
        <td>
          <button type="button" onClick={pop2} className="btn btn-danger">
            Tmpilkan
          </button>
        </td>
      )
    else if (val == '1')
      return (
        <td>
          <Link
            to={'/ejenis/' + v_distribusi.id_jenis}
            type="button"
            className="btn btn-info"
            style={{ marginRight: '4px' }}
          >
            Edit
          </Link>
          <button type="button" onClick={preview} className="btn btn-success">
            Setting & Preview Theme
          </button>{' '}
          &nbsp;
          <button
            type="button"
            onClick={pop}
            className="btn btn-danger"
            style={{ marginRight: '4px' }}
          >
            Hapus
          </button>
        </td>
      )
    else return ''
  }

  return (
    <tr>
      <td>{v_distribusi.No}</td>
      <td>{v_distribusi.kode_jenis}</td>
      <td>{v_distribusi.ket_jenis}</td>
      <td>{rep(v_distribusi.id_hidden)}</td>
      {/* {tm(v_distribusi.id_hidden)} */}
      {v_distribusi.id_hidden}
    </tr>
  )
}

export default v_djenis
