import React, { Component } from 'react'
import V_History_SuratUndangan from './V_History_SuratUndangan'
import API from '../axios/Api'


export default class History_SuratUndangan extends Component {



  state = {
    history_suratundangan: []
  }

  async componentDidMount() {
    // await API.get("read_invite.php")
    //   .then(Response => this.setState({
    //     history_suratundangan: Response.data
    //   }))

    // console.log(this.state)
  }

  render() {

    const renderData = this.state.history_suratundangan.map(history_suratundangan => {
      return (
        <V_History_SuratUndangan v_history_suratundangan={history_suratundangan} key={history_suratundangan.Invite_code} />
      )
    })

    return (
      <div className="">
        {/* <div className="">
          <div className="wrapper">

            <div className="content-wrapper"> */}
              {/* Content Header (Page header) */}

              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <section className="content-header">
                      <h1>
                       
      </h1>
                    </section>
                    <div className="col-md-14 col-sm-14 margin-bottom-30">
                      <div className="box box-success box-solid">
                        <div className="box-header">
                          <strong>History Surat dan Undangan</strong>
                          <div className="box-tools pull-right">
                          </div>
                        </div>
                        {/* /.box-header */}
                        <div className="box-body">
                          <div className="table-responsive">
                            <table id="example1" className="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>Nomer Surat / Undangan</th>
                                  <th>Pembuat</th>
                                  <th>Perihal</th>
                                  <th>Tgl Dibuat</th>
                                  <th>Tujuan</th>
                                  <th>Pilihan</th>
                                </tr>
                              </thead>
                              <tbody>
                                {renderData}
                              </tbody>
                            </table>
                          </div>
                          {/* /.box-body */}
                        </div>
                        {/* /.box */}
                      </div>
                    </div>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </section>
              {/* /.content */}
            </div>

      //     </div>
      //   </div>
      // </div>
    )
  }
}

