import React, { Component } from 'react'

function v_rektor({ v_distribusi }) {
  //console.log(v_suratedit)

  return (
    <option value={v_distribusi.id_rektor}>[{v_distribusi.rektor_id}] {v_distribusi.rektor_name}  </option>
  )
  
}

export default v_rektor