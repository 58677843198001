import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../axios/Api'


function V_Notif_tidakada_Notulen({ v_notif_tidakada_notulen }) {

  const script = document.createElement("script");

  script.src = 'js/content.js';
  script.async = true;

  document.body.appendChild(script);

  const stype = (val) => {
    if (val === "R.0") return '(Rektor)'
    else if (val === "R.1") return '(Wakil Rektor 1)'
    else if (val === "R.2") return '(Wakil Rektor 2)'
    else if (val === "R.3") return '(Wakil Rektor 3)'
    else return 'Wakil Rektor 4'
  }

  const stus = (val) => {
    if (val === "0") return 'Undangan Tanpa Notulen'
    else return 'Undangan Dengan Notulen'
  }

  const tjn = (val) => {
    if (val === "-") return v_notif_tidakada_notulen.Invite_to_ekstern
    else return v_notif_tidakada_notulen.Invite_to_intern
  }

  return (

    <tr>
      <td>{v_notif_tidakada_notulen.No}</td>
      <td>{v_notif_tidakada_notulen.Invite_code}</td>
      <td>{stype(v_notif_tidakada_notulen.Invite_creator)}  {v_notif_tidakada_notulen.Invite_nm}</td>
      <td>{v_notif_tidakada_notulen.Invite_about}</td>
      <td>{v_notif_tidakada_notulen.Invite_date}</td>
      <td>{v_notif_tidakada_notulen.Invite_date_event}</td>
      <td>{tjn(v_notif_tidakada_notulen.Invite_to_intern)}</td>
      <td><span className="label label-info">{stus(v_notif_tidakada_notulen.Invite_status)}</span></td>
      <td>
      <a href={"/editnonotulen/" + v_notif_tidakada_notulen.invite_id} className="btn btn-primary">Edit Undangan</a>&nbsp;
        <a href={"/notulenisi/" + v_notif_tidakada_notulen.invite_id} className="btn btn-primary">Isi Notulen</a>
      </td>
    </tr>


  )
}

export default V_Notif_tidakada_Notulen