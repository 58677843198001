import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'

export default class tjenis extends Component {

  constructor() {
    super()
    this.state = {

      filedatas: '',
      k_jenis: '',
      n_jenis: '',
      j_ttd: '1',
      notulen: '',
      ket_nttd: '',
      ttd: '',
      posisittd: '',
      isi_theme: '',
      orang:'',
      idjenis:'',
      logoheader: '',
      footer_jenis: '',
      idusers: '',
      unusers:'',
      aksusers: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  // cange ck ===================
  onEditorChange(evt) {
    this.setState({
      isi_theme: evt.editor.getData()
    });

    // console.log(evt.editor.getData())
  }

  handleChange(changeEvent) {
    this.setState({
      isi_theme: changeEvent.target.value
    });
  }

  // batas ck===================

  handlegoyang = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.name]: event.target.value,
      ket_nttd: this.state.j_ttd

    })
  }

  handleSubmit = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()
    // const scp = sessionStorage.getItem("ak")
    await API.post('surat/theme/jenis/surat/tambah/add' + key_surat.baseURL + '&aks=' + this.state.aksusers, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas,
          idjenis: Response.data[0].idjenis
        })

      )

    if (this.state.filedatas == 1) {
      // alert("Berhasil Menambah Data Jenis Surat")
      // window.location.replace('/vtheme/' + this.state.idjenis)
      
      var loadingmamak = document.getElementById('preloader')
      loadingmamak.classList.add('loading-jon')

      var popup = document.querySelector('.popbox')
      popup.classList.add('active')
      var popup1 = document.querySelector('.popup-box')
      popup1.classList.add('active')

    } else if (this.state.filedatas == 2) {
      alert("Kode Jenis Surat Tidak Boleh Sama, Silakan Isi Data Kembali")
    } else {
      alert("Gagal Menambah Data Jenis Surat")
    }
  }

  ckcrud() {
    if (this.state.filedatas == 1) {
      alert("Berhasil Menambah Data Jenis Surat")
      window.location.replace('/djenis')
    } else if (this.state.filedatas == 2) {
      alert("Kode Jenis Surat Tidak Boleh Sama, Silakan Isi Data Kembali")
    } else {
      alert("Gagal Menambah Data Jenis Surat")
    }
  }

  handleJenisSurat = (e) => {
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    this.setState({ ttdsurat: e.target.value })
  }



  async componentDidMount() {

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);

    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')
    }
  }

  render() {
  
    const indonesia = this.state.idjenis
    function okjek () {
      var x = document.querySelector(".checkjon");
      if (x.checked == true) {
        var popup2 = document.querySelector('.popbox')
        popup2.classList.remove('active')
        
        var popup3 = document.querySelector('.popup-box')
        popup3.classList.remove('active')

        window.location.replace('/vtheme/' + indonesia)
      } 
      else {}
    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">

        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon" onChange={okjek}></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          <section className="content-header">
            <h1>Tambah Jenis Surat</h1>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Tambah Jenis Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  <div className="box-body">
                    {/* <form action={`${config.baseURL}surat/theme/jenis/surat/tambah/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=${sessionStorage.getItem("ak")}`} method="post" > */}
                    <form onSubmit={this.handleSubmit}>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kode Jenis Surat <a style={{fontSize: '12px'}} className="label label-warning fontku"> ex.(S.Kep, SP, SK, ....)</a></label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-tag"></i>
                            </div>
                            <input type="text" name="k_jenis" onChange={this.handlegoyang} className="form-control" required placeholder="Kode Jenis Surat"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nama Jenis Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope-open-o"></i>
                            </div>
                            <input type="text" name="n_jenis" onChange={this.handlegoyang} className="form-control" required placeholder="Nama Jenis Surat"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Notulen</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-clipboard"></i>
                            </div>
                            <select className="Sent_type" name="notulen" className="form-control" onChange={this.handlegoyang} required>
                              <option value="">--Notulen Surat--</option>
                              <option value="1">Ada Notulen</option>
                              <option value="0">Tidak Ada Notulen</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jumlah Yang Tandatangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-users"></i>
                            </div>
                            <select className="Sent_type" name="orang" className="form-control" onChange={this.handlegoyang} required>
                              <option value="">--Jumlah Yang Tandatangan--</option>
                              <option value="1">1 Orang</option>
                              <option value="2">2 Orang</option>
                              <option value="3">3 Orang</option>
                              <option value="4">4 Orang</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Logo / header Instansi Lain</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-users"></i>
                            </div>
                            <select className="logoheader" name="logoheader" className="form-control" onChange={this.handlegoyang} required>
                              <option value="">--Logo / header Instansi Lain--</option>
                              <option value="1">Pakai Logo / Header</option>
                              <option value="0">Tidak Pakai</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomor Halaman</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-sort-numeric-asc"></i>
                            </div>
                            <select className="footer_jenis" name="footer_jenis" className="form-control" onChange={this.handlegoyang} required>
                              <option value="">--Tambah Nomor Halaman--</option>
                              <option value="1">Pakai Nomor Halaman</option>
                              <option value="0">Tidak Pakai</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Isi Theme (Isi Surat)</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.data}
                              name='isi_theme'
                              onChange={this.onEditorChange}
                            />
                           
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 text-right">
                        <div className="form-group">
                        <Link to="/djenis" className="btn btn-warning">Kembali</Link>&nbsp;
                          <button type="Submit" className="btn btn-primary">Tambah Jenis Surat</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>

    )
  }
}
