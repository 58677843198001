import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import key from '../axios/key'


export default class isinotulen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      objek: [],
      ckvalue: [],
      isEdit: false,
      file_suratkeluar: null,
      fiv: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(evt) {
    this.setState({
      notulen_suratkeluar: evt.editor.getData()
    });

  }

  handleChange(changeEvent) {
    this.setState({
      notulen_suratkeluar: changeEvent.target.value
    });
  }

  async componentDidMount() {
    const id_surat = this.props.match.params.id_surat
    const res = await API.get('/notulen/isi/isinotulen/tampil/v'+ key.baseURL +'&ids=' + id_surat)
    this.setState({
      no_suratkeluar: res.data[0].no_suratkeluar,
      tujuan_suratkeluar: res.data[0].tujuan_suratkeluar,
      hal_suratkeluar: res.data[0].hal_suratkeluar,
      id_suratkeluar: res.data[0].id_suratkeluar,
      notulen_suratkeluar: res.data[0].notulen_suratkeluar,
      s_suratkeluar: res.data[0].s_suratkeluar,
      notulen_suratkeluar: res.data[0].notulen_suratkeluar,
      file_suratkeluar: res.data[0].file_suratkeluar
    })
  }

  handleCkChange = (e, editor) => {
    const data = e.editor.getData();
    this.setState({
      notulen_suratkeluar: data
    })
  }

  ValidasiFile = (event) => {
    event.preventDefault()
    this.setState({ file_suratkeluar: event.target.files[0] })
    var file = document.querySelector("#file");
    if (/\.(pdf)$/i.test(file.files[0].name) === false) {
      document.getElementById('file').innerHTML = '';
      document.getElementById('size').innerHTML = 'Error: file yang diupload harus berformat PDF';
      document.getElementById('file').value = '';
      document.getElementById('sesuai').innerHTML = '';
    } else {
      let fi = document.getElementById('file');
      // cek ukuran mb 
      if (fi.files.length > 0) {
        for (var i = 0; i <= fi.files.length - 1; i++) {
          var fsize = fi.files.item(i).size;
          const file = Math.round((fsize / 1024));
          if (file > 2048) {
            document.getElementById('file').value = '';
            document.getElementById('size').innerHTML = 'Error: file yang diupload harus kurang dari 2MB';
            document.getElementById('sesuai').innerHTML = '';
          } else {
            document.getElementById('size').innerHTML = '';
            document.getElementById('sesuai').innerHTML = 'File sudah sesuai';
          }
        }
      }
    }
  }

  validasiKirim = (event) => {
    var ckq = document.getElementById('ckFile').value
    if (ckq != '-' && ckq != '') return alert('Berhasil Isi Notulen!!')
    else
      event.preventDefault();
    alert('Isi Notulen Harus Diisi')
    return false
  }

  simpannotulen = async (event) => {
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.remove('loading-jon')

    event.preventDefault()

    const formData = new FormData();

    formData.append(
      "file_suratkeluar",
      this.state.file_suratkeluar,
      this.state.file_suratkeluar.name,
      this.state
    );

    // console.log(this.state.file_suratkeluar)
    await API.post('notulen/coba/upload/isi/notulen/' + this.state.id_suratkeluar + key.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas,
          nomer: Response.data[0].nomer
        })

      )
      // console.log(this.state.filedatas,"f")
    if (this.state.filedatas == 1) {
      await API.post(`notulen/upload/file/${this.state.id_suratkeluar}${key.baseURL}`, formData, {
        mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        }

      })
        .then(Response =>
          this.setState({
            fiv: Response.data[0].fiv
          }),
        )
        // .catch(e => console.log(e))
        if (this.state.filedatas == 1) {
          // alert("Berhasil Menambah Data Notulen")
          // window.location.replace('/hsuratkeluar/0')

          var loadingmamak = document.getElementById('preloader')
          loadingmamak.classList.add('loading-jon')

          var popup = document.querySelector('.popbox')
          popup.classList.add('active')
          var popup1 = document.querySelector('.popup-box')
          popup1.classList.add('active')

          document.querySelector(".checkjon").onclick = function() {
            var popup2 = document.querySelector('.popbox')
            popup2.classList.remove('active')
            
            var popup3 = document.querySelector('.popup-box')
            popup3.classList.remove('active')
    
            window.location.replace('/hsuratkeluar/0')
  
          }
        }
        else{
          var loadingmamak = document.getElementById('preloader')
          loadingmamak.classList.add('loading-jon')

          alert("Proses Gagal, Silakan Ulangi Lagi")
          window.location.reload();
        }
      // console.log(this.state.fiv, "v")

    }
  }

  render() {

    return (
      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">

        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon"></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          <section className="content-header">
            <h1>Isi Notulen</h1>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <h1></h1>
                    <strong>Form Isi Notulen</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  <div className="box-body">
                    {/* <form method="POST" action={`${config.baseURL}notulen/upload/file/${this.state.id_suratkeluar}?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`} encType="multipart/form-data"> */}
                    <form onSubmit={this.simpannotulen}>
                      <div className="form-group-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>No Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-tag"></i>
                              </div>
                              <input name="no_suratkeluar" value={this.state.no_suratkeluar} readOnly required className="form-control" placeholder="Nomor Surat" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Hal / Perihal</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bookmark"></i>
                              </div>
                              <input name="hal_suratkeluar" value={this.state.hal_suratkeluar} readOnly required className="form-control" placeholder="Kategori" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tujuan</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-home"></i>
                              </div>
                              <input name="tujuan_suratkeluar" value={this.state.tujuan_suratkeluar} readOnly required className="form-control" placeholder="Kategori" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Upload File <sup>*daftar hadir</sup></label>

                            <input type="file" name="file_suratkeluar"
                              id="file"
                              accept=".pdf"
                              placeholder="Digitalisasi Surat"
                              onChange={this.ValidasiFile}
                              required
                            />
                            <p id="sesuai" style={{ color: 'green' }}></p>
                            <p id="size" style={{ color: 'red' }}></p>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Isi Notulen Surat</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-envelope"></i>
                              </div>
                              <CKEditor
                                data={this.state.notulen_suratkeluar}
                                name="notulen_suratkeluar"
                                onChange={this.handleCkChange}
                              />
                            </div>
                            {/* <input readOnly
                              type="hidden"
                              className="form-control" name="ayamgeprek"
                              id="ckFile"
                              value={this.state.notulen_suratkeluar}>
                            </input> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 text-right">
                        <Link to="/hsuratkeluar/0" className="btn btn-warning">Kembali</Link>
                        <button type="submit" className="btn btn-primary" style={{ marginLeft: '5px' }}>Kirim</button>&nbsp;
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    )
  }
}
