import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
import V_opjenis from './v_opjenis'

export default class ttheme extends Component {

  constructor() {
    super()

    this.state = {

      backcode: [],
      firscode: [],
      kakeanpolah: '',
      distribusi: [],
      voption: [],
      jenis: '',
      isi_theme: '',
      name_theme: ''
    }

    this.handleChange = this.handleChange.bind( this );
    this.onEditorChange = this.onEditorChange.bind( this );
  }

  // cange ck ===================
  onEditorChange( evt ) {
		this.setState( {
			isi_theme: evt.editor.getData()
    } );
    
    console.log(evt.editor.getData())
	}

	handleChange( changeEvent ) {
		this.setState( {
			isi_theme: changeEvent.target.value
		} );
  }
  
  // batas ck===================

  handlegoyang = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.name]: event.target.value

    })
  }


  handleJenisSurat = (e) => {
    this.setState({ isi_theme: e.target.value })
  }
  handleTtdSurat = (e) => {
    this.setState({ ttdsurat: e.target.value })
  }

  async componentDidMount() {
    // this.loaddaya()
    const aks = sessionStorage.getItem("ak")
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);

    await API.get('surat/theme/ttheme/jenis/theme/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + aks)
      .then(Response => this.setState({
        distribusi: Response.data
      }))
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const scp = sessionStorage.getItem("ak")
    await API.post('surat/theme/tambah/surat/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=' + scp, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })

      )
    if (this.state.filedatas == 1) {
      alert("Berhasil Menambah Theme Surat")
      window.location.replace('/ptheme')
    } else {
      alert("Gagal Menambah Data Theme Surat")
    }
  }

  render() {

    const ttj = this.state.distribusi.map(distribusi => {
      return (
        <V_opjenis v_opjenis={distribusi} key={distribusi.id_jenis} />
      )
    })

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Tambah Theme Jenis Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Tambah Theme Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">


                    {/* <form action={`${config.baseURL}surat/theme/tambah/surat/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&aks=${sessionStorage.getItem("ak")}`} method="post" > */}
                      <form onSubmit={this.handleSubmit}>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nama Theme</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <input type="text" name="name_theme" className="form-control" onChange={this.handlegoyang} placeholder="Judul Theme" required />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jenis Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select className="Sent_type" name="jenis" className="form-control" onChange={this.handlegoyang} required>
                              <option value="">--Jenis Surat--</option>
                              {ttj}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">

                        <div className="form-group">
                          <label>Isi Theme (Isi Surat)</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <CKEditor
                              data={this.state.data}
                              name='isi_theme'
                              // onChange={this.ckchange}
                              onChange={this.onEditorChange}
                            />
                            {/* <textarea id="editor2" onChange={this.handleJenisSurat} name="isi_theme" rows="10" cols="80" required>
                            </textarea> */}
                          </div>
                        </div>
                        <button type="Submit" className="btn btn-primary">Tambah Theme</button>&nbsp;
                        <Link to="/ptheme" className="btn btn-primary">Kembali</Link>
                      </div>
                    </form>


                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
