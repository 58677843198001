import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import jsPDF from 'jspdf'
import Select from 'react-select'
import config from '../axios/koneksi'


export default class Surat extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `Und`,
      ttdsurat: `....`,
      bulantahun: 'IV/2020',
      backcode: [],
      firscode: [],
      backh: ''
    }
  }

  //state = {

  //};

  handleJenisSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ ttdsurat: e.target.value })
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  cth = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ backh: e.target.value })
  }

  generatePDF = () => {
    var doc = new jsPDF('p', 'pt');

    // var Invite_code      = ('#Invite_code').val();
    // var Invite_creator    = ('#Invite_creator').val();
    // var Invite_to_intern    = ('#Invite_to_intern').val();
    // var Invite_to_ekstern   = ('#Invite_to_ekstern').val();
    // var Invite_address_ekstern       = ('#Invite_address_ekstern').val();
    // var Invite_category      = ('#Invite_category').val();
    // var Invite_date_event = ('#Invite_date_event').val();
    // var Invite_room     = ('#Invite_room').val();
    // var Invite_time     = ('#Invite_time').val();
    // var Invite_about     = ('#Invite_about').val();
    // var Invite_header_content     = ('#Invite_header_content').val();
    // var Invite_footer_content     = ('#Invite_footer_content').val();


    doc.setFont('helvetica')
    doc.text(20, 60, 'Invite_code')
    doc.text(20, 90, 'Invite_creator')
    doc.text(20, 120, 'Invite_to_intern')
    doc.text(20, 160, 'Invite_to_ekstern')
    doc.text(20, 190, 'Invite_address_ekstern')
    doc.text(20, 210, 'Invite_category')
    doc.text(20, 230, 'Invite_date_event')
    doc.text(20, 260, 'Invite_room')
    doc.text(20, 290, 'Invite_time')
    doc.text(20, 310, 'Invite_about')
    doc.text(20, 330, 'Invite_header_content')
    doc.text(20, 360, 'Invite_footer_content')


    doc.save('demo.pdf')
  }

  //handleSubmit = async (event) => {
  //  event.preventDefault()
  //  await API.post('create_invite.php', this.state)
  //  this.props.history.push('/notif')
  // const data = this.state
  // console.log("Data berhasil", data)
  //}

  handleInputChange = (event) => {
    event.preventDefault()
    console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
  }

  async componentDidMount() {
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);

    await API.get("surat/undangan/nomor/2/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        backcode: Response.data[0].backoutcode
      }))

    await API.get("surat/undangan/nomor/1/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        firscode: Response.data[0].codepertama
      }))

    //console.log(this.state, 'jdisoajdiasidjioasjdoi')
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }

  render() {
    const { selectedOption } = this.state;
    const stus = (val) => {
      if (val === "1") return <div className="col-md-6">
        <div className="form-group">
          <label>Alamat</label>
          <div className="input-group">
            <div className="input-group-addon">
              <i className="fa fa-home"></i>
            </div>
            <textarea type="text" style={{ height: '68px' }} required id="Invite_address_ekstern" name="Invite_address_ekstern" className="form-control" onChange={this.handleInputChange} placeholder="Alamat Eksternal"></textarea>

          </div>
        </div>
      </div>
      else return <div className="col-md-6">
        <div className="form-group" style={{  height: '100px' }}>
          <label></label>
          <div className="input-group">
            {/* <div className="input-group-addon">
              <i className="fa fa-home"></i>
            </div> */}
            <textarea type="hidden" style={{ display: 'none', height: '68px' }} required id="Invite_address_ekstern" name="Invite_address_ekstern" className="form-control" onChange={this.handleInputChange} placeholder="Alamat Eksternal">-</textarea>
          </div>
        </div>
      </div>
    }
    return (

      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              Permintaan Undangan
      </h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Permintaan Undangan</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form action={`${config.baseURL}imassage/user/undangan/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`} method="post" >
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kode Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-tag"></i>
                            </div>
                            <input type="text" value={`${this.state.firscode}/${this.state.jenissurat}/${this.state.ttdsurat}/${this.state.itb}/${this.state.backcode}`} readOnly name="Invite_code" className="form-control" placeholder="Generte Number" required></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pembuat Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="Invite_creator" className="form-control" required onChange={this.handleTtdSurat}>
                              <option value="">--Pembuat Undangan--</option>
                              <option value="R.0">Rektor</option>
                              <option value="R.1">WR 1</option>
                              <option value="R.2">WR 2</option>
                              <option value="R.3">WR 3</option>
                              <option value="R.4">WR 4</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tujuan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="Invite_to_intern" required id="Invite_to_intern" required className="form-control" onChange={this.cth}>
                              <option value="">--Tujuan Internal / External--</option>
                              <option value="0">Internal</option>
                              <option value="1">External</option>
                              {/* <option value="Ketua LPKD">Ketua LPKD</option>
                              <option value="Kaprodi Akuntansi">Kaprodi Akuntansi</option>
                              <option value="Wa Dek FEB">Wa Dek FEB</option>
                              <option value="HRD">HRD</option> */}
                            </select>
                            <input type="text" required id="Invite_to_ekstern" name="Invite_to_ekstern" className="form-control" onChange={this.handleInputChange} placeholder="Isi Tujuan"></input>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group">
                     <label>Tujuan Eksternal</label> 
                     <div className="input-group">
                       <div className="input-group-addon">
                        <i className="fa fa-institution"></i>
                     </div>
                       <input type="text" id="Invite_to_ekstern" name="Invite_to_ekstern" className=" " onChange={this.handleInputChange} placeholder="Tujuan Eksternal"></input>          
                     </div>
                    </div> */}


                      {stus(this.state.backh)}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kategori</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select name="Invite_category" required id="Invite_category" className="form-control" onChange={this.handleInputChange}>
                              <option value="">--Kategori--</option>
                              <option value="Rahasia">Rahasia</option>
                              <option value="Penting">Penting</option>
                              <option value="Biasa">Biasa</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tanggal Event</label>
                          <div className="input-group date">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input type="date" required id="Invite_date_event" name="Invite_date_event" className="form-control pull-right" onChange={this.handleInputChange}></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Ruangan / Tempat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-home"></i>
                            </div>
                            <input type="text" required placeholder="Ruangan / Tempat" className="form-control" name="Invite_room"></input>
                            {/* <select name="Invite_room" required id="Invite_room" className="form-control" onChange={this.handleInputChange}>
                              <option value="">--Ruang--</option>
                              <option value="Rektor (Lt.2)">Rektor (Lt.2)</option>
                              <option value="Thetre (Lt.1)">Thetre (Lt.1)</option>
                              <option value="Eksekutif (Lt.1)">Eksekutif (Lt.1)</option>
                            </select> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jam Acara</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-clock-o"></i>
                            </div>
                            <input type="time" id="Invite_time" required name="Invite_time" className="form-control" onChange={this.handleInputChange} placeholder="Jam"></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Perihal Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" id="Invite_about" required name="Invite_about" className="form-control" onChange={this.handleInputChange} placeholder="Perihal Undangan"></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Isi Awal Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <textarea id="editor1" required name="Invite_header_content" onChange={this.handleInputChange} rows="10" cols="80">
                            </textarea>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Isi Akhir Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <textarea id="editor2" required name="Invite_footer_content" onChange={this.handleInputChange} rows="10" cols="80">
                            </textarea>
                          </div>
                        </div>
                        <button className="btn btn-primary" type="submit">Simpan</button>
                      </div>

                    </form>

                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
