import React, { Component } from 'react';

class Nyoba extends Component {
    render() {
        return (
            <div id="print-area">
                <style>
                    {`@media print {
                        .asu {
                            display: none
                        }
                    }`}
                </style>
                <h1 className="asu">asu</h1>
                <h1 className="asu">asu</h1>
            </div>
        );
    }
}

export default Nyoba;