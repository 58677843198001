import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import { Link } from 'react-router-dom'
import { load } from 'recaptcha-v3'


function v_ptheme({ v_distribusi }) {


  // const script = document.createElement("script");

  // script.src = 'js/content.js';
  // script.async = true;

  // document.body.appendChild(script);

  async function handleClick() {

    await API.put('surat/theme/hapus/sungguhan/dl?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.id_theme)
    //window.location.reload();
    // console.log('Tautan diklik.');

  }

  function load() {
    // alert('Berhasil Menghapus Theme')
    window.location.reload()
  }

  function pop() {
    var pop = window.confirm("Apakah anda yakin untuk menghapus Theme Surat ini?")
    if (pop == true) {
      handleClick()
      setInterval(() => load(), 1000);
    } else {
      //window.location.reload();
    }
  }




  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {


  // console.log(config.baseURL, v_distribusi.Recieve_file)

  return (
    // <a href="" style={{cursor:'pointer'}}>
    <div className="col-md-3 col-sm-6 col-xs-12">
      <div className="info-box">
        <span className="info-box-icon bg-aqua">
          <i className="glyphicon glyphicon-list-alt"></i>
        </span>
        <div className="info-box-content">
          <span className="info-box-number">
            {v_distribusi.nama_theme}
          </span>
          <span>
            {v_distribusi.ket_jenis} ({v_distribusi.kode_jenis})
          </span><br /><br />
          <div align="right" style={{ width: '100%', paddingRight: '10px' }}>
            <span>
              <Link to={'/vtheme/' + v_distribusi.id_theme} className="glyphicon glyphicon-zoom-in" style={{ paddingRight: '10px' }}></Link>
              <a href={'/etheme/' + v_distribusi.id_theme} className="glyphicon glyphicon-pencil" style={{ paddingRight: '10px' }}></a>
              <button onClick={pop} className="glyphicon glyphicon-remove" style={{ paddingRight: '4px' }}></button>
            </span>
          </div>


          {/* <a className="glyphicon glyphicon-pencil"></a> */}
        </div>
      </div>
    </div>
    // </a>
  )

}

export default v_ptheme
