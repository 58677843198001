// import '../modules/paged.polyfill.js';

import React, { Component } from 'react'
import { Previewer } from "pagedjs";
import API from '../axios/Api'
import config from '../axios/koneksi'
import Axios from 'axios';
import V_Edaran from './V_Edaran'
import Printer, { print } from 'react-pdf-print'
import ReactHtmlParser from 'react-html-parser';
import rp from 'react-html-parser';
import CKEditor from 'ckeditor4-react';
import pdfjsLib from "pdfjs-dist/build/pdf";
import { fireEvent } from '@testing-library/react';
import koneksi_astor from '../axios/koneksi_astor';
import key_astor from '../axios/key_astor';
import key_surat from '../axios/key'
// import GET from '../pages/la';
import API_ASTOR from '../axios/Api_astor'
// import API_Astor from '../axios/Api_astor'
// import key_astor from '../axios/key_astor'


// const ids = ['1']

export default class laporan extends Component {

    constructor() {
        super()
        this.state = {
            kode: '',
            data: '',
            idusers: '',
            unusers: '',
            aksusers: '',
            casedata: 0
        }
    }

    async componentDidMount() {
        const user = sessionStorage.getItem("sid");
        const ak = sessionStorage.getItem("ak");
        const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
        const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
        this.setState({
            idusers: sstate.data[0].id,
            unusers: sstate.data[0].user_id,
            aksusers: aksses.data[0].level_id,
        })
        this.datalaporan()

        // console.log(sstate)
        this.timer = setInterval(() => this.cek(), 1000)


    }

    async datalaporan() {
        const mystyle = {
            // iframe{
            height: '100vh',
            width: '100%',
            margin: '0px',

            bottom: '0',
            overflowY: 'hidden',
            // }
        };
        const kodesurat = this.props.match.params.nomersurat
        const idpengguna = sessionStorage.getItem("sid")
        const akpengguna = sessionStorage.getItem("ak")
        await API.get('pdf/surat/terimasurat/t/v' + key_surat.baseURL + '&nosurat=' + kodesurat + '&idpengguna=' + this.state.idusers + '&akpengguna=' + this.state.aksusers)
            .then(Response =>
                this.setState({
                    data: Response.data[0].fileterimasurat
                })
            )

        // .catch(Response => )
        // console.log(this.state)

        // .getElementById('tampilfile').innerHTML = `<iframe style=${mystyle} src="${config.baseURL}${this.state.data}${key_surat.baseURL}" />`

        // console.log(kodesurat)

        // const sentid = this.props.match.params.id_suratkeluar
        // const cuk = await API.get('surat/keluar/preview/print/pdf?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&ids=' + sentid)
        // this.setState({
        //     resdata: cuk.data[0].resdata,
        //     filedatas: cuk.data[0].filedatas
        // })

        if (this.state.data == null) return false
        else if (this.state.data == undefined) return false
        else return this.setState({ casedata: this.state.casedata + 1 })


        // let tmr = setInterval(() => {
        //     if (this.state.wkwk >= 1) {
        //         clearInterval(tmr);
        //         window.print()
        //     } else {
        //         this.setState({ wkwk: this.state.wkwk + 1 });
        //     }
        // }, 100);
    }

    cek() {

        if (sessionStorage.getItem("un") == null) {
            this.props.history.push('/loadingout')

        }
    }

    tutup() {
        window.close()
    }


    render() {
        const mystyle2 = {
            // iframe{
            height: '100vh',
            width: '100%',
            margin: '0px',

            bottom: '0',
            overflowY: 'hidden',
            // }
        };

        const cekingdata = (val) => {
            if (val == 1) return <iframe style={{
                height: '100vh',
                width: '100%',
                margin: '0px',

                bottom: '0',
                overflowY: 'hidden',
            }} src={`${config.baseURL}${this.state.data}${key_surat.baseURL}`} />
            else return <div style={{
                width: 'auto',
                padding: '20px',
                // background:'yellow',
                position: 'fixed',
                top: '50%',
                left: '50%',
                marginTop: '-120px',
                marginLeft: '-220px'
            }}>
                <strong style={{ fontSize: '20pt' }}>
                    Loading Data .... / (
                    <button onClick={this.tutup} style={{color: 'red'}}> Tutup</button>)
                </strong>
            </div>
        }

        return (
            // <div id="tampilfile"></div>
            <div>
                {cekingdata(this.state.casedata)}
            </div>
        )
    }
}
