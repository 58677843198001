import React, { Component } from 'react'
import V_DistribusiSuratKeluar from './V_DistribusiSuratKeluar'
import API from '../axios/Api'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'


export default class DistribusiSuratKeluar extends Component {

  constructor() {
    super()
    this.state = {
      distribusi: []
    }
  }

  
  //interval = null;
  async dataapi(){
    await API.get("surat/keluar/distribusi/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(
        Response => this.setState({
        distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      })
      )
  }

  componentDidMount() {


    this.dataapi()
    this.timer = setInterval(() => this.dataapi(), 2000);
    //this.componentWillUnmount()
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek(){
    
    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')
      
    }
  }
 
  componentWillUnmount() {
    this.timer = null;
  }
  
  render() {

    const renderData = this.state.distribusi.map(distribusi => {
      return (
        <V_DistribusiSuratKeluar v_distribusi={distribusi} key={distribusi.id_tujuan} />
      )
    })

    return (
      <div className="wrapper">
        <div className="">
          <div className="wrapper">
            <Menu />
            <Header />


            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <section className="content-header">
                <h1>Distribusi Surat Keluar</h1>
              </section>
              {/* Main content */}
              <section className="content">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-success box-solid">
                      <div className="box-header">
                        <strong>Daftar Surat Keluar</strong>
                        <div className="box-tools pull-right">
                        </div>
                      </div>
                      {/* /.box-header */}
                      <div className="box-body">
                        <div className="table-responsive">
                          <table id="example1" className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Pilih</th>
                                <th>No</th>
                                <th>Kode Surat</th>
                                <th>Pengirim</th>
                                <th>Perihal</th>
                                <th>Tgl Terima</th>
                                <th>Tujuan</th>
                                <th>Kategori</th>
                                <th>Tipe</th>
                                <th>Status</th>
                                <th>Menu</th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderData}
                            </tbody>
                          </table>
                        </div>
                        <button type="button" className="btn btn-primary">Kirim</button>
                        {/* /.box-body */}
                      </div>
                    </div>

                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </section>
              {/* /.content */}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

