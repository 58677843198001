import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../axios/Api'
import config from '../axios/koneksi'


function v_suratk({ v_distribusi }) {

  async function handleClick() {

    await API.put('surat/keluar/distribusi/dl?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.sent_id)
    //window.location.reload();
    console.log('Tautan diklik.');
  }

  function pop() {
    var pop = window.confirm("Apakah anda yakin untuk menghapus data surat ini?")
    if (pop == true) {
      handleClick()
    } else {
      //window.location.reload();
    }
  }

  //async function deleteKeluar() {
  //  await API.put('surat/keluar/distribusi/dl?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&sid=' + v_distribusi.sent_id)
  //}


  const script = document.createElement("script");

  script.src = 'js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }



  const sts = (val) => {
    if (val === "S") return 'Surat Keluar'
    else return 'Emergency'
  }


  return (
    <div>
      {/* <a href={"/bsurat/" + v_distribusi.id_theme} className="btn btn-primary">Buat</a> */}
      <Link to={"/bsurat/" + v_distribusi.id_jenis}>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="info-box">
            <div className="info-box-icon bg-aqua">
              <i className="glyphicon glyphicon-align-left"></i>
            </div>
            <div className="info-box-content">
              <div className="info-box-number">{v_distribusi.ket_jenis} </div>
              <span><b>({v_distribusi.kode_jenis})</b></span>
            </div>
          </div>
        </div>
      </Link>
    </div>


  )
}

export default v_suratk