import React, { Component } from 'react'

function v_optiontgl({ v_optiontgl }) {

  const sts = (val) => {
    if (val == "1") return '0' + v_optiontgl.hari
    else if (val == "2") return '0' + v_optiontgl.hari
    else if (val == "3") return '0' + v_optiontgl.hari
    else if (val == "4") return '0' + v_optiontgl.hari
    else if (val == "5") return '0' + v_optiontgl.hari
    else if (val == "6") return '0' + v_optiontgl.hari
    else if (val == "7") return '0' + v_optiontgl.hari
    else if (val == "8") return '0' + v_optiontgl.hari
    else if (val == "9") return '0' + v_optiontgl.hari
    else return v_optiontgl.hari
  }

  return (
    <option value={sts(v_optiontgl.hari)}>{sts(v_optiontgl.hari)}</option>
  )
}

export default v_optiontgl