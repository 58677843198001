import React, { Component } from 'react'
import config from '../axios/koneksi'
import API from '../axios/Api'
import Recaptcha from 'react-recaptcha'
import keyastor from '../axios/key_astor'
import API_ASTOR from '../axios/Api_astor'
import API_DASH from '../axios/Api_dash'
import keydash from '../axios/key_dash'
import direkdash from '../axios/Direk_dash'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      user_id: '',
      user_name: '',
      user_password: '',
      filedatas: '',
      idus: '',
      isVerified: true,
      fslog: '',
      ceklok: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  hedlelog = async (event) => {
    if (this.state.isVerified) {
      event.preventDefault()

      await API_DASH.post('/login/dashboard/ul' + keydash.baseURL, this.state)
        .then(Response =>
          this.setState({
            fslog: Response.data[0].filedatas,
            idus: Response.data[0].idus
          })
        )
        
      if (this.state.fslog == 1) {
        var pop = window.confirm("Apakah anda ingin pindah ke DASHBOARD PEGAWAI Institut ASIA MALANG ?")
        if (pop == true) {
          window.location.replace(direkdash.baseURL + this.state.idus)
        } else {
          await API_ASTOR.post('login/staf/surat/ul' + keyastor.baseURL, this.state,)
            .then((Response) =>
              this.setState({
                filedatas: Response.data[0].filedatas,
                idus: Response.data[0].idus,
                asd: Response.data[0].asd,
              }),
            )
          this.ceklogin()
        }
      } else {
        alert('Gagal Login, Silahkan Ulangi Kembali')
      }

    } else {
      alert('Silahkan isi captcha dahulu.. ya :D')
      return false
    }
  }

  ceklogin() {
    if (this.state.filedatas == 1) {
      const ar = [this.state]
      localStorage.setItem('s', JSON.stringify(ar))
      this.props.history.push('/loading/' + this.state.idus)
    } else {
      alert('Gagal Login, Silahkan Ulangi Kembali')
    }
  }

  recaptchaLoaded() {
    // console.log('captcha successfully loaded');
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      })
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem('un') == null) {
      sessionStorage.clear()
    } else {
      this.props.history.push('/loading/' + sessionStorage.getItem('un'))
    }
  }

  showPw() {
    var x = document.getElementById('myInput')
    if (x.type === 'password') {
      x.type = 'text'
      document.getElementById('asem').style.color = '#32a2e3'
      // document.getElementById("asem").className.replace('fa fa-eye-slash')
    } else {
      x.type = 'password'
      document.getElementById('asem').style.color = '#919191'
    }
  }

  render() {

    // <h1 style={mystyle}>Hello Style!</h1>
    return (
      <div>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100 p-l-50 p-r-50 p-t-40 p-b-30">
              {/* <form id='demo-form' className="login100-form validate-form" action={`${config.baseURL}login/ul?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`} method="post"> */}
              <form onSubmit={this.hedlelog}>
                <h6
                  className="login100-form-title p-b-55"
                  style={{ fontSize: '22px' }}
                >
                  <img
                    width="31%"
                    src="assets/images/asia.png"
                    style={{ marginBottom: '7px' }}
                  ></img>
                  <br />
                  Sistem Informasi Pengarsipan
                </h6>
                <div
                  className="wrap-input100 validate-input m-b-16"
                  style={{ marginTop: '-20px' }}
                >
                  <input
                    className="input100"
                    type="text"
                    name="user_name"
                    onChange={this.handleChange}
                    placeholder="Username"
                    required
                  />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <span className="fa fa-user" />
                  </span>
                </div>
                <div className="wrap-input100 validate-input m-b-16">
                  <input
                    className="input100"
                    id="myInput"
                    type="password"
                    name="user_password"
                    onChange={this.handleChange}
                    placeholder="Passwordd"
                    required
                  />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <span className="fa fa-lock" />
                  </span>
                  <span
                    onClick={this.showPw}
                    id="asem"
                    className="fa fa-eye"
                    style={{
                      position: 'absolute',
                      marginLeft: '90%',
                      marginTop: '-11%',
                      color: '#919191',
                      fontSize: '16px',
                    }}
                  />
                </div>
                <div className="wrap-input100 validate-input">
                  <center>
                    <Recaptcha
                      sitekey="6LeiQ8sZAAAAAKj2UvvKDNqzkpOm4FCrC3D7UIVO"
                      render="explicit"
                      onloadCallback={this.recaptchaLoaded}
                      verifyCallback={this.verifyCallback}
                    />
                  </center>
                </div>

                <div className="container-login100-form-btn p-t-25">
                  <button className="login100-form-btn" type="submit">
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Login
