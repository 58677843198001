import React, { Component } from 'react'
import V_DistribusiSurat from './v_djenis'
import API from '../axios/Api'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API_ASTOR from '../axios/Api_astor'
import key_surat from '../axios/key'
import key_astor from '../axios/key_astor'

export default class djenis extends Component {
  state = {
    distribusi: [],
    idusers: '',
    unusers: '',
    aksusers: '',
    as: '0',
  }

  async loaddaya() {
    // const aks = sessionStorage.getItem('ak')
    await API.get(
      'surat/theme/jenis/surat/view' + key_surat.baseURL + '&aks=' + this.state.aksusers,
    ).then((Response) =>
      this.setState({
        distribusi: Response.data.map((val, idx) => ({ No: idx + 1, ...val })),
      }),
    )
  }

  async componentDidMount() {

    // const script = document.createElement("script");

    // script.src = 'js/content.js';
    // script.async = true;

    // document.body.appendChild(script);

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    this.loaddaya()
    
    // let timerloading = setInterval(() => {
    //   if (this.state.as >= 1) {
    //     clearInterval(timerloading);
    //     var loadingmamak = document.querySelector("#preloader");
    //     loadingmamak.classList.add("loading-jon");
    //   } else {
    //     this.setState({ as: this.state.as + 1 });
    //   }
    // }, 100);
    this.timer = setInterval(() => this.cek(), 1000)
  }

  cek() {
    if (sessionStorage.getItem('un') == null) {
      this.props.history.push('/loadingout')
    }
  }

  render() {
    // const renderData = this.state.distribusi.map((distribusi) => {
    //   return (
    //     <V_DistribusiSurat
    //       v_distribusi={distribusi}
    //       key={distribusi.id_jenis}
    //     />
    //   )
    // })
    const renderData = this.state.distribusi.map((v_distribusi) => {
      const script = document.createElement("script");

      script.src = '../js/content.js';
      script.async = true;

      document.body.appendChild(script);

      async function handleClick() {
        await API.get(
          'surat/theme/jenis/dellete/up' + key_surat.baseURL + '&sid=' +
          v_distribusi.id_jenis,
        )

        // document.querySelectorAll(".btn.btn-danger").setAttribute("disabled", true)
        var loadingmamak = document.querySelector("#preloader");
        loadingmamak.classList.add("loading-jon");
        window.location.reload();
        // console.log('Tautan diklik.')
      }

      function pop(e) {
        e.preventDefault();
        // var pop = window.confirm('Apakah anda yakin untuk menghapus data?')
        // if (pop == true) {
        //   handleClick()
        //   // window.location.reload()
        // }

        var co_popup = document.querySelector('.confirm')
        co_popup.classList.add('active')
        var co_popup1 = document.querySelector('.confirm-box')
        co_popup1.classList.add('active')

        
        document.querySelector(".confirmjon").onclick = function() {
        
          var loadingmamak = document.querySelector("#preloader");
          loadingmamak.classList.remove("loading-jon");

          var co_popup2 = document.querySelector('.confirm')
          co_popup2.classList.remove('active')
          
          var co_popup3 = document.querySelector('.confirm-box')
          co_popup3.classList.remove('active')
  
          handleClick();

        }
      }

      function preview() {
        window.location.replace('/vtheme/' + v_distribusi.id_jenis)
      }

      async function handleClick2() {
        await API.get(
          'surat/theme/jenis/dellete/up/view' + key_surat.baseURL + '&sid=' +
          v_distribusi.id_jenis,
        )
        var loadingmamak = document.querySelector("#preloader");
        loadingmamak.classList.add("loading-jon");

        window.location.reload();
        // console.log('Tautan diklik.')
      }

      function pop2() {
        // var pop = window.confirm('Apakah anda yakin untuk Menampilkan data?')
        // if (pop == true) {
        //   handleClick2()
        //   // window.location.reload()
        // }

        var return_popup = document.querySelector('.return-confirm')
        return_popup.classList.add('active')
        var return_popup1 = document.querySelector('.return-confirm-box')
        return_popup1.classList.add('active')

        
        document.querySelector(".return-confirmjon").onclick = function() {
          
          var loadingmamak = document.querySelector("#preloader");
          loadingmamak.classList.remove("loading-jon");

          var return_popup2 = document.querySelector('.return-confirm')
          return_popup2.classList.remove('active')
          
          var return_popup3 = document.querySelector('.return-confirm-box')
          return_popup3.classList.remove('active')
  
          handleClick2();

        }
      }

      const rep = (val) => {
        if (val === '0') return 'Dihapus / Hiden'
        else if (val === '1') return 'Tampil'
        else return ''
      }

      const rep2 = (val) => {
        if (val == null) return '0'
        else return v_distribusi.ket_nttd
      }

      const tm = (val) => {
        if (val == '0')
          return (
            <td>
              <button type="button" onClick={pop2} className="btn btn-danger">Tampilkan</button>
            </td>
          )
        else if (val == '1')
          return (
            <td>
              <Link to={'/ejenis/' + v_distribusi.id_jenis} type="button" className="btn btn-info" style={{ marginRight: '4px' }}>Edit</Link>
              <button type="button" onClick={preview} className="btn btn-success">Setting & Preview Theme</button>{' '}
              &nbsp;
              <button
                type="button"
                onClick={pop}
                className="btn btn-danger"
                style={{ marginRight: '4px' }}
              >
                Hapus
              </button>
            </td>
          )
        else return ''
      }

      return (
        <tr key={v_distribusi.No}>
          <td>{v_distribusi.No}</td>
          <td>{v_distribusi.kode_jenis}</td>
          <td>{v_distribusi.ket_jenis}</td>
          <td>{rep(v_distribusi.id_hidden)}</td>
          {tm(v_distribusi.id_hidden)}
          {/* {v_distribusi.id_hidden} */}
        </tr>
      )
    })
    // function okjek () {
    //   var x = document.querySelector(".confirmjon");
    //   if (x.checked == true) {
    //     var co_popup2 = document.querySelector('.confirm')
    //     co_popup2.classList.remove('active')
        
    //     var co_popup3 = document.querySelector('.confirm-box')
    //     co_popup3.classList.remove('active')

    //     window.location.reload();
    //   } 
    //   else {}
  
    // }
    function okbos () {
      var y = document.querySelector(".canceljon");

      if (y.checked == true){
        var co_popup2 = document.querySelector('.confirm')
        co_popup2.classList.remove('active')
        
        var co_popup3 = document.querySelector('.confirm-box')
        co_popup3.classList.remove('active')
      } 
      else {}
    }
    function okwes () {
      var y = document.querySelector(".return-canceljon");

      if (y.checked == true){
        var return_popup2 = document.querySelector('.return-confirm')
        return_popup2.classList.remove('active')
        
        var return_popup3 = document.querySelector('.return-confirm-box')
        return_popup3.classList.remove('active')
      } 
      else {}
    }

    return (
      <div className="wrapper">
        <div className="">
          <div className="wrapper">
            <Menu />
            <Header />
            <div className="content-wrapper">

            <div className="loading-jon" id="preloader">
              <div className="jumper">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="confirm">
              <input type="checkbox" className="confirmjon" id="confirmjon"></input>
              <input type="checkbox" className="canceljon" id="canceljon" onChange={okbos}></input>
              <div className="confirm-box">
                <div className="confirm-icon">
                  <i className="fa fa-exclamation"></i>
                </div>
                <header>Hapus ?</header>
                <p>Apakah anda yakin ingin menghapus?</p>
                <div className="confirm-btn-box">
                <label for="confirmjon">OK</label>
                <label for="canceljon">Cancel</label>
                </div>
              </div>
            </div>
            <div className="return-confirm">
              <input type="checkbox" className="return-confirmjon" id="return-confirmjon"></input>
              <input type="checkbox" className="return-canceljon" id="return-canceljon" onChange={okwes}></input>
              <div className="return-confirm-box">
                <div className="return-confirm-icon">
                  <i className="fa fa-refresh"></i>
                </div>
                <header>Tampilkan ?</header>
                <p>Apakah anda yakin ingin menampilkan data?</p>
                <div className="return-confirm-btn-box">
                <label for="return-confirmjon">OK</label>
                <label for="return-canceljon">Cancel</label>
                </div>
              </div>
            </div>

              <section className="content-header">
                <h1>Daftar Jenis Surat dan Pengaturan Laporan</h1>
              </section>
              <section className="content">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-success box-solid">
                      <div className="box-header">
                        <strong>Daftar Jenis Surat</strong>
                        <div className="box-tools pull-right"></div>
                      </div>
                      <div className="box-body">
                        <div className="table-responsive">
                          <table id="example1" className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Kode Jenis</th>
                                <th>Nama Jenis</th>
                                <th>Keterangan</th>
                                <th>Opsi</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {renderData} */}
                              {
                                renderData
                              // this.state.distribusi.map((v_distribusi) => {
                                  // const script = document.createElement("script");

                                  // script.src = '../js/content.js';
                                  // script.async = true;

                                  // document.body.appendChild(script);

                                  // async function handleClick() {
                                  //   await API.get(
                                  //     'surat/theme/jenis/dellete/up' + key_surat.baseURL + '&sid=' +
                                  //     v_distribusi.id_jenis,
                                  //   )
                                  //   //window.location.reload();
                                  //   // console.log('Tautan diklik.')
                                  // }

                                  // function pop() {
                                  //   var pop = window.confirm('Apakah anda yakin untuk menghapus data?')
                                  //   if (pop == true) {
                                  //     handleClick()
                                  //     // window.location.reload()
                                  //   }
                                  // }

                                  // function preview() {
                                  //   window.location.replace('/vtheme/' + v_distribusi.id_jenis)
                                  // }

                                  // async function handleClick2() {
                                  //   await API.get(
                                  //     'surat/theme/jenis/dellete/up/view' + key_surat.baseURL + '&sid=' +
                                  //     v_distribusi.id_jenis,
                                  //   )
                                  //   //window.location.reload();
                                  //   console.log('Tautan diklik.')
                                  // }

                                  // function pop2() {
                                  //   var pop = window.confirm('Apakah anda yakin untuk Menampilkan data?')
                                  //   if (pop == true) {
                                  //     handleClick2()
                                  //     window.location.reload()
                                  //   }
                                  // }

                                  // const rep = (val) => {
                                  //   if (val === '0') return 'Dihapus / Hiden'
                                  //   else if (val === '1') return 'Tampil'
                                  //   else return ''
                                  // }

                                  // const rep2 = (val) => {
                                  //   if (val == null) return '0'
                                  //   else return v_distribusi.ket_nttd
                                  // }

                                  // const tm = (val) => {
                                  //   if (val == '0')
                                  //     return (
                                  //       <td>
                                  //         <button type="button" onClick={pop2} className="btn btn-danger">Tmpilkan</button>
                                  //       </td>
                                  //     )
                                  //   else if (val == '1')
                                  //     return (
                                  //       <td>
                                  //         <Link to={'/ejenis/' + v_distribusi.id_jenis} type="button" className="btn btn-info" style={{ marginRight: '4px' }}>Edit</Link>
                                  //         <button type="button" onClick={preview} className="btn btn-success">Setting & Preview Theme</button>{' '}
                                  //         &nbsp;
                                  //         <button
                                  //           type="button"
                                  //           onClick={pop}
                                  //           className="btn btn-danger"
                                  //           style={{ marginRight: '4px' }}
                                  //         >
                                  //           Hapus
                                  //         </button>
                                  //       </td>
                                  //     )
                                  //   else return ''
                                  // }

                                  // return (
                                  //   <tr key={v_distribusi.No}>
                                  //     <td>{v_distribusi.No}</td>
                                  //     <td>{v_distribusi.kode_jenis}</td>
                                  //     <td>{v_distribusi.ket_jenis}</td>
                                  //     <td>{rep(v_distribusi.id_hidden)}</td>
                                  //     {tm(v_distribusi.id_hidden)}
                                  //     {/* {v_distribusi.id_hidden} */}
                                  //   </tr>
                                  // )
                                // })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}
