import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'
// import V_ptheme from './v_ptheme'
import V_vtheme from './v_vtheme'
import { red } from '@material-ui/core/colors';
// import z_theme from './ztheme';
import styles from './hitcss.css'

export default class vtheme extends Component {

  constructor() {
    super()
    this.state = {
      vmenutheme: [],
      rwl: '',

      m1: '',
      m2: '',
      m3: '',
      m4: '',
      m5: '',
      m6: '',
      m7: '',
      m8: '',
      m9: '',
      m10: '',
      m11: '',
      m12: '',
      m13: '',
      m14: '',
      m15: '',
      m16: '',
      m17: '',
      m18: '',
      m19: '',
      m20: '',

      thes1: '',
      hdttd: '',
      resdata: ''
    }
  }

  defoulttheme = async (event) => {
    var loadingmamak = document.querySelector('#preloader')
    loadingmamak.classList.remove('loading-jon')
    
    const idjns = this.props.match.params.id_jenis
    await API.put('update/theme/default/' + idjns + '?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE')
    window.location.reload();
  }
  save = () => {
    // alert('Berhasil Simpan !!')
    // return window.location.href = "/djenis";
    
    var loadingmamak = document.getElementById('preloader')
    loadingmamak.classList.add('loading-jon')

    var popup = document.querySelector('.popbox')
    popup.classList.add('active')
    var popup1 = document.querySelector('.popup-box')
    popup1.classList.add('active')

    // var elmnt = document.querySelector(".btn.btn-success");
    // elmnt.scrollIntoView({behavior: 'smooth'});
  }
  okjek () {
    var x = document.querySelector(".checkjon");
    if (x.checked == true) {
      var popup2 = document.querySelector('.popbox')
      popup2.classList.remove('active')
      
      var popup3 = document.querySelector('.popup-box')
      popup3.classList.remove('active')

      window.location.replace('/djenis')
    } 
    else {}

  }

  async componentDidMount() {

    const aks = sessionStorage.getItem("idjns")
    let idjns = this.props.match.params.id_jenis
    const cuk = await API.get('preview/menu/kanan/tampil/test?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + idjns)
    this.setState({
      resdata: cuk.data[0].resdata,
      filedatas: cuk.data[0].filedatas
    })
    // console.log(cuk)

    if (this.state.resdata == 0) {
      window.location.reload()
    } else {
      this.setState({
        vmenutheme: cuk.data,

        m1: cuk.data[0].hasvalue,
        m2: cuk.data[1].hasvalue,
        m3: cuk.data[2].hasvalue,
        m4: cuk.data[3].hasvalue,
        m5: cuk.data[4].hasvalue,
        m6: cuk.data[5].hasvalue,
        m7: cuk.data[6].hasvalue,
        m8: cuk.data[7].hasvalue,
        m9: cuk.data[8].hasvalue,
        m10: cuk.data[9].hasvalue,
        m11: cuk.data[10].hasvalue,
        m12: cuk.data[11].hasvalue,
        m13: cuk.data[12].hasvalue,
        m14: cuk.data[13].hasvalue,
        m15: cuk.data[14].hasvalue,
        m16: cuk.data[15].hasvalue,
        m17: cuk.data[16].hasvalue,
        m18: cuk.data[17].hasvalue,
        m19: cuk.data[18].hasvalue,
        m20: cuk.data[19].hasvalue,

        rwl: cuk.data[0].filedatas,
        hdttd1: cuk.data[4].hdttd,
      })
    }



    // if (this.state.hdttd1 == "hittrue"){
    //   this.state.hdttd="hittrue"
    // }else{
    //   this.state.hdttd=""
    // }

    if (this.state.rwl == 1) {
      window.location.reload()
    }
    // .then(Response => console.log(Response))

    // console.log(this.state.vmenutheme)

    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek() {

    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')

    }
  }


  render() {
    // const renderData = this.state.vmenutheme.map(vmenutheme => {
    //   return (
    //     <z_theme z_theme={vmenutheme} key={vmenutheme.nono} />
    //   )
    // })

    const b1 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '40px' }} align="center">
        <font size="5"><b><u>JENIS SURAT</u></b></font>
      </div>
      else return ''
    }
    const b2 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '0px', paddingLeft: '25%', paddingRight: '25%' }}>
        <div className="col-md-12" align="center">
          <font size="2">No : 001/Und/R.0/ITB-ASIA/VII/2020 (Db)</font>
        </div>
      </div>
      else return ''
    }
    const b3 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '0px' }} align="center">
        <b>Tentang :<br />Isi Tentang / Hal / Perihal (Db)</b>
      </div>
      else return ''
    }
    const b4 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '30px', marginBottom: '40px', padding: '0px 0px 80px 0px' }}>
        Isi Surat
    </div>
      else return ''
    }
    const b5 = (val) => {
      if (val == "z1") return 'Mengetahui,'
      else if (val == "z2") return 'Menyetujui,'
      else if (val == "z3") return <div>Ditetapkan di : <br /> Pada Tanggal :</div>
      // <ul> Ditetapkan di : <li>Pada Tanggal :</li></ul>
      else if (val == "z4") return 'Hormat Kami'
      else if (val == "z5") return 'Malang, Tanggal'
      else return ''
    }
    const b6 = (val) => {
      if (val == "z1") return 'Mengetahui,'
      else if (val == "z2") return 'Menyetujui,'
      else if (val == "z3") return <div>Ditetapkan di : <br /> Pada Tanggal :</div>
      // <ul> Ditetapkan di : <li>Pada Tanggal :</li></ul>
      else if (val == "z4") return 'Hormat Kami'
      else if (val == "z5") return 'Malang, Tanggal'
      else return ''
    }
    const b7 = (val) => {
      if (val == "v") return <div>
        Nama Ttd1<br />NIDN :
      </div>
      else return ''
    }
    const b7jabatan = (val) => {
      if (val == "v") return <div>
        Jabatan
      </div>
      else return ''
    }
    const b8 = (val) => {
      if (val == "v") return <div>
        Nama Ttd2<br />NIDN :
      </div>
      else return ''
    }
    const b8jabatan = (val) => {
      if (val == "v") return <div>
        Jabatan
      </div>
      else return ''
    }
    const b9 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', marginTop: '40px', padding: '0px' }}>
        Lampiran Surat 'Jenis Surat (db)' Nomor : 001/Und/R.0/ITB-ASIA/VII/2020 (Db)<br />Tentang :<br /><br />Isi Lampiran
    </div>
      else return ''
    }
    const b10 = (val) => {
      if (val == "z1") return 'Mengetahui,'
      else if (val == "z2") return 'Menyetujui,'
      else if (val == "z3") return <div>Ditetapkan di : <br /> Pada Tanggal :</div>
      // <ul> Ditetapkan di : <li>Pada Tanggal :</li></ul>
      else if (val == "z4") return 'Hormat Kami'
      else if (val == "z5") return 'Malang, Tanggal'
      else return ''
    }
    const b11 = (val) => {
      if (val == "v") return <div>
        Nama Ttd3<br />NIDN :
      </div>
      else return ''
    }
    const b11jabatan = (val) => {
      if (val == "v") return <div>
        Jabatan
      </div>
      else return ''
    }
    const b12 = (val) => {
      if (val == "v") return <div>
        Tembusan / Catatan
      </div>
      else return ''
    }
    const b13 = (val) => {
      if (val == "v") return <div className="col-md-6" style={{ border: '0px solid black' }} >
        Kepada Yth.<br />Nama / Tujuan (Db tujuan)
    </div>
      else return ''
    }
    const b14 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black' }}>
        Di Tempat (Db alamat)
    </div>
      else return ''
    }
    const b15 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', paddingLeft: '0px', marginTop: '20px' }} align="left">
        Kepada Yth.<br />Nama / Tujuan (Db tujuan)
    </div>
      else return ''
    }
    const b16 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', paddingLeft: '0px', marginTop: '0px' }} align="left">
        Di Tempat (Db alamat)
    </div>
      else return ''
    }
    const b17 = (val) => {
      if (val == "v") return <div className="col-md-12" style={{ border: '0px solid black', paddingLeft: '0px', marginTop: '40px' }} align="left">
        <table border="0">
          <tbody>
            <tr>
              <td>No.</td>
              <td style={{ paddingLeft: '2px' }}>: 001/Und/R.0/ITB-ASIA/VII/2020 (Db)</td>
            </tr>
            <tr>
              <td>Lampiran</td>
              <td style={{ paddingLeft: '2px' }}>: (db lampiran)</td>
            </tr>
            <tr>
              <td>Perihal</td>
              <td style={{ paddingLeft: '2px' }}>: (db perihal)</td>
            </tr>
          </tbody>
        </table>
      </div>
      else return ''
    }
    const b18 = (val) => {
      if (val == "v") return <div className={col13(this.state.m13.has_value)} style={{ border: '0px solid black' }} align="right">
        Malang, Tanggal (Db tanggal)
    </div>
      else return ''
    }
    const b19 = (val) => {
      if (val == "z1") return 'Mengetahui,'
      else if (val == "z2") return 'Menyetujui,'
      else if (val == "z3") return <div>Ditetapkan di : <br /> Pada Tanggal :</div>
      // <ul> Ditetapkan di : <li>Pada Tanggal :</li></ul>
      else if (val == "z4") return 'Hormat Kami'
      else if (val == "z5") return 'Malang, Tanggal'
      else return ''
    }
    const b20 = (val) => {
      if (val == "v") return <div>
        Nama Ttd4<br />NIDN :
      </div>
      else return ''
    }
    const b20jabatan = (val) => {
      if (val == "v") return <div>
        Jabatan
      </div>
      else return ''
    }

    const col13 = (val) => {
      if (val == "v") return 'col-md-6'
      else return 'col-md-12'
    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">

        <div className="loading-jon" id="preloader">
            <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="popbox">
            <input type="checkbox" className="checkjon" id="checkjon" onChange={this.okjek}></input>
            <div className="popup-box">
              <div className="icon">
                <i className="fa fa-check"></i>
              </div>
              <header>Berhasil !</header>
              <p>Klik OK untuk melanjutkan</p>
              <label for="checkjon">OK</label>
            </div>
          </div>

          <section className="content-header">
            <h1>Setting dan Tampilan Tema Surat</h1>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-8 margin-bottom-30">
                <div>
                  <div className="box-body">
                    <div className="col-md-12 box box-primary box-solid" style={{ paddingLeft: '8%', paddingRight: '6%', overflow: 'auto', backgroundColor: '#f0f6ff' }}>
                      <div className="col-md-12" id="print-area" style={{ width: '210mm', height: 'auto', backgroundColor: '#FFF', border: '0px solid black', marginTop: '30px', marginRight: '20px', marginBottom: '30px', marginLeft: '0px', padding: '70px' }}>

                        {b13(this.state.m13)}
                        {b18(this.state.m18)}
                        {b14(this.state.m14)}
                        {b1(this.state.m1)}
                        {b2(this.state.m2)}
                        {b3(this.state.m3)}
                        {b17(this.state.m17)}
                        {b15(this.state.m15)}
                        {b16(this.state.m16)}
                        {b4(this.state.m4)}

                        <div className="col-md-12" style={{ border: '0px solid black', marginTop: '20px', marginBottom: '20px', padding: '0px' }} align="Left">
                          <div className="col-md-12" style={{ border: '0px solid black', padding: '0px' }} align="Left">
                            {/* header  */}
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b6(this.state.m6)}
                            </div>

                            <div className="col-md-2" style={{ border: '0px solid black', marginTop: '0px' }}></div>

                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b5(this.state.m5)}
                            </div>
                          </div>

                          <div className="col-md-12" style={{ border: '0px solid black', padding: '0px' }} align="Left">
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: 'px', padding: '0px' }} align="Left">
                              {b8jabatan(this.state.m8)}
                            </div>
                            <div className="col-md-2" style={{ border: '0px solid black', marginTop: '0px' }}></div>
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b7jabatan(this.state.m7)}
                            </div>
                          </div>

                          <div className="col-md-12" style={{ border: '0px solid black', padding: '0px' }} align="Left">
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              <br /><br /><br /><br />{b8(this.state.m8)}
                            </div>
                            <div className="col-md-2" style={{ border: '0px solid black', marginTop: '0px' }}></div>
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              <br /><br /><br /><br />{b7(this.state.m7)}
                            </div>
                          </div>

                        </div>

                        {b9(this.state.m9)}

                        <div className="col-md-12" style={{ border: '0px solid black', marginTop: '20px', marginBottom: '20px', padding: '0px' }} align="Left">
                          <div className="col-md-12" style={{ border: '0px solid black', padding: '0px' }} align="Left">
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b19(this.state.m19)}
                            </div>
                            <div className="col-md-2" style={{ border: '0px solid black', marginTop: '0px' }}></div>
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b10(this.state.m10)}
                            </div>
                          </div>
                          <div className="col-md-12" style={{ border: '0px solid black', padding: '0px' }} align="Left">
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b20jabatan(this.state.m20)}
                            </div>
                            <div className="col-md-2" style={{ border: '0px solid black', marginTop: '0px' }}></div>
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              {b11jabatan(this.state.m11)}
                            </div>
                          </div>
                          <div className="col-md-12" style={{ border: '0px solid black', padding: '0px' }} align="Left">
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              <br /><br /><br /><br />{b20(this.state.m20)}
                            </div>
                            <div className="col-md-2" style={{ border: '0px solid black', marginTop: '0px' }}></div>
                            <div className="col-md-5" style={{ border: '0px solid black', marginTop: '0px', padding: '0px' }} align="Left">
                              <br /><br /><br /><br />{b11(this.state.m11)}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12" style={{ border: '0px solid black', marginTop: '40px', padding: '0px' }}>
                          {b12(this.state.m12)}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ====== menus ==== */}

              <div className="col-md-4 col-sm-4 margin-bottom-30" align="center">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Setting Isi Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="col-md-12" align="left" style={{ paddingBottom: '20px' }}>
                      Reset Theme Surat : <button onClick={this.defoulttheme} style={{ marginLeft: '5px' }} className="btn btn-warning">Reset Default</button>
                      <button className="btn btn-info" onClick={this.save} style={{ marginLeft: '10px' }}>SIMPAN</button>
                    </div>

                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table id="example1" className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th colSpan="3" align="center">Daftar Isian Surat</th>
                            </tr>

                          </thead>
                          <tbody>
                            {/* {renderData} */}
                            {
                              this.state.vmenutheme.map((z_theme, idx) => {
                                async function handleClick() {
                                  if (z_theme.hasvalue == 0) {
                                    await API.put('update/preview/menu/kanan/tambah/up?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  } else {
                                    await API.put('update/preview/menu/kanan/hapus/data/update?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  }

                                  window.location.reload();
                                  // console.log('Tautan diklik.');
                                }
                                // handleChange3(evt2) {
                                //     this.setState({
                                //       isi_lamp: evt2.editor.getData()
                                //     });

                                //   }


                                const hhdn = (val) => {
                                  if (z_theme.has_id == null) {

                                  } else {
                                    if (val == "0") return <button onClick={handleClick} className="btn btn-success">Tambah {z_theme.has_id}</button>
                                      
                                    else if (val == "v") return <button onClick={handleClick} className="btn btn-danger">Hapus {z_theme.has_id}</button>
                                    
                                    //     else if (val == "z") return <td>
                                    //         <button onClick={handleClick} className="btn btn-success">Tambah</button>
                                    //     </td>
                                    //     else if (val == "za") return <td>
                                    //     <button onClick={handleClick} className="btn btn-danger">Hapus</button>
                                    // </td>
                                    else return ''
                                  }

                                }

                                async function kosongkan() {
                                  await API.put('update/preview/menu/kanan/ket/ttd/kosong?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  window.location.reload()
                                }

                                async function mengetahui() {
                                  await API.put('update/preview/menu/kanan/ket1/ttd1/mengetahui?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  window.location.reload()
                                }

                                async function menyetujui() {
                                  await API.put('update/preview/menu/kanan1/ket2/ttd2/menyetujui?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  window.location.reload()
                                }

                                async function ditetapkan() {
                                  await API.put('update/preview/menu/kanan/ket3/ttd3/ditetapkan?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  window.location.reload()
                                }

                                async function hormat() {
                                  await API.put('update/preview/menu/kanan/ket4/ttd4/hormat?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  window.location.reload()
                                }

                                async function malang() {
                                  await API.put('update/preview/menu/kanan/ket5/ttd5/malang?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&idjns=' + z_theme.jenis_id + '&hasid=' + z_theme.has_id)
                                  window.location.reload()
                                }

                                const zcrew = (val) => {
                                  if (val == "5") return <div>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td colSpan="3">{z_theme.judul_theme}</td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={kosongkan} id="btnckanan" className="btn">Kosongkan</button></td>
                                          <td><button onClick={mengetahui} id="btnckanan" className="btn">Mengetahui</button></td>
                                          <td><button onClick={menyetujui} id="btnckanan" className="btn">Menyetujui</button></td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={ditetapkan} id="btnckanan" className="btn">Ditetapkan di :<br />Pada Tanggal :</button></td>
                                          <td><button onClick={hormat} id="btnckanan" className="btn">Hormat Kami</button></td>
                                          <td><button onClick={malang} id="btnckanan" className="btn">Malang, Tanggal</button></td>
                                        </tr>
                                      </tbody>

                                    </table>
                                  </div>
                                  else if (val == "6") return <div>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td colSpan="3">{z_theme.judul_theme}</td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={kosongkan} id="btnckanan" className="btn">Kosongkan</button></td>
                                          <td><button onClick={mengetahui} id="btnckanan" className="btn">Mengetahui</button></td>
                                          <td><button onClick={menyetujui} id="btnckanan" className="btn">Menyetujui</button></td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={ditetapkan} id="btnckanan" className="btn">Ditetapkan di :<br />Pada Tanggal :</button></td>
                                          <td><button onClick={hormat} id="btnckanan" className="btn">Hormat Kami</button></td>
                                          <td><button onClick={malang} id="btnckanan" className="btn">Malang, Tanggal</button></td>
                                        </tr>
                                      </tbody>

                                    </table>
                                  </div>
                                  else if (val == "10") return <div>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td colSpan="3">{z_theme.judul_theme}</td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={kosongkan} id="btnckanan" className="btn">Kosongkan</button></td>
                                          <td><button onClick={mengetahui} id="btnckanan" className="btn">Mengetahui</button></td>
                                          <td><button onClick={menyetujui} id="btnckanan" className="btn">Menyetujui</button></td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={ditetapkan} id="btnckanan" className="btn">Ditetapkan di :<br />Pada Tanggal :</button></td>
                                          <td><button onClick={hormat} id="btnckanan" className="btn">Hormat Kami</button></td>
                                          <td><button onClick={malang} id="btnckanan" className="btn">Malang, Tanggal</button></td>
                                        </tr>
                                      </tbody>

                                    </table>
                                  </div>
                                  else if (val == "19") return <div>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td colSpan="3">{z_theme.judul_theme}</td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={kosongkan} id="btnckanan" className="btn">Kosongkan</button></td>
                                          <td><button onClick={mengetahui} id="btnckanan" className="btn">Mengetahui</button></td>
                                          <td><button onClick={menyetujui} id="btnckanan" className="btn">Menyetujui</button></td>
                                        </tr>
                                        <tr>
                                          <td><button onClick={ditetapkan} id="btnckanan" className="btn">Ditetapkan di :<br />Pada Tanggal :</button></td>
                                          <td><button onClick={hormat} id="btnckanan" className="btn">Hormat Kami</button></td>
                                          <td><button onClick={malang} id="btnckanan" className="btn">Malang, Tanggal</button></td>
                                        </tr>
                                      </tbody>

                                    </table>
                                  </div>
                                  else return z_theme.judul_theme
                                }

                                const cspan = (val) => {
                                  if (val == "5") return '2'
                                  else if (val == "6") return '2'
                                  else if (val == "10") return '2'
                                  else if (val == "19") return '2'
                                  else return ''
                                }

                                return (
                                  <tr key={idx}>
                                    {/* {console.log(z_theme.nono)} */}
                                    <td>{z_theme.has_id}</td>
                                    <td colSpan={cspan(z_theme.has_id)}>{zcrew(z_theme.has_id)}</td>
                                    <td>{hhdn(z_theme.hasvalue)}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
        <Footer />
      </div >

    )
  }
}
