import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import Notif_ada_notulen from './Notif_ada_Notulen'
import Notif_tidakada_notulen from './Notif_tidakada_Notulen'

function Notulen(props) {
  return (
    <>


      <div class="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          <Notif_tidakada_notulen />
          <Notif_ada_notulen />
        </div>
        <Footer />
      </div>


    </>
  )
}

export default Notulen