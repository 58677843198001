import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CKEditor from 'ckeditor4-react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import config from '../axios/koneksi'
import ReactHtmlParser from 'react-html-parser';


export default class NotulenIsi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datainvite: [],
      Invite_creator: '',
      Invite_category: '',
      Invite_to_intern: '',
      Invite_date_event: '',
      Invite_about: '',
      Invite_status: '',
      Invite_file_notulen: '',
      Invite_notulen: '',
      Invite_nm:''
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const data = this.state
    console.log("Data berhasil", data)
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
  }

  async componentDidMount() {
    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);
    
    const invite_id = this.props.match.params.invite_id
    const res = await API.get('/surat/notulen/form/view/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&isnotulen=' + invite_id)
    this.setState({
      Invite_id:res.data[0].invite_id,
      Invite_nm:res.data[0].invite_nm,
      Invite_creator:res.data[0].invite_creator,
      Invite_category:res.data[0].invite_category,
      Invite_to_intern:res.data[0].invite_to_intern,
      Invite_date_event:res.data[0].invite_date_event,
      Invite_about:res.data[0].invite_about,
      Invite_status:res.data[0].invite_status,
      Invite_file_notulen:res.data[0].invite_file_notulen,
      Invite_notulen:res.data[0].invite_notulen
    })

    //const invite_id = this.props.match.params.invite_id

    //await API.get('/surat/notulen/form/view/view?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&isnotulen=' + invite_id)
      //await API.get("uv?req=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
    //  .then(Response => this.setState({
    //    datainvite: Response.data[0]
    //  }))
      
    //console.log(this.state)
  }

  render() {

    const stype = (val) => {
      if (val === "R.0") return 'Rektor'
      else if (val === "R.1") return 'Wakil Rektor 1'
      else if (val === "R.2") return 'Wakil Rektor 2'
      else if (val === "R.3") return 'Wakil Rektor 3'
      else return 'Wakil Rektor 4'
    }

    const stus = (val) => {
      if (val === "0") return 'Undangan Tanpa Notulen'
      else return 'Undangan Dengan Notulen'
    }

    return (
      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Isi Notulen</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Isi Notulen</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form action={`${config.baseURL}undangan/notulen/no/isiundangan?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&nid=${this.state.Invite_id}`} method="post" encType="multipart/form-data">
                      <div className="col-md-6">

                        <div className="form-group">
                          <label>Pembuat Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <input type="text" name="Invite_creator" readOnly required value={`${stype(this.state.Invite_creator)}`} className="form-control" onChange={this.handleInputChange} placeholder="Pembuat Undangan"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kategori</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <input type="text" name="Invite_category" readOnly disabled required value={this.state.Invite_category} className="form-control" onChange={this.handleInputChange} placeholder="Kategori"></input>
                          </div>
                        </div>
                      </div>
                      {/*
                      <div className="form-group">
                        
                        &nbsp;&nbsp;&nbsp; <label>Tujuan </label><br />
                        &nbsp;&nbsp;&nbsp; <input name="Invite_to_intern" className="form-control" onChange={this.handleInputChange} class="minimal" type="checkbox"></input>&nbsp;Dekan FTD<br />
                        &nbsp;&nbsp;&nbsp; <input name="Invite_to_intern" className="form-control" onChange={this.handleInputChange} class="minimal" type="checkbox"></input>&nbsp;Kaprodi Akuntnsi<br />
                        &nbsp;&nbsp;&nbsp; <input name="Invite_to_intern" className="form-control" onChange={this.handleInputChange} class="minimal" type="checkbox"></input>&nbsp;HRD<br />
                        
                      </div>
                        */}
                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Tujuan Internal</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="Invite_to_intern" disabled readOnly id="Invite_to_intern" required className="form-control" onChange={this.handleInputChange}>
                              <option value={this.state.datainvite.invite_to_intern}>-- {this.state.Invite_to_intern} --</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Dekan FTD">Dekan FTD</option>
                              <option value="Ketua LPKD">Ketua LPKD</option>
                              <option value="Kaprodi Akuntansi">Kaprodi Akuntansi</option>
                              <option value="Wa Dek FEB">Wa Dek FEB</option>
                              <option value="HRD">HRD</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Tanggal Event</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input type="date" name="Invite_date_event" readOnly required className="form-control" value={this.state.Invite_date_event} onChange={this.handleInputChange} placeholder="Tanggal Event"></input>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Perihal Undangan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input type="text" name="Invite_about" readOnly required defaultValue={this.state.Invite_about} className="form-control" onChange={this.handleInputChange} placeholder="Perihal Undangan"></input>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div className="form-group">
                          <label>Status</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bell"></i>
                            </div>
                            <input type="text" name="Invite_status" readOnly required value={stus(this.state.Invite_status)} className="form-control" onChange={this.handleInputChange} placeholder="Status"></input>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Upload Daftar Hadir</label>
                        <input type="file" name="Recieve_file" placeholder="Digitalisasi Surat" onChange={this.handleInputChange} required></input>
                      </div>
                      
                      <div className="form-group">
                        <label>Isi Notulen Surat</label>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <i className="fa fa-envelope"></i>
                          </div>
                          <textarea id="editor1" name="Invite_notulen" placeholder="Isi Notulen Surat" required onChange={this.handleInputChange} rows="10" cols="80">
                          </textarea>
                        </div>
                      </div>

                      <button className="btn btn-primary">Kirim</button>

                    </form>
                  </div>
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
