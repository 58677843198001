import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import V_Loading from './V_Loading'
import V_KelolaUser from './administrator/V_KelolaUser'
import ClearCache from 'react-clear-cache'
import keyastor from '../axios/key_astor'
import API_ASTOR from '../axios/Api_astor'

export default class Login extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      un: '',
      ua: '',
      usd: '',
      user_name: '',
      user_password: '',
      filedatas: '',
      semongko: '',
      v_versi: '',
      b_versi: '',
      n_versi: '',
      Lot: [],
      cklink: '2'
    }
  }

  async componentDidMount() {
    const lsun = this.props.match.params.usr
    const lspss = this.props.match.params.pss

    this.setState({
      user_name: lsun,
      user_password: lspss
    })

    const scp = this.props.match.params.un


    const res = await API_ASTOR.get('aplikasi/surat/search/lv/join/app/inview' + keyastor.baseURL + '&uid=' + scp + '&app=1')
    this.setState({
      un: res.data[0].user_id,
      uas: res.data[0].level_id,
      ua: res.data[0].level_id,
      usd: res.data[0].usid,
      v_versi: res.data[0].v_versi,
      b_versi: res.data[0].b_versi,
      n_versi: res.data[0].n_versi,
      Lot: res.data[0].lots
    })

    await API_ASTOR.post('login/staf/dash/in/ul' + keyastor.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas,
        })
      )

    if (this.state.filedatas == 1) {
      sessionStorage.setItem("un", this.state.un)
      // localStorage.setItem("ak", JSON.stringify(this.state.uas))
      // sessionStorage.setItem("ak", this.state.Lot[0].akses)
      sessionStorage.setItem("sid", this.state.usd)

      this.setState({
        cklink: '1'
      })
      const ar = [{ "is verivy": "true", }]
      localStorage.setItem('l', JSON.stringify(ar))

      this.sesscek()
    } else {
      this.setState({
        cklink: '0'
      })
    }

  }

  sesscek() {
    if (sessionStorage.getItem("un") == null) {
      localStorage.clear()
      sessionStorage.clear()
      // window.location.replace('/notdash')
      this.setState({
        cklink: '0'
      })
    } else {
      // if (this.state.b_versi == 1) {
        localStorage.removeItem('s')
        window.location.replace('/inboxframe/0')
        
      // } else {

        // if (this.state.semongko == 1) {
        //   window.location.replace('/inboxframe/0')
        // } else {
        //   localStorage.removeItem('s')
          // window.location.replace('/notdash')
          // this.setState({
          //   cklink: '0'
          // })
        // }
      // }
    }
  }

  lainkalidiupdate = async () => {
    const idui = sessionStorage.getItem('sid')
    const sem = await API_ASTOR.post('update/versi/app/surat/lain/kali/no' + keyastor.baseURL + '&idus=' + idui + '&appid=1')

    // window.location.replace('/home')
  }

  onupdateversiapp = async () => {
    const idui = sessionStorage.getItem('sid')
    const sem = await API_ASTOR.post('update/versi/app/surat/up' + keyastor.baseURL + '&idus=' + idui + '&appid=1')
    this.setState({
      semongko: sem.data[0].semongko
    })
    alert("Berhasil Update Aplikasi. Silahkan Login Kembali")
    this.sesscek()
  }


  render() {
    const linked = (val) => {
      if (val == 0) return window.location.replace('../../../notdash')
    }

    

    return (
      <div>
        {/* {sis(this.state.semongko)} */}
        {/* {linked(this.state.cklink)} */}
        {/* <iframe src="t-rex-runner-gh-pages" style={{ top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '90vh', border: 'none', margin: 0, padding: 0, overflow: 'hidden', zIndex: 9999, overflow: 'hidden' }}></iframe> */}
        {/* <div className="limiter">
          <div className="container-login100">
            <div className="col-md-3">
              <div className="box box-solid">
                <div className="overlay">
                  <li className="fa fa-refresh fa-spin" />
                </div>
              </div><br />
              <div className=" box-solid" align="center">
                <h4><b style={{ color: '#fff' }}>LOADING DATA ....{ }</b></h4>
                
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
