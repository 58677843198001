import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import { Link } from 'react-router-dom'
import key_surat from '../axios/key'


function V_DistribusiSurat({ v_distribusi }) {


  const script = document.createElement("script");

  script.src = 'js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {

  const cekKondisi = (val) => {
    if (val === 1) return 'Dikirim'
    else return 'Disimpan'
  }
  return (

    <tr>
      <td>{v_distribusi.No}</td>
      <td>{v_distribusi.Recieve_id}</td>
      <td>{v_distribusi.Recieve_code}</td>
      <td>{v_distribusi.Recieve_sender}</td>
      <td>{v_distribusi.Recieve_about}</td>
      <td>{v_distribusi.Recieve_date}</td>
      <td>{v_distribusi.rektor_name}</td>
      <td>{v_distribusi.Recieve_category}</td>
      <td>
        <a className="label label-primary fontku" style={{ fontSize: '12px' }}>{cekKondisi(v_distribusi.Recieve_distribution_status)}</a>
      </td>
      <td>
        <button className="btn btn-success" onClick={() => window.open(`${config.baseURL}${v_distribusi.Recieve_file}`, '_blank')}>Download</button>
      </td>
      <td>
        <a href={'/etsurat/' + v_distribusi.Recieve_id} className="btn btn-warning">Edit</a>
      </td>
    </tr>

  )

}

export default V_DistribusiSurat
