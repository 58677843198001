import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../axios/Api'
import jsPDF from 'jspdf'
import config from '../axios/koneksi'
import V_Noout_msg from './V_Noout_msg'
import V_Firstnoout_msg from './V_Firstnoout_msg'

export default class Emergency extends Component {

  constructor() {
    super()
    this.itb = 'ITB-ASIA'
    this.jenissurat = ''
    this.nosuratRes = ``
    this.state = {
      itb: 'ITB-ASIA',
      jenissurat: `....`,
      nsurat: ``,
      vno: ``,
      vbln: ``,
      vthn: '',
      vspc: '',
      ttdsurat: `....`,
      nosurat: `....`,
      bulantahun: 'IV/2020',
      blnroma: `....`,
      thnnomer: `....`,
      firscode: []
    }
  }
  handleJenisSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ jenissurat: e.target.value })
  }
  handleTtdSurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ ttdsurat: e.target.value })
  }
  handleblnroma = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ blnroma: e.target.value })
  }
  handlethnnomer = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ thnnomer: e.target.value })
  }
  handlensurat = (e) => {
    console.log(e.target.value)
    //this.jenissurat=e.target.value
    this.setState({ nosurat: e.target.value })
  }

  async nomergency() {
    await API.get('surat/emergency/view/nomor?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE&b=' + this.state.blnroma + '&t=' + this.state.thnnomer)
      .then(Response => this.setState({
        vno: Response.data[0].nsurat,
        vbln: Response.data[0].blne,
        vthn: Response.data[0].thne,
        vspc: Response.data[0].space
      }))
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);

    this.nomergency()
    this.timer = setInterval(() => this.nomergency(), 2000);

    // console.log(this.state.vno)
    //console.log(this.state.blnroma)
    //console.log(this.state.thnnomer)
    // if (sessionStorage.getItem("un", JSON.stringify(this.state.un)) == null){
    //   sessionStorage.clear()
    //   this.props.history.push('/')
    // }else{
      
    // }
    this.timer = setInterval(() => this.cek(), 1000);
  }
  cek(){
    
    if (sessionStorage.getItem("un") == null) {
      this.props.history.push('/loadingout')
      
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  handleChangeInput = event => {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);

    this.setState({
      form: {
        message
      }
    });
  };

  render() {
    const { selectedOption } = this.state;
    
    const bul = (val) => {
      if (val === "1") return 'Januari'
      else if (val === "2") return 'Februari'
      else if (val === "3") return 'Maret'
      else if (val === "4") return 'April'
      else if (val === "5") return 'Mei'
      else if (val === "6") return 'Juni'
      else if (val === "7") return 'Juli'
      else if (val === "8") return 'Agustus'
      else if (val === "9") return 'September'
      else if (val === "10") return 'Oktober'
      else if (val === "11") return 'November'
      else if (val === "12") return 'Desember'
      else return '0'
    }
    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Emergency Surat</h1>
          </section>

          {/* Main content */}
          <section className="content">
            <div className="callout callout-info">
              <h4>Info Nomor Surat Emergency!</h4>
              <p style={{ color: '#fff' }}><b>Surat Emergency {/*Bulan {this.state.vbln} Tahun {this.state.vthn}*/} Sebanyak {this.state.vspc} Surat</b></p>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Emergency Surat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <p>Kode : {Sent_code}</p> */}
                    <form onSubmit={this.handleSubmit} action={`${config.baseURL}imassage/user/emergency/add?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE`} method="post" >

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomer Surat Emergency</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <input readOnly disabled required type="text" name="Sent_nomer" className="form-control" defaultValue={this.state.vno} placeholder="Nomer Surat"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jenis Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select className="Sent_type" name="Sent_type" className="form-control" onChange={this.handleJenisSurat} required>
                              <option value="">--Jenis Surat--</option>
                              <option value="S.Kep">Surat Keputusan</option>
                              <option value="S.Ket">Surat Keterangan</option>
                              <option value="S.Ed">Surat Edaran</option>
                              <option value="ST">Surat Tugas</option>
                              <option value="SK">Surat Kuasa</option>
                              <option value="SP">Surat Peringatan</option>
                              <option value="SIK">Surat Ijin Kegiatan</option>
                              <option value="S.Pn">Surat Perjanjian</option>
                              <option value="S.Tap">Surat Ketetapan</option>
                              <option value="S.Ker">Surat Kerjasama</option>
                              <option value="S.Per">Surat Perintah</option>
                              <option value="Peng">Pengumuman</option>
                              <option value="L">Surat Lain-lain</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Pembuat Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <select name="Sent_creator" className="form-control" onChange={this.handleTtdSurat} required>
                              <option value="">--Pembuat Surat--</option>
                              <option value="R.0">Rektor</option>
                              <option value="R.1">Wakil Rektor 1</option>
                              <option value="R.2">Wakil Rektor 2</option>
                              <option value="R.3">Wakil Rektor 3</option>
                              <option value="R.4">Wakil Rektor 4</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tanggal Surat Emergency</label>
                          <div className="input-group date">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2) }} required type="number" name="Sent_date" onChange={this.handleInputChange} placeholder="Tanggal Surat" className="form-control pull-right"></input>
                            {/*<input required type="date" name="Sent_date" onChange={this.handleInputChange} className="form-control pull-right"></input>*/}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Bulan Space Emergency</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <select name="bln" className="form-control" onChange={this.handleblnroma} required>
                              <option value="">Bulan</option>
                              <option value="I">Januari</option>
                              <option value="II">Pebruari</option>
                              <option value="III">Maret</option>
                              <option value="IV">April</option>
                              <option value="V">Mei</option>
                              <option value="VI">Juni</option>
                              <option value="VII">Juli</option>
                              <option value="VIII">Agustus</option>
                              <option value="IX">September</option>
                              <option value="X">Oktober</option>
                              <option value="XI">Nopember</option>
                              <option value="XII">Desember</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">

                        <div className="form-group">
                          <label>Tahun Space Emergency</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-calendar"></i>
                            </div>
                            <input onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }} type="number" name="thn" className="form-control" onChange={this.handlethnnomer} placeholder="Tahun" required></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Kategori</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <select required name="Sent_category" onChange={this.handleInputChange} className="form-control">
                              <option value="">--Kategori--</option>
                              <option value="Rahasia">Rahasia</option>
                              <option value="Penting">Penting</option>
                              <option value="Biasa">Biasa</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomer Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-tag"></i>
                            </div>
                            <input required readOnly type="text" name="Sent_code" className="form-control" value={`${this.state.vno}/${this.state.jenissurat}/${this.state.ttdsurat}/${this.state.itb}/${this.state.blnroma}/${this.state.thnnomer}`} placeholder="Generate Nomer"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Tujuan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bars"></i>
                            </div>
                            <input required type="text" name="Sent_to" className="form-control" placeholder="Tujuan"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Perihal Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-bookmark"></i>
                            </div>
                            <input required type="text" name="Sent_about" onChange={this.handleInputChange} className="form-control" placeholder="Perihal Surat"></input>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Isi Surat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <textarea required id="editor1" name="Sent_content" onChange={this.handleInputChange} rows="10" cols="80">
                            </textarea>
                          </div>
                        </div>
                        <button className="btn btn-primary" type="submit">Simpan</button>
                      </div>
                    </form>

                  </div>

                  {/* /.box-body */}
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
