import React, { Component } from 'react'
import V_Notif_ada_Notulen from './V_Notif_ada_Notulen'
import API from '../axios/Api'


export default class Notif_ada_Notulen extends Component {

  state = {
    notif_ada_notulen: []
  }

  async componentDidMount() {
    await API.get("surat/intulen/view/inview?v=UkVTIEZVTEwgQVBJIEluc3RpdHV0IEFTSUE")
      .then(Response => this.setState({
        notif_ada_notulen: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    //console.log(this.state)
  }

  render() {

    const renderData = this.state.notif_ada_notulen.map(notif_ada_notulen => {
      return (
        <V_Notif_ada_Notulen v_notif_ada_notulen={notif_ada_notulen} key={notif_ada_notulen.Invite_code} />
      )
    })

    return (

      <div className="">
        {/* Content Header (Page header) */}

        {/* Main content */}
        <section className="content">
          <div className="row">
            <div className="col-md-12 col-sm-12 margin-bottom-30">

              <div className="col-md-14 col-sm-14 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>Daftar Notulen Yang Telah Dibuat</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table id="example3" className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kode Undangan</th>
                            <th>Pengundang</th>
                            <th>Perihal</th>
                            <th>Tgl Dibuat</th>
                            <th>Tgl Event</th>
                            <th>Tujuan</th>
                            <th>pdf</th>
                            <th>Status</th>
                            <th>Pilihan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData}
                        </tbody>
                      </table>
                    </div>
                    {/* /.box-body */}
                  </div>
                  {/* /.box */}
                </div>
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
        {/* /.content */}
      </div>

    )
  }
}

