import React, { Component } from 'react'

function V_SEOptionb({ v_seoptionb }) {
  //console.log(v_suratedit)
  const stype = (val) => {
    if (val === "R.0") return '( Rektor )'
    else if (val === "R.1") return '( Wakil Rektor 1 )'
    else if (val === "R.2") return '( Wakil Rektor 2 )'
    else if (val === "R.3") return '( Wakil Rektor 3 )'
    else return '( Wakil Rektor 4 )'
  }

  return (
    <option value={v_seoptionb.rektor_id}>{stype(v_seoptionb.rektor_id)}</option>
  )
}

export default V_SEOptionb