import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import API from "../axios/Api";
import jsPDF from "jspdf";
import config from "../axios/koneksi";
import V_Inbox_Code from "./V_Inbox_Code";
import V_rektor from "./v_rektor";
import API_ASTOR from "../axios/Api_astor";
import key_astor from "../axios/key_astor";
import key_surat from "../axios/key";
import { CallMerge } from "@material-ui/icons";
// import key_surat

export default class PreviewSurat extends Component {
  constructor() {
    super();
    this.state = {
      inbox_code: [],
      kakeanpolah: '',
      rektor: [],
      Recieve_file: null,
      filedatas: '',
      users: [],
      valdasi: '',
      idusers: '',
      unusers: '',
      aksusers: '',
      data: '',
      dataw: '',
    }
  }
  //===================================================


  getuser(id) {
    const user = this.state.users.find(usr => usr.id === id)
    // .then(qwe => )
    if (user) {
      return user.namauser
    }
    return '-'
    // console.log(user.user_name)

  }

  async componentDidMount() {

    const idy = this.props.match.params.Recieve_id
    const stts = await API.post('status/surat/masuk/up' + key_surat.baseURL + '&Recieve_id=' + idy)
    this.setState({
      filedatas: stts.data[0].filedatas,
    
    })

    const user = sessionStorage.getItem("sid");
    const ak = sessionStorage.getItem("ak");
    const sstate = await API_ASTOR.get('cari/id/sign/cari' + key_astor.baseURL + '&id=' + user)
    const aksses = await API_ASTOR.get('cari/aks/sign/aks/vvv' + key_astor.baseURL + '&ak=' + ak)
    this.setState({
      idusers: sstate.data[0].id,
      unusers: sstate.data[0].user_id,
      aksusers: aksses.data[0].level_id,
    })

    this.datalaporan()

    const script = document.createElement("script");

    script.src = "js/content.js";
    script.async = true;

    document.body.appendChild(script);


    const idx = this.props.match.params.Recieve_id
    await API.get("srt/masuk/dashboard/id" + key_surat.baseURL + "&Recieve_id=" + idx )
      .then((res) =>
        this.setState({
          Recieve_code: res.data[0].emas,
          Recieve_number: res.data[0].hijau,
          Recieve_sender: res.data[0].hitam,
          Recieve_address: res.data[0].kuning,
          Recieve_to: res.data[0].marun,
          Recieve_date: res.data[0].magenta,
          Recieve_date_send: res.data[0].mawar,
          Recieve_about: res.data[0].merah,
          Recieve_category: res.data[0].nila,
          filesnya: res.data[0].jingga,
          rektor_name: res.data[0].zaga,
          rektor_id: res.data[0].zigi,
          // wow: res.data[0]
        })
      );
    await API_ASTOR.get('surat/buat/rektor/tampil/all/view' + key_astor.baseURL + '&aks=' + this.state.aksusers)
      .then(Response => this.setState({
        rektor: Response.data
      }))
    
    await API_ASTOR.get('surat/masuk/pembuat/v' + key_astor.baseURL)
      .then(
        Response => this.setState({
          users: Response.data
        })
      )

    this.timer = setInterval(() => this.cek(), 1000);
  }
  async datalaporan() {
    
    const kodesurat = this.props.match.params.Recieve_id
    // const idpengguna = sessionStorage.getItem("sid")
    // const akpengguna = sessionStorage.getItem("ak")
    await API.get('pdf/surat/dash/v' + key_surat.baseURL + '&Recieve_id=' + kodesurat + '&Recieve_to=' + this.state.idusers)
        .then(Response =>
            this.setState({
                data: Response.data[0].fileterimasurat
            })

        )

    // if (this.state.data == null) return false
    // else if (this.state.data == undefined) return false
    // else return this.setState({ casedata: this.state.casedata + 1 })

}

  cek() {
    if (sessionStorage.getItem("un") == null) {
      this.props.history.push("/loadingout");
    }
  }


  render() {

    // const cekingdata = (val) => {
    //             <embed
    //                 style={{
    //                 height: '100vh',
    //                 width: '100%',
    //                 margin: '0px',

    //                 bottom: '0',
    //                 overflowY: 'hidden',
    //             }} src={`${config.baseURL}${this.state.data}${key_surat.baseURL}`} />
    // };


    return (
      <div className="wrapper">
        <div className="">
          <div className="wrapper" style={{ backgroundColor: 'transparant', border: '0' }}>
            <div className="">
              <section className="">
                <div className="row">
                  <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-primary box-solid">
                      <div className="box-header" align="center">
                      <Link className="profil" to={'../inboxframe/0'} style={{ float : 'left'}} >
                        <span className="fa fa-arrow-left"/>&nbsp; Kembali
                        </Link>
                        {/* <div className="judul" align = "center"> */}
                        <strong>Form Terima Surat</strong>
                        {/* </div> */}

                        <strong style={{float : 'right'}}>Pembuat : {this.getuser(this.state.Recieve_to)}</strong>
                        <div className="box-tools pull-right"></div>
                      </div>
                      <div className="box-body">

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Kode Surat Masuk : </label>
                             <span> {this.state.Recieve_code} </span>
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Nomer Surat : </label>
                              <span> {this.state.Recieve_number} </span>
                          </div>
                        </div> */}

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Pengirim : </label>
                             <span> {this.state.Recieve_sender} </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Tujuan</label>
                            <div className="input-group">
                              <div className="input-group-addon"> */}
                                {/* <i className="fa fa-user"></i> */}
                              {/* </div> */}
                              {/* <select
                                name="Recieve_to"
                                className="form-control"
                                //   onChange={this.ck}
                                required
                                disabled
                              >
                                <option defaultValue={this.state.Recieve_to} hidden>[{this.state.rektor_id}]&nbsp;{this.state.rektor_name}</option>
                                {/* {renderData} */}
                              {/* </select> */}
                              {/* {this.state.Recieve_to} */}
                            {/* </div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Alamat Pengirim : </label>
                              <span> {this.state.Recieve_address} </span>
                          </div>
                        </div> */}
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Tanggal Kirim : </label>
                              <span> {this.state.Recieve_date_send} </span>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Tanggal Terima : </label>
                             <span> {this.state.Recieve_date} </span>
                          </div>
                        </div>

                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Perihal Surat : </label>
                              <span> {this.state.Recieve_about} </span>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Kategori Surat : </label>
                              <span> {this.state.Recieve_category} </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Digitalisasi Surat : </label> &nbsp;
                              {/* <span> {this.state.filesnya} </span> */}
                              {/* {cekingdata(this.state.casedata)} */}
                              <embed type="text/html"  style={{height: '100vh',width: '100%',margin: '0px',bottom: '0',overflowY: 'hidden', backgroundSize : 'cover'}} src={`${config.baseURL}${this.state.data}${key_surat.baseURL}`}></embed>
                          </div>
                        </div>

                        <div style={{ marginTop: "15px" }} className="col-md-12 text-right">
                          <div className="form-group">
                            {/* <Link to="/distribusisurat" className="btn btn-warning">Kembali</Link> &nbsp; */}
                          {/* <button className="btn btn-primary" type="submit">Simpan</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
